import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAuth} from "@/services/Auth";
import React, {useEffect, useState} from "react";
import LoadingPage from "@/components/LoadingPage";
import {Button, Card, Col, Row, Table} from "react-bootstrap";
import * as moment                    from 'moment';
import OrderItems from "@/components/Tables/OrderItems";
import Users from "@/components/Tables/Users";
import Sites from "@/components/Tables/Sites";
import { Helmet } from "react-helmet";
import ConfirmDialog from "@/components/Modals/ConformDialog";
import SuccessDialog from "@/components/Modals/SuccessDialog";
import AccountItems from "../../../components/Tables/AccountItems";

function SupplierSingle(props) {

    const {t, i18n} = useTranslation();

    let auth = useAuth();

    const getRequest = auth.getRequest;
    let {id} = useParams();

    const [supplier, setSupplier] = useState(null);
    const [sites, setSites] = useState(null);
    const [loading, setLoading] = useState(true);
    const [canUpdateSupplier, setCanUpdateSupplier] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const deleteSupplier = () => {

        setConfirmDelete(false);
        setLoading(true);
        auth.deleteRequest(`/suppliers/${id}`)
            .then(response => {
                setLoading(false);
                setSupplier(null);
                setDeleteSuccess(true);
            })
            .catch(error => {
                setLoading(false);
            })
    }


    useEffect(() => {
        let supplierID = id;
        if (props.supplierId) {
            supplierID = props.supplierId
        }

        if(supplier === null)
        getRequest('/suppliers/' + supplierID)
            .then(response => {
                setSupplier(response.data.supplier);
                setSites(response.data.sites);
                setCanUpdateSupplier(response.data.can_update);
                setCanDelete(response.data.can_delete)
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })

    }, [supplier]);

    return (
        !supplier || loading ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{supplier?.deleted_at && t('archived') || ''} {supplier?.company_name} / {t('drawer_link_titles.suppliers')} - {t('app')}</title>
                </Helmet>

                <SuccessDialog
                    show={deleteSuccess}
                    message="record_deleted"
                    handleClose={_ => setDeleteSuccess(false)}
                />

                <ConfirmDialog
                    cancel='conform_dialog.cancel'
                    accept='conform_dialog.delete'
                    acceptAction={deleteSupplier}
                    show={confirmDelete}
                    handleClose={_ => setConfirmDelete(false)}
                />

                <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                    <h3 className="text-primary">{supplier?.deleted_at && t('archived') || ''}{supplier.company_name}</h3>
                    <div>
                        {canDelete && <Button variant="danger" className="mx-1" onClick={() => setConfirmDelete(true)}>{t('delete')}</Button>}
                        {
                            canUpdateSupplier &&
                            <Link to={`/suppliers/edit/${supplier.id}`} className="btn btn-secondary ms-2">{t('edit')}</Link>
                        }
                    </div>
                </Card.Subtitle>
                <Row className="mt-2">
                    <Col md={8}>
                        <Row className="mt-1">
                            <Col>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h5 className="mb-0">{t('transactions')}</h5>
                                    <div>
                                        <Link to={`/orders/supplier/${supplier.id}`} className="btn-primary btn">{t('orders.view_all_orders')}</Link>
                                    </div>
                                </div>

                                <OrderItems url={`/suppliers/${supplier.id}/order_items`} perPage={10} paginate={false} exclude={['supplier']} />
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h5 className="mb-0">{t('drawer_link_titles.users')}</h5>
                                    <div>
                                        {
                                            auth?.roles?.create_user && canUpdateSupplier &&
                                            <Link to={`/suppliers/${supplier.id}/users/create`} className="btn btn-secondary me-2">{t('new_user')}</Link>
                                        }
                                        <Link to="/users" className="btn btn-primary">{t('view_all_users')}</Link>
                                    </div>
                                </div>

                                <Users url={`/suppliers/${supplier.id}/users`}  exclude={['vehicles', 'client', 'fleet', 'account_number', 'site']} />
                            </Col>
                        </Row>


                        <Row className="mt-4">
                            <Col>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h5>{t('drawer_link_titles.sites')}</h5>
                                    <div>
                                        {
                                            auth?.roles?.create_site && canUpdateSupplier &&
                                            <Link to={`/suppliers/${supplier.id}/sites/create`} className="btn btn-secondary me-2">{t('new_site')}</Link>
                                        }
                                        <Link to={`/suppliers/${supplier.id}/sites`} className="btn btn-primary">{t('view_all_sites')}</Link>
                                    </div>
                                </div>

                                <Sites url={`/suppliers/${supplier.id}/sites`}  exclude={['supplier']} />
                            </Col>
                        </Row>

                        <Row className="mt-5">
                          <Col>
                            <div className="d-flex justify-content-between align-items-center">
                              <h5 className="mb-0">{t('billing_information')}</h5>
                            </div>

                            <AccountItems url={`suppliers/${supplier.id}/account-items`} />
                          </Col>
                        </Row>
                    </Col>

                    <Col md={4}>
                        <ol className="list-group">
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('address')}</div>
                                    {supplier.address},&nbsp;{supplier.address_line_2}
                                </div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('email_address')}</div>
                                    {supplier.email}
                                </div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('phone_number')}</div>
                                    {supplier.phone_number}
                                </div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('account_number')}</div>
                                    {supplier.account_number}
                                </div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('account_status')}</div>
                                    {t(supplier.account_status)}
                                </div>
                            </li>
                            {
                                supplier?.credit_limit?.toLocaleString &&
                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div className=" text-black-50">{t('mpark_credit')}</div>
                                        {supplier.credit_limit.toLocaleString(undefined, {minimumFractionDigits: 2})}&nbsp;{supplier.currency}
                                    </div>
                                </li>
                            }
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('supplier_payment_terms')}</div>
                                    {supplier.supplier_payment_terms_days}&nbsp;{t('days')}&nbsp; {supplier.supplier_payment_terms}
                                </div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('vat_registration_number')}</div>
                                    {t(supplier.vat_registration_number)}
                                </div>
                            </li>
                        </ol>
                    </Col>
                </Row>
            </Card>
        )
    )
}

export default SupplierSingle;
