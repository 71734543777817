import {useTranslation}                                                           from "react-i18next";
import {useAuth}                                                                  from "@/services/Auth";
import {Link, useParams}                                                          from "react-router-dom";
import React, {useEffect, useReducer, useState}                                   from "react";
import LoadingPage                                                                from "@/components/LoadingPage";
import {Button, Card, Col, Container, InputGroup, ListGroup, Row, Spinner, Table} from "react-bootstrap";
import ConfirmDialog                                                              from "../../../components/Modals/ConformDialog";
import Commissions                                                                from "./components/Commissions";
import LoadingButton                                                              from "@/components/LoadingButton";
import {ErrorMessage, Field, FieldArray, Formik, Form}                            from "formik";
import * as Yup                                                                   from "yup";
import Select                                                                     from "react-select";
import SuccessDialog                                                              from "../../../components/Modals/SuccessDialog";
import SiteForm                                                                   from "./components/SiteForm";
import { Helmet } from "react-helmet";

function SiteEdit(props) {

    const {t, i18n} = useTranslation();
    let auth = useAuth();
    const getRequest = auth.getRequest;
    const putRequest = auth.putRequest;
    const postRequest = auth.postRequest;
    const deleteRequest = auth.deleteRequest;
    let {id} = useParams();


    const [site, setSite] = useState(null);
    const [loading, setLoading] = useState(true);
    const [updatingSite, setUpdatingSite] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [serverErrors, setServerErrors] = useState([]);

    //force Rerender
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const paymentTermsDays = [7, 14];


    const getSiteData = () => {

        getRequest('/sites/' + id, { include: 'all' })
            .then(response => {
                setSite(response.data.data)
                //client data

                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.log(error)
            })
    };

    const handleSubmit = (payload) => {
        setUpdatingSite(true);
        setServerErrors([]);
        putRequest(`/sites/${site.id}?include=all`, payload)
            .then(response => {
                setUpdatingSite(false);
                setUpdateSuccess(true);
            })
            .catch(error => {
                setUpdatingSite(false);
                if (error.response.status === 422) {
                    console.log(error.response.data.errors);
                    let serverErrors = [];
                    for (const key in error.response.data.errors) {
                        serverErrors.push(
                            error.response.data.errors[key][0]
                        )
                    }
                    setServerErrors(serverErrors)
                }
            })
    };

    const handleSuccess = (value) => {
        setUpdateSuccess(value);
    }

    const handleClose = () => {
        setUpdateSuccess(false);
        props.history.push(`/sites/${site.id}`)
    };

    useEffect(() => {
        getSiteData();
    }, []);
    return (
        loading ? (
            <LoadingPage/>
        ) : (
            <div>
                <Helmet>
                    <title>{t('edit')} / {t('drawer_link_titles.sites')} - {t('app')}</title>
                </Helmet>

                <SuccessDialog
                    show={updateSuccess}
                    message="site_updated"
                    handleClose={handleClose}
                />
                <Card className="mx-2 my-2 p-2">
                    <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                        <h4 className="text-primary">{site.name}</h4>
                    </Card.Subtitle>
                    <SiteForm
                        handleOnSubmit={handleSubmit}
                        handleSuccess={handleSuccess}
                        buttonLoading={updatingSite}
                        serverErrors={serverErrors}
                        action="update"
                        site={site}
                        isEdit={true}
                    />
                </Card>

            </div>
        )
    )
}

export default SiteEdit;
