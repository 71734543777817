import React, {useEffect, useReducer, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Form, Button, Card, Col, Row, Table, Modal, Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useForm} from 'react-hook-form';
import moment from 'moment-timezone';
import Select from 'react-select';
import {useAuth} from "@/services/Auth";
import LoadingPage from "@/components/LoadingPage";
import LoadingButton from "@/components/LoadingButton";
import SuccessDialog from "@/components/Modals/SuccessDialog";
import * as Yup from "yup";
import useMoment from "@/hooks/useMoment";
import NumberFormat from 'react-number-format';

function SiteSettings(props) {
    const {t} = useTranslation();

    let auth = useAuth();

    let {id} = useParams();

    const [showSuccessDialog, setShowSuccessDialog] = useState(false);

    const [serverErrors, setServerErrors] = useState([]);


    const [sites, setSites] = useState(null);
    const [site, setCurrentSite] = useState(null);

    const formatRecord = site => site && {...site, value: site.site_id, label: site.site.name};

    const getSites = () => {
        auth.getRequest('/settings/sites', {include: ['site', 'account_items']})
            .then(response => {
                setSites(response.data.data);

                if (response.data.data.length === 1) {
                    let site = response.data.data[0];
                    setCurrentSite(site)
                }
            });
    }

    const updateSite = (payload) => {
        return new Promise((resolve, reject) => {

            return Promise.all([
                auth.putRequest(`/settings/sites/${site.site_id}?include[]=site&include[]=account_items`, payload),
                auth.putRequest(`/sites/${site.site_id}?include[]=all&include[]=account_items`, payload.site)
            ]).then(responseArray => {
                const response = responseArray[0];
                setShowSuccessDialog(true);
                setCurrentSite((prev) => ({...prev, ...response.data.data, site: responseArray[1].data.data}));

                resolve(response.data.data);
            })
                .catch(error => {
                    if (error.response.status === 422) {

                        let serverErrors = [];

                        for (const key in error.response.data.errors) {
                            serverErrors.push(error.response.data.errors[key][0])
                        }

                        setServerErrors(serverErrors)
                    }

                    reject();
                });
        });
    }

    const success = (successMessage) => {
        setShowSuccessDialog(true);
    }


    useEffect(() => {
        if (sites === null) {
            getSites();
        }
    }, [sites]);

    return (
        <Card className="mx-2 my-2 p-2 site-setting-card">
            {!sites && <LoadingPage/> || ''}

            <SuccessDialog
                handleClose={() => setShowSuccessDialog(false)}
                show={showSuccessDialog}
            />

            <Modal show={serverErrors.length !== 0} onHide={_ => setServerErrors([])}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('success_dialog.error')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {
                        serverErrors.map((error, index) => <p className="text-danger font-weight-bold"
                                                              key={index}>{t(error)}</p>)
                    }

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={_ => setServerErrors([])}>
                        {t('okay')}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Card.Subtitle className="" style={{width: '100%'}}>
                <div className="row d-flex flex-md-row flex-column-reverse">
                    <div className="col-12 col-md-6">
                        {
                            site?.site && <h3>{site.site.name}</h3>
                        }
                    </div>
                    <div className="col-12 col-md-6 my-4 my-md-0">
                        {sites && <Select
                            className="react-select"
                            value={formatRecord(site)}
                            onChange={(e) => setCurrentSite(e)}
                            placeholder={t('choose_site')}
                            options={sites.map(formatRecord)}/> || ''}
                    </div>
                </div>
            </Card.Subtitle>

            <div className="card-body site-find-card position-relative px-2" style={{height: '100%'}}>
                {
                    site && <>

                        <SiteForm {...{siteSettings: site, updateSite}} />
                    </> || ''
                }
            </div>

        </Card>
    )
}

function SiteForm({siteSettings, updateSite}) {
    const {t} = useTranslation();
    const [updating, setUpdating] = useState(false);

    const auth = useAuth();
    const site = siteSettings?.site;

    const {momentLocal} = useMoment()


    const schema = yup.object().shape({
        notify_booking_request: yup.string().oneOf(['None', 'Instant']),
        notify_booking_approved: yup.string().oneOf(['None', 'Instant']),
        notify_booking_rejected: yup.string().oneOf(['None', 'Instant']),
        site: yup.object().shape({
            marketing_status: yup.string().oneOf(['Active', 'Inactive', 'Cancelled']),
            approval_status: Yup.string().oneOf(['Active', 'Inactive']),
            spaces: Yup.array()
                .when(['approval_status'], (approvalStatus, schema) => {
                    return approvalStatus === 'Active' &&
                        schema.of(
                            Yup.object().shape({
                                space: Yup.number().required()
                                    .min(0)
                                    .test('maxValue', 'Invalid Value', val => val < 999)
                            })
                        ) || schema.nullable();
                }),
            driver_message: Yup.string().nullable().max('400'),
            default_space: Yup.number().required().min(0),
        }).noUnknown(true),
    }).noUnknown(true);

    const onSubmit = (payload) => {
        setUpdating(true);

        updateSite(payload)
            .finally(_ => setUpdating(false));
    };

    const {
        handleSubmit,
        control,
        register,
        formState: {errors},
        setError,
        watch,
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const account_items = [
        site.account_items?.find((item) => item.type == 'marketing') ?? {
            name: 'Marketing Licence',
            renew_date: moment().format('YYYY-MM-DD'),
            unit_price: auth.marketing_licence_price,
            quantity: 1,
        }
    ]

    useEffect(() => {
        const values = {...siteSettings};

        reset(values)
    }, [site])

    console.log(errors);

    const marketing_status = watch('site.marketing_status');
    const approval_status = watch('site.approval_status');

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mt-4">
                <h5>{t('email_notifications')}</h5>

                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label htmlFor="notify_booking_request" className="my-2">{t('notify_booking_request')}</label>
                        <Form.Select
                            {...register('notify_booking_request')}
                            isInvalid={errors.notify_booking_request}>
                            <option value="Instant">{t('notify.Instant')}</option>
                            <option value="None">{t('notify.None')}</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.notify_booking_request && errors.notify_booking_request.message}
                        </Form.Control.Feedback>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label htmlFor="notify_booking_approved" className="my-2">{t('notify_booking_approved')}</label>
                        <Form.Select
                            {...register('notify_booking_approved')}
                            isInvalid={errors.notify_booking_approved}>
                            <option value="Instant">{t('notify.Instant')}</option>
                            <option value="None">{t('notify.None')}</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.notify_booking_approved && errors.notify_booking_approved.message}
                        </Form.Control.Feedback>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label htmlFor="notify_booking_rejected" className="my-2">{t('notify_booking_rejected')}</label>
                        <Form.Select
                            {...register('notify_booking_rejected')}
                            isInvalid={errors.notify_booking_rejected}>
                            <option value="Instant">{t('notify.Instant')}</option>
                            <option value="None">{t('notify.None')}</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.notify_booking_rejected && errors.notify_booking_rejected.message}
                        </Form.Control.Feedback>
                    </div>

                </div>
            </div>

            {auth.user.is_site_manager && <>
                <h4 className="mt-4">{t('marketing.title')}</h4>
                <p>{t('marketing.description')}</p>

                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                        <Form.Label className="my-2">
                            {t('marketing.active')}
                        </Form.Label>

                        <Form.Select
                            {...register('site.marketing_status')}
                            isInvalid={!!errors.marketing_status}
                        >
                            {
                                ['Active', 'Cancelled'].includes(site?.marketing_status) &&
                                <option value="Cancelled">{t('cancel')}</option>
                            }

                            {
                                ['Inactive'].includes(site?.marketing_status || 'Inactive') &&
                                <option value="Inactive">{t('no')}</option>
                            }

                            <option value="Active">{t('yes')}</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.marketing_status && errors.marketing_status.message}
                        </Form.Control.Feedback>

                        {
                            marketing_status == 'Cancelled' &&
                            <Form.Text id="marketing_status_helpblock" muted>
                                {t('marketing.cancellation_info_block')}
                            </Form.Text>
                        }
                    </div>

                </div>

                {marketing_status != 'Inactive' && (
                    <>
                        <h5>{t('monthly_bill')}</h5>

                        <Table>
                            <thead>
                            <tr>
                                <th>
                                    {t('title')}
                                </th>

                                <th>
                                    {t('renew_date')}
                                </th>

                                <th>
                                    {t('unit_price_gbp')}
                                </th>

                                <th>
                                    {t('quantity')}
                                </th>
                            </tr>
                            </thead>

                            <tbody>
                            {account_items.map((account_item, index) => (

                                <tr key={index}>
                                    <td>
                                        {account_item.name}
                                    </td>

                                    <td>
                                        {momentLocal(account_item.renew_date).format(t('formats.short_date'))}
                                    </td>

                                    <td>
                                        <NumberFormat
                                          value={account_item.unit_price}
                                          displayType={'text'}
                                          thousandSeparator={true}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                        />
                                    </td>

                                    <td>
                                        {account_item.quantity}
                                    </td>

                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </>
                )}
                {site.prebookable && (
                    <>
                        <h4 className="mt-4">{t('approval.title')}</h4>
                        <p className="mb-4 small">{t('approval.description')}</p>
                        <Form.Label className="my-2">
                            {t('approval.status')}
                        </Form.Label>

                        <Form.Select
                            className="mb-4"
                            {...register('site.approval_status')}
                        >
                            <option value="Inactive">{t('no')}</option>
                            <option value="Active">{t('yes')}</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            {errors.approval_status && errors.approval_status.message}
                        </Form.Control.Feedback>

                        {approval_status == 'Active' && (
                            <>
                                <div className="mt-3 mb-4 mx-3">
                                    <div className="d-block d-md-none row">
                                        <div className="col border-1 input-box-style">
                                            {t('approval.days.spaces')}
                                        </div>
                                    </div>
                                    <div className="row row-cols-2 row-cols-md-1 d-md-block">
                                        <div className="row flex-column flex-md-row flex-grow-1 flex-md-grow-0">
                                            <div className="col border-1 input-box-style d-none d-md-block"></div>
                                            {site.spaces.map((space, index) => (
                                                <div className="col border-1 input-box-style">
                                                    {t(`approval.days.${space.day}`)}
                                                </div>
                                            ))}
                                            <div className="col border-1 input-box-style">
                                                {t('approval.days.default')}
                                            </div>
                                        </div>
                                        <div className="row flex-column flex-md-row flex-grow-1 glex-md-grow-0">
                                            <div className="col border-1 input-box-style d-none d-md-block">
                                                {t('approval.days.spaces')}
                                            </div>

                                            {site.spaces.map((space, index) => (
                                                <>
                                                    <Form.Control
                                                        key={space.id}
                                                        {...register(`site.spaces.${index}.space`)}
                                                        className="col border-1 day-input rounded-0 input-box-style"
                                                        type="number"
                                                    ></Form.Control>
                                                </>
                                            ))}
                                            <Form.Control
                                                {...register('site.default_space')}
                                                className="col border-1 rounded-0 day-input input-box-style"
                                                type="number"
                                            ></Form.Control>
                                        </div>
                                    </div>
                                </div>

                                {site.spaces.map((space, index) => (
                                    errors.site && errors.site.spaces && errors.site.spaces[index]?.space &&
                                    <div className="text-danger py-2 gap-2" key={index}>
                                        {t('approval.invalid_value', {day: t(`approval.days.${space.day}`)})}
                                    </div>
                                ))}
                                {errors.site && errors.site.default_space &&
                                    <div className="text-danger py-2 gap-2">
                                        {t('approval.invalid_value', {day: t(`approval.days.default`)})}
                                    </div>}

                                <h4 className="mt-4">{t('approval.message.title')}</h4>
                                <p>{t('approval.message.description')}</p>
                                <Form.Control
                                    className="mb-4"
                                    {...register('site.driver_message')}
                                    as="textarea" rows={7}
                                ></Form.Control>

                                {errors.site && errors.site.driver_message &&
                                    <div className="text-danger py-2 gap-2">
                                        {t('approval.invalid_message')}
                                    </div>
                                }
                            </>
                        )}
                    </>
                )}

            </>}


            <div className="form-group mt-4">
                <div className="d-grid gap-2">
                    <LoadingButton type="submit" variant="primary" loading={updating}>
                        {t('update')}
                    </LoadingButton>
                </div>
            </div>
        </Form>
    );
}

export default SiteSettings;
