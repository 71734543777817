import {Link, useParams}            from "react-router-dom";
import {useTranslation}             from "react-i18next";
import {useAuth}                    from "@/services/Auth";
import React, {useEffect, useState} from "react";
import LoadingPage                  from "@/components/LoadingPage";
import {Card, Col, Row, Table, Button}      from "react-bootstrap";
import * as moment                    from 'moment';
import OrderItems from "@/components/Tables/OrderItems";
import Reports from "@/components/Tables/Reports";
import Users from "@/components/Tables/Users";
import { Helmet } from "react-helmet";
import ConfirmDialog from "@/components/Modals/ConformDialog";
import SuccessDialog from "@/components/Modals/SuccessDialog";

function VehicleSingle(props) {

    const {t, i18n} = useTranslation();

    let auth = useAuth();

    const getRequest = auth.getRequest;
    let {id} = useParams();


    const [vehicle, setVehicle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [canUpdateVehicle, setCanUpdateVehicle] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const deleteVehicle = () => {

        setConfirmDelete(false);
        setLoading(true);
        auth.deleteRequest(`/vehicles/${id}`)
            .then(response => {
                setLoading(false);
                setVehicle(null);
                setDeleteSuccess(true);
            })
            .catch(error => {
                setLoading(false);
            })
    }


    useEffect(() => {
        if(vehicle === null)
        getRequest('/vehicles/' + id)
            .then(response => {
                setVehicle(response.data.vehicle);
                setCanUpdateVehicle(response.data.can_update);
                setCanDelete(response.data.can_delete)
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })

    }, [vehicle]);

    return (
        !vehicle || loading ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{vehicle?.deleted_at && t('archived') || ''} {vehicle.registration_number} / {t('drawer_link_titles.vehicles')} - {t('app')}</title>
                </Helmet>

                <SuccessDialog
                    show={deleteSuccess}
                    message="record_deleted"
                    handleClose={_ => setDeleteSuccess(false)}
                />

                <ConfirmDialog
                    cancel='conform_dialog.cancel'
                    accept='conform_dialog.delete'
                    acceptAction={deleteVehicle}
                    show={confirmDelete}
                    handleClose={_ => setConfirmDelete(false)}
                />

                <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                    <h3 className="text-primary">{vehicle?.deleted_at && t('archived') || ''} {vehicle.registration_number} {vehicle?.fleet && ' - ' + vehicle?.fleet.name} {vehicle?.fleet?.client && ' - ' + vehicle?.fleet?.client.name}</h3>
                    <div>
                        {canDelete && <Button variant="danger" className="mx-1" onClick={() => setConfirmDelete(true)}>{t('delete')}</Button>}
                        {
                            canUpdateVehicle &&
                            <Link to={`/vehicles/edit/${vehicle.id}`} className="btn btn-primary">{t('edit')}</Link>
                        }
                    </div>
                </Card.Subtitle>
                <Row className="mt-5">
                    <Col md={8}>
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h5>{t('drivers')}</h5>
                                </div>

                                <Users data={vehicle.drivers || []}  exclude={['vehicles', 'client', 'fleet', 'account_number', 'supplier', 'site', 'account_status']} />
                            </Col>
                        </Row>

                        <Row className="mt-5">
                            <Col>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h5 className="mb-0">{t('orders.last_ten_orders')}</h5>
                                    {
                                        !auth.user.is_driver &&
                                        <Link to={`/orders/vehicle/${vehicle.id}`} className="btn btn-primary">{t('orders.view_all_orders')}</Link>
                                    }
                                </div>

                                <OrderItems url={`/vehicles/${vehicle.id}/order_items`}  exclude={['supplier', 'vehicle', 'vehicle_type', 'account_number', 'fleet']}/>
                            </Col>
                        </Row>

                        {auth?.roles?.view_compliance && (
                            <Row className="mt-5">
                                <Col>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <h5 className="mb-0">{t('drawer_link_titles.compliance')}</h5>
                                    </div>

                                    <Reports url={`/vehicles/${vehicle.id}/reports`} exclude={[ 'vehicle', 'fleet']}/>
                                </Col>
                            </Row>
                        ) || ''}
                    </Col>

                    <Col md={4}>
                        <ol className="list-group">
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('vehicle_type')}</div>
                                    {t(`vehicle_types.${vehicle.vehicle_type}`)}
                                </div>
                            </li>

                            {vehicle.fleet && <>

                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div className=" text-black-50">{t('fleet_name')}</div>
                                        {vehicle?.fleet?.id && <Link to={`/fleets/${vehicle.fleet.id}`}>{vehicle.fleet.name}</Link> || vehicle.fleet.name}
                                    </div>
                                </li>

                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div className=" text-black-50">{t('client')}</div>
                                        {vehicle?.fleet?.client?.id && <Link to={`/clients/${vehicle.fleet.client.id}`}>{vehicle.fleet.client.name}</Link> || vehicle?.fleet?.client?.name}
                                    </div>
                                </li>

                            </>}
                        </ol>
                    </Col>
                </Row>
            </Card>
        )
    )
}

export default VehicleSingle;
