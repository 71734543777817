import {useAuth}                    from "@/services/Auth";
import LoadingPage                  from "@/components/LoadingPage";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, Row}     from "react-bootstrap";
import {Link} from "react-router-dom";
import {useTranslation}             from "react-i18next";
import ConfirmDialog                from "../../components/Modals/ConformDialog";
import * as moment                    from 'moment';
import { Helmet } from "react-helmet";
import useMoment from "@/hooks/useMoment";

function Notifications() {
    const {t, i18n} = useTranslation();
    let auth = useAuth();
    const getRequest = auth.getRequest;
    const deleteRequest = auth.deleteRequest;
    const checkToken = auth.checkToken;
    const [loading, setLoading] = useState(false);
    const {momentLocal} = useMoment()

    // const [notifications, setNotifications] = useState([]);
    const [showConfirm, setShowConform] = useState(false);
    const [dismissAction, setDismissAction] = useState(null);
    const [dismissMessage, setDismissMessage] = useState(null);
    const [notificationToDismiss, setNotificationToDismiss] = useState(null);


    const dismissAllNotifications = () => {
        handleClose();
        getRequest('/notifications/dismiss')
            .then(response => {
                if (response.data.message === 'OK') {
                    checkToken();
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })
    }

    const dismissNotifications = () => {
        setLoading(true);
        handleClose();
        if (notificationToDismiss) {
            deleteRequest(`/notifications/${notificationToDismiss.id}`)
                .then(response => {
                    if (response.data.message === 'OK') {
                        checkToken();
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(()=> setLoading(false))
        }
    }
    const handleClose = () => {
        setShowConform(false);
    };

    const confirmDismiss = (notification, type) => {
        setDismissAction(type);
        setShowConform(true);
        if (type === 'ALL') {
            setDismissMessage('dismiss_all_notification')
        } else {
            setDismissMessage('dismiss_this_notification')
        }
        if (notification != null) {
            setNotificationToDismiss(notification);
        }
    }

    return (
        !auth.tokenChecked ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2 justify-content-between">
                <Helmet>
                    <title>{t('notifications')} - {t('app')}</title>
                </Helmet>

                <ConfirmDialog
                    message={dismissMessage}
                    cancel='conform_dialog.no'
                    accept='conform_dialog.yes'
                    acceptAction={dismissAction === 'ALL' ? dismissAllNotifications : dismissNotifications}
                    show={showConfirm}
                    handleClose={handleClose}
                />

                <Card.Subtitle className="d-flex justify-content-between">
                    <h3 className="text-primary">{t('notifications')}</h3>
                    {
                        auth.notifications?.length > 0 &&
                        <Button variant="outline-danger" onClick={() => confirmDismiss(null, 'ALL')}>{t('dismiss_all')}</Button>
                    }
                </Card.Subtitle>
                <Row className="pt-4">
                    {
                        auth.notifications?.map && auth.notifications.map(notification => {
                            var data = {};

                            if(notification?.booking)
                            {
                                data.bookingId       = notification.booking?.id;
                                data.bookingNumber   = notification.booking?.booking_number;
                                data.siteName        = notification.booking?.site?.name;
                                data.registration_number = notification.booking?.vehicle?.registration_number ?? '-';
                                data.instantBook     = notification.booking.instant_booking;
                                data.bookingTime     = notification.booking?.from_datetime && momentLocal(notification.booking?.from_datetime).format(t('formats.datetime'));
                                data.created         = notification.booking?.created_at && momentLocal(notification.booking?.created_at).format(t('formats.datetime'));
                            }

                            return (
                                <Col className="col-12 py-1 px-2 driver-dashboard" sm={6} md={4} key={notification.id}>


                                    <Card style={{width: '100%', height: '100%'}} className="card-shadow p-3">
                                        <Card.Title className="d-fle d-flex justify-content-between align-items-center">
                                            <h4>{t(`notifications_types.${notification.type}.header`, data)}</h4>

                                            <small className="text-black-50">{notification.date && momentLocal(notification.date).format(t('formats.datetime'))}</small>
                                        </Card.Title>
                                        <div className="card-body relative p-2 ">
                                            {data.instantBook === 1 && (
                                                <p>{t(`notifications_types.instant.body`, data)}</p>
                                            ) || (<p>{t(`notifications_types.${notification.type}.body`, data)}</p>)}

                                            {notification.booking?.type_str && <p>{t('booking_type')}:&nbsp;{t(`booking_types.${notification.booking?.type_str}`)}</p>}
                                        </div>
                                        <Row className="mx-0 mt-2">
                                            <Col className="col-12 mt-2 p-0">
                                                <div className="d-grid gap-2">
                                                    {['BookingRequested', 'BookingConfirmed', 'BookingCheckedIn'].includes(notification.type) && (
                                                        <Link className="btn btn-primary" to={`/bookings/${notification.booking?.id}`}>{t('go_to_booking')}</Link>
                                                    )}

                                                    {['BookingRejected', 'BookingCheckedOut'].includes(notification.type) && (
                                                        <Link className="btn btn-primary" to={`/bookings/site-card/${notification.booking?.site?.id}`}>{t('rebook')}</Link>
                                                    )}

                                                    <Button variant="outline-danger" disabled={loading} onClick={() => confirmDismiss(notification, null)}>{t('dismiss')}</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
                {
                    auth.notifications?.length === 0 &&
                    <Row>
                        <Col className="text-center">
                            <p className="text-danger">
                                {t('no_new_notification')}
                            </p>
                        </Col>
                    </Row>
                }
            </Card>
        )
    )

}

export default Notifications;
