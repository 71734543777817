import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAuth} from "@/services/Auth";
import React, {useEffect, useState} from "react";
import LoadingPage from "@/components/LoadingPage";
import {Button, Card, Col, Row, Table} from "react-bootstrap";
import * as moment                    from 'moment';
import OrderItems from "@/components/Tables/OrderItems";
import Users from "@/components/Tables/Users";
import Promotions from "@/components/Tables/Promotions";
import { Helmet } from "react-helmet";
import ConfirmDialog from "@/components/Modals/ConformDialog";
import SuccessDialog from "@/components/Modals/SuccessDialog";

function SiteSingle(props) {

    const {t, i18n} = useTranslation();

    let auth = useAuth();

    const getRequest = auth.getRequest;
    let {id} = useParams();


    const [supplier, setSupplier] = useState(null);
    const [site, setSite] = useState(null);
    const [loading, setLoading] = useState(true);
    const [can_update, setCanUpdateSite] = useState(false);
    const [can_delete, setCanDelete] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const deleteSite = () => {

        setConfirmDelete(false);
        setLoading(true);
        auth.deleteRequest(`/sites/${id}`)
            .then(response => {
                setLoading(false);
                setSite(null);
                setDeleteSuccess(true);
            })
            .catch(error => {
                setLoading(false);
            })
    }


    useEffect(() => {
        if(site === null)
        getRequest('/sites/' + id, { include: 'supplier' })
            .then(response => {
                setSite(response.data.data);
                setCanUpdateSite(response.data.can_update);
                setCanDelete(response.data.can_delete)
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })

    }, [site]);

    return (
        !site || loading ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{site?.deleted_at && t('archived') || ''} {site.name} / {t('drawer_link_titles.sites')} - {t('app')}</title>
                </Helmet>

                <SuccessDialog
                    show={deleteSuccess}
                    message="record_deleted"
                    handleClose={_ => setDeleteSuccess(false)}
                />

                <ConfirmDialog
                    cancel='conform_dialog.cancel'
                    accept='conform_dialog.delete'
                    acceptAction={deleteSite}
                    show={confirmDelete}
                    handleClose={_ => setConfirmDelete(false)}
                />

                <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                    <h3 className="text-primary">{site?.deleted_at && t('archived') || ''} {site.name}</h3>
                    <div>
                        {site?.page_content &&
                            <Link to={`/pages/${site?.slug}`} className="btn btn-warning me-2">
                                {t('marketing.page')}
                            </Link>
                        || can_update && typeof site?.page_content != 'undefined' &&
                            <Link to={`/sites/${site?.id}/page/edit`} className="btn btn-warning me-2">
                                {t('marketing.page')}
                            </Link>
                        || ''}
                        {can_delete && <Button variant="danger" className="mx-1" onClick={() => setConfirmDelete(true)}>{t('delete')}</Button>}
                        {site.token && <Link to={`/sites/qr-code/${site.id}`} className="me-2 btn btn-secondary">{t('show_qr_code')}</Link> || ''}
                        {
                            can_update &&
                            <Link to={`/sites/edit/${site.id}`} className="btn btn-primary">{t('edit')}</Link>
                        }
                    </div>
                </Card.Subtitle>

                <Row className="mt-2">
                    <Col md={8}>
                        <Row className="mt-2">
                            <Col>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h5 className="mb-0">{t('last_ten_orders')}</h5>
                                    <div>
                                        <Link to={`/orders/site/${site.id}`} className="me-2 btn btn-primary">{t('view_all_bookings')}</Link>
                                    </div>
                                </div>

                                <OrderItems url={`/sites/${site.id}/order_items`} perPage={10} paginate={false} exclude={['site']} />
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h5 className="mb-0">{t('drawer_link_titles.users')}</h5>
                                    <div>
                                        {
                                            can_update &&
                                            <Link to={`/users/create/site/${site.id}`} className="me-2 btn btn-secondary">{t('new_user')}</Link>
                                        }
                                    </div>
                                </div>

                                <Users url={`/sites/${site.id}/users`} exclude={['vehicles', 'client', 'fleet', 'account_number', 'supplier']} />
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h5>{t('drawer_link_titles.promotions')}</h5>

                                    {
                                        auth?.roles?.create_promotion && can_update &&
                                        <Link to={`/promotions/create/${site.id}`} className="me-2 btn btn-secondary">{t('add_promotion')}</Link>
                                    }
                                </div>

                                <Promotions url={`/sites/${id}/promotions`} exclude={['site', 'supplier']} />
                            </Col>
                        </Row>

                        {
                            site.client_pricing &&
                            <Row className="mt-4">
                                <Col>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h5>{t('client_pricing')}</h5>
                                        {
                                            auth.user.is_platform_admin && can_update &&
                                            <Link to={{ pathname: `/sites/${id}/pricing`, state: { site } }} className="me-2 btn btn-secondary">{t('add_client_pricing')}</Link>
                                        }
                                    </div>
                                    <Table striped bordered hover responsive className="mt-1">
                                        <thead>
                                        <tr>
                                            <th>{t('company_name')}</th>
                                            <th>{t('primary_contact')}</th>
                                            <th>{t('email_address')}</th>
                                            <th>{t('account_number')}</th>
                                            <th>{t('account_status')}</th>
                                            <th>{t('credit')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {
                                            site.client_pricing.map((pricing, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {
                                                                auth.user.is_platform_admin ?
                                                                    <Link to={`/clients/${pricing.company.id}/sites/${site.id}/pricing`}>{pricing.company.name}</Link>
                                                                    :
                                                                    <span>{pricing.company.name}</span>
                                                            }
                                                        </td>
                                                        <td>{pricing.company.primary_contact}</td>
                                                        <td>{pricing.company.email}</td>
                                                        <td>{pricing.company.account_number}</td>
                                                        <td>{pricing.company.account_status}</td>
                                                        <td>{pricing.company.credit_limit}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        {
                                            site.client_pricing.length === 0 &&
                                            <tr>
                                                <td colSpan={6}>{t('no_data')}</td>
                                            </tr>
                                        }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        }
                    </Col>

                    <Col md={4}>
                        <ol className="list-group">
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('address')}</div>
                                    {site.address}
                                </div>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('email_address')}</div>
                                    {site.email}
                                </div>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('phone_number')}</div>
                                    {site.phone_number}
                                </div>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('tapa_rating')}</div>
                                    {site.security_rating}
                                </div>
                            </li>

                            {site.supplier && (
                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div className=" text-black-50">{t('account_number')}</div>
                                        {site.supplier.account_number}
                                    </div>
                                </li>
                            ) || ''}

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('total_spaces')}</div>
                                    {t(site.total_spaces)}
                                </div>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('site_currency')}</div>
                                    {t(site.currency)}
                                </div>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('marketing_price')}</div>
                                    {site.marketing_value && parseFloat(site.marketing_value).toFixed(2) || ''}
                                </div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('booking.methods')}</div>
                                    {
                                        site.booking_methods.map((method, index) => <p className="my-1"
                                                                              key={index}>{t(`booking.${method}`)}</p>)
                                    }
                                </div>
                            </li>

                            {site.supplier && <>

                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div className=" text-black-50">{t('supplier')}</div>
                                        {site.supplier.id && <Link to={`/suppliers/${site.supplier.id}`}>{site.supplier.company_name}</Link> || site.supplier.company_name}
                                    </div>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div className=" text-black-50">{t('supplier_payment_terms')}</div>
                                        {site.supplier_payment_terms_days}&nbsp;{t('days')}&nbsp; {site.supplier_payment_terms}
                                    </div>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div className=" text-black-50">{t('vat_registration_number')}</div>
                                        {t(site.vat_registration_number ?? site.supplier.vat_registration_number)}
                                    </div>
                                </li>

                            </>}
                        </ol>
                    </Col>
                </Row>
            </Card>
        )
    )
}

export default SiteSingle;
