export default {
    "Apr": "Avril",
    "Aug": "Ao\u00fbt",
    "Credit": "Cr\u00e9dit",
    "Dec": "D\u00e9cembre",
    "Feb": "F\u00e9vrier",
    "Jan": "Janvier",
    "Jul": "Juillet",
    "Jun": "Juin",
    "Mar": "Mars",
    "May": "Mai",
    "Nov": "Novembre",
    "Oct": "Octobre",
    "Sep": "Septembre",
    "a_new_order_has_been_made": "Une nouvelle commande n\u00b0\u00a0{{orderId}} a \u00e9t\u00e9 effectu\u00e9e sur {{siteName}} pour {{bookingTime}}",
    "account_number": "Num\u00e9ro de compte",
    "account_payment": "Paiement du compte",
    "account_payment_method": "M\u00e9thode de paiement du compte",
    "account_status": "Statut du compte",
    "account_type": "Type de compte",
    "activate": "Activer",
    "active": "Actif",
    "add": "Ajouter",
    "add_addon": "Ajouter un module compl\u00e9mentaire",
    "add_client_pricing": "Ajouter un tarif client",
    "add_driver": "Nouveau pilote",
    "add_drivers": "Ajouter des pilotes",
    "add_favourite": "Ajouter comme favori",
    "add_parking_price": "Ajouter un prix de stationnement",
    "add_parking_prices": "Ajouter des prix de stationnement",
    "add_payment_method": "Ajouter un mode de paiement",
    "add_person": "Ajouter une personne",
    "add_promotion": "Ajouter une promotion",
    "add_region": "Ajouter une r\u00e9gion",
    "add_service": "Ajouter un service",
    "add_single_payout": "Ajouter un paiement unique",
    "add_site": "Ajouter un site",
    "add_site_pricing": "Ajouter le prix du site",
    "add_to_order": "Ajouter \u00e0 la commande",
    "addon": "Ajouter",
    "addon_purchase_using_fleet_account": "Le conducteur ach\u00e8te un module compl\u00e9mentaire \u00e0 l'aide du compte de flotte",
    "addon_specific": "Sp\u00e9cifique au service et \u00e0 l'addon",
    "addon_spending_cap_instructions": "Pour restreindre un service\/module compl\u00e9mentaire particulier, r\u00e9pertoriez-les ci-dessous",
    "addons": "Modules compl\u00e9mentaires",
    "addons_services": "Ajouts et services",
    "address": "Adresse",
    "address_1": "Adresse de 1\u00e8re ligne",
    "address_2": "Adresse de la 2e ligne",
    "address_3": "Adresse de 3e ligne",
    "address_line_2": "Adresse de 2e ligne",
    "admin_dashboard": "Tableau de bord administrateur",
    "all": "Tout",
    "all_clients_are_imported": "Tous les clients ont \u00e9t\u00e9 import\u00e9s avec succ\u00e8s",
    "all_drivers_are_imported": "Tous les pilotes ont \u00e9t\u00e9 import\u00e9s avec succ\u00e8s",
    "all_fleets_are_imported": "Toutes les flottes ont \u00e9t\u00e9 import\u00e9es avec succ\u00e8s",
    "all_payments_are_imported": "Tous les paiements ont \u00e9t\u00e9 import\u00e9s avec succ\u00e8s",
    "all_payouts_are_imported": "Tous les paiements ont \u00e9t\u00e9 import\u00e9s avec succ\u00e8s",
    "all_promotions_are_imported": "Toutes les promotions ont \u00e9t\u00e9 import\u00e9es avec succ\u00e8s",
    "all_sites_are_imported": "Tous les sites ont \u00e9t\u00e9 import\u00e9s avec succ\u00e8s",
    "all_suppliers_are_imported": "Tous les fournisseurs ont \u00e9t\u00e9 import\u00e9s avec succ\u00e8s",
    "all_users_are_imported": "Tous les utilisateurs ont \u00e9t\u00e9 import\u00e9s avec succ\u00e8s",
    "all_vehicles_are_imported": "Tous les v\u00e9hicules ont \u00e9t\u00e9 import\u00e9s avec succ\u00e8s",
    "alternative_payment_method": "Mode de paiement alternatif",
    "amount": "Montant",
    "app": "Application M-Park",
    "approve": "Approuver",
    "archived": "ARCHIV\u00c9\u00a0:",
    "back": "Pr\u00e9c\u00e9dent",
    "back_to_map_view": "Retour \u00e0 la vue carte",
    "back_to_n": "Retour \u00e0 {{n}}",
    "balance": "Solde",
    "begin": "Commencer",
    "best": "Meilleur",
    "billing_address": "Adresse de facturation",
    "billing_address_line_2": "Adresse de facturation de 2e ligne",
    "billing_address_same_as_registered_address": "Adresse de facturation identique \u00e0 l'adresse enregistr\u00e9e\u00a0?",
    "billing_county": "Comt\u00e9",
    "billing_currency": "Devise de facturation",
    "billing_information": "d\u00e9tails de facturation",
    "book_now": "R\u00e9servez maintenant",
    "booking_cant_be_extend_to_before_initial_expire_date": "La r\u00e9servation ne peut pas \u00eatre prolong\u00e9e avant la date d'expiration initiale et doit \u00eatre prolong\u00e9e d'au moins 1 heure",
    "booking_cant_end_end_before_it_stated": "La r\u00e9servation ne peut pas se terminer avant d'avoir commenc\u00e9",
    "booking_cant_start_on_the_past": "La r\u00e9servation ne peut pas commencer dans le pass\u00e9 et doit \u00eatre ant\u00e9rieure de 45\u00a0minutes ou plus \u00e0 l'heure actuelle",
    "booking_checked_in": "Enregistr\u00e9",
    "booking_checked_out": "V\u00e9rifi\u00e9",
    "booking_duration": "Pour combien de temps?",
    "booking_duration_must_be_at_lest_one_hour": "La dur\u00e9e de la r\u00e9servation doit \u00eatre d'au moins 1 heure",
    "booking_extended": "R\u00e9servation prolong\u00e9e",
    "booking_from": "Entrez la date\/l'heure d'arriv\u00e9e...",
    "booking_id": "ID de r\u00e9servation",
    "booking_summary": "R\u00e9sum\u00e9",
    "booking_to": "Entrer la date\/l'heure de d\u00e9part",
    "call_to_action": "Appel \u00e0 l'action",
    "call_to_action_link_text": "Appel \u00e0 l'action\/Texte du lien",
    "cancel": "Annuler",
    "cancel_extend": "Annuler Prolonger",
    "cancel_question": "Annuler?",
    "card": "Carte",
    "card_expiry": "Expiration de la carte",
    "card_holder_name": "Nom du titulaire",
    "card_number": "Num\u00e9ro de carte",
    "category": "Cat\u00e9gorie",
    "ccv": "CVC",
    "change": "Changer",
    "check_email": "S'il vous pla\u00eet s'il vous pla\u00eet v\u00e9rifier votre e-mail",
    "check_in": "Enregistrement",
    "check_off_what_customer_has_received": "Veuillez cocher ce que le client a re\u00e7u.",
    "check_out": "V\u00e9rifier",
    "checked_in_at": "Enregistr\u00e9 \u00e0",
    "checked_out_at": "V\u00e9rifi\u00e9 \u00e0",
    "checkout_now": "R\u00e9gler maintenant",
    "choose": "Choisir",
    "choose_client": "Choisissez Client",
    "choose_csv_file_to_upload": "Choisissez le fichier CSV \u00e0 t\u00e9l\u00e9charger",
    "choose_driver": "Choisissez le pilote",
    "choose_facility": "Choisissez l'\u00e9tablissement",
    "choose_fleet": "Choisissez la flotte",
    "choose_type": "Choisissez Type",
    "city": "Ville\/Ville",
    "city_country": "Ville Pays)",
    "client": "Client",
    "client_deleted": "Client supprim\u00e9\u00a0!",
    "client_name": "Nom du client",
    "client_payment_terms": "Conditions de paiement des clients",
    "client_payment_terms_days": "Jours de paiement client",
    "client_pricing": "Tarification clients",
    "client_pricing_reset": "R\u00e9initialisation des prix client",
    "client_pricing_saved": "Tarification client enregistr\u00e9e",
    "client_supplier": "Client \/ Fournisseur",
    "clients_imported": "Clients import\u00e9s",
    "close": "proche",
    "column": "Colonne",
    "comma_separated_available_options": "S\u00e9par\u00e9s par des virgules, Options disponibles",
    "commission_amount": "Montant des commissions",
    "commission_percentage": "Pourcentage de commission",
    "commission_rate_options": {
        "percentage": "Pourcentage",
        "flat_rate": "Forfait"
    },
    "commission_rate_type": "Type de taux de commission",
    "commission_rate_value": "Valeur du taux de commission",
    "commission_rates": "Taux de commission",
    "company": "Soci\u00e9t\u00e9",
    "company_details": "D\u00e9tails du client",
    "company_name": "Nom de l'entreprise",
    "complete": "Complet",
    "complete_purchase": "Finalisez l'achat et payez {{amount}}",
    "compliance": {
        "options": "Options de conformit\u00e9",
        "licence_price": "Prix d\u2019immatriculation par utilisateur (GBP)",
        "licences_hit_limit": "Vous avez atteint votre limite de licence de conformit\u00e9.",
        "increase_licences": "Augmenter pour ajouter un utilisateur",
        "licences_description": "Pendant que la conformit\u00e9 est active, vous devez avoir suffisamment de permis pour tous les conducteurs de la flotte",
        "next_section": "Section suivante",
        "title": "Conformit\u00e9",
        "active": "Activer",
        "defects": "D\u00e9fauts",
        "dashboard_link": "V\u00e9rification quotidienne et conformit\u00e9 du v\u00e9hicule",
        "dashboard_advert": "Vous voulez un moyen plus facile d\u2019effectuer vos v\u00e9rifications de conformit\u00e9 de votre v\u00e9hicule?",
        "dashboard_advert_button": "En savoir plus",
        "incident_information": "Renseignements sur l\u2019incident",
        "datetime": "Date\/heure du rapport",
        "override_warning": "Veuillez noter que la facture mensuelle sera \u00e9cras\u00e9e si le nombre de licences est modifi\u00e9.",
        "report_status": {
            "Incomplete": "Incomplet",
            "Pending": "En instance",
            "Waiting": "Attente",
            "Resolved": "R\u00e9solu",
            "Complete": "Complet"
        },
        "severity": {
            "None": "Aucun probl\u00e8me",
            "Fault": "Probl\u00e8mes mineurs",
            "Severe": "Probl\u00e8mes graves"
        },
        "severity_label": "S\u00e9v\u00e9rit\u00e9",
        "reporting_to": "Signalement \u00e0",
        "report_type": "Type de v\u00e9rification",
        "view_image": "Voir l\u2019image",
        "answer": "R\u00e9pondre",
        "check_key_name": "V\u00e9rifier",
        "details": "D\u00e9tails",
        "DailyReport": {
            "title": "Contr\u00f4les quotidiens des v\u00e9hicules",
            "dashboard_summary": "Une feuille de contr\u00f4le quotidienne du v\u00e9hicule est une liste d\u2019\u00e9l\u00e9ments sp\u00e9cifiques que vous devez v\u00e9rifier chaque jour avant d\u2019utiliser votre v\u00e9hicule. Le syst\u00e8me informera votre gestionnaire de flotte de tout probl\u00e8me soulev\u00e9 et tiendra un journal de toutes vos v\u00e9rifications.",
            "complete": "Complet",
            "incomplete": "Incomplet",
            "no_issues": "Tout va bien",
            "minor_issues": "Probl\u00e8mes mineurs",
            "severe_issues": "Probl\u00e8mes graves",
            "group_key_name": "V\u00e9rifier le groupe",
            "completed": "Vous avez termin\u00e9 les v\u00e9rifications quotidiennes pour {{registration}}",
            "minor_issues_long": "Vous avez enregistr\u00e9 quelques probl\u00e8mes mineurs, veuillez contacter votre gestionnaire de flotte et faites-vous r\u00e9parer \u00e0 temps",
            "severe_issues_long": "Vous avez enregistr\u00e9 des probl\u00e8mes graves veuillez contacter votre gestionnaire de flotte avant de conduire",
            "inside": {
                "title": "\u00c0 l\u2019int\u00e9rieur du v\u00e9hicule",
                "mirrors_glass": {
                    "title": "Miroirs et verre",
                    "is_window_cracked": "Le pare-brise est-il fissur\u00e9 \/ ray\u00e9 \/ d\u00e9color\u00e9?",
                    "cracked": "Oui fissur\u00e9",
                    "scratched": "Oui ray\u00e9",
                    "discoloured": "Oui D\u00e9color\u00e9",
                    "is_vision_obsured": "Est-ce que cela obstrue la vision du conducteur?",
                    "is_mirrors_acceptable": "Tous les miroirs n\u00e9cessaires sont-ils mont\u00e9s, fonctionnels et exempts de dommages?",
                    "not_fitted": "Non \u00e9quip\u00e9",
                    "is_camera_working": "Si un syst\u00e8me de cam\u00e9ra est utilis\u00e9 \u00e0 la place d\u2019un miroir, ne fonctionnent-ils pas ou la vue est-elle incorrecte?"
                },
                "wipers": {
                    "title": "Essuie-glaces et lave-glaces",
                    "is_working": "Les essuie-glaces sont-ils correctement ajust\u00e9s et fonctionnent-ils?",
                    "is_missing": "Les essuie-glaces manquent-ils?",
                    "is_damaged": "Les essuie-glaces sont-ils endommag\u00e9s ou us\u00e9s?"
                },
                "front_view": {
                    "title": "Vue de face",
                    "object_obstruction": "Y a-t-il des objets qui g\u00eanent votre vue de face?",
                    "wiper_obstruction": "Y a-t-il un obstacle dans la zone balay\u00e9e des essuie-glaces?"
                },
                "dashboard": {
                    "title": "Voyants et jauges du tableau de bord",
                    "is_reading_working": "Tous les instruments et jauges fonctionnent-ils correctement ?",
                    "is_warning_working": "Vos voyants d\u2019avertissement fonctionnent-ils correctement ?"
                },
                "steering": {
                    "title": "Direction",
                    "is_moving": "Le volant bouge-t-il correctement?",
                    "is_powered": "La direction assist\u00e9e fonctionne-t-elle correctement ?",
                    "has_play": "La direction a-t-elle un jeu excessif?",
                    "does_jam": "Est-ce que la direction se bloque?"
                },
                "horn": {
                    "title": "Corne",
                    "is_working": "Le klaxon fonctionne-t-il?",
                    "is_accessible": "Le klaxon est-il accessible depuis le si\u00e8ge du conducteur?"
                },
                "breaks_air": {
                    "title": "Freins et accumulation d\u2019air",
                    "working": "L\u2019air s\u2019accumule-t-il correctement ?",
                    "warning": "Le syst\u00e8me d\u2019alerte fonctionne-t-il?",
                    "leaks": "Y a-t-il des fuites d\u2019air?",
                    "footwell": "Le puits de pied est-il clair?",
                    "service": "Le frein de service actionne-t-il \u00e0 la fois les freins du tracteur et ceux de la remorque?",
                    "parking": "Le frein de stationnement du tracteur fonctionne-t-il ?",
                    "loose": "Le frein de service est-il desserr\u00e9, ou a-t-il un jeu lat\u00e9ral excessif ou une bande de roulement antid\u00e9rapante incompl\u00e8te?"
                },
                "height_marker": {
                    "title": "Marqueur de hauteur",
                    "is_correct": "La hauteur correcte est-elle affich\u00e9e sur le marqueur de hauteur du v\u00e9hicule dans la cabine?"
                },
                "seatbelts": {
                    "title": "Ceintures de s\u00e9curit\u00e9",
                    "is_damaged": "Les ceintures de s\u00e9curit\u00e9 ont-elles des coupures, des dommages ou des effilochages qui pourraient les emp\u00eacher de fonctionner?",
                    "is_secure": "Les ceintures de s\u00e9curit\u00e9 restent-elles bien fix\u00e9es lorsque vous les branchez?",
                    "does_retract": "Les ceintures de s\u00e9curit\u00e9 se r\u00e9tractent-elles contre vous lorsqu\u2019elles sont mont\u00e9es et se r\u00e9tractent-elles compl\u00e8tement lorsque vous les enlevez?"
                }
            },
            "outside": {
                "title": "\u00c0 l\u2019ext\u00e9rieur du v\u00e9hicule",
                "lights": {
                    "title": "Feux et indicateurs",
                    "is_working": "Tous les voyants et indicateurs fonctionnent-ils correctement ?",
                    "is_colour_correct": "Toutes les lentilles sont-elles ajust\u00e9es, propres et de la bonne couleur?",
                    "brakes": "Les feux stop s\u2019allument-ils lorsque vous serrez le frein de service et s\u2019\u00e9teignent-ils lorsque vous le rel\u00e2chez?",
                    "side": "Les feux de position lat\u00e9raux sont-ils install\u00e9s et fonctionnent-ils?"
                },
                "fuel_oil": {
                    "title": "Fuites de carburant et d\u2019huile",
                    "cap": "Le bouchon de remplissage de carburant est-il correctement install\u00e9?",
                    "leaks": "Avec le moteur allum\u00e9, y a-t-il des fuites de carburant ou d\u2019huile sous le v\u00e9hicule?"
                },
                "battery": {
                    "title": "S\u00e9curit\u00e9 et conditions de la batterie",
                    "is_secure": "La batterie est-elle s\u00e9curis\u00e9e ?",
                    "is_good": "La batterie est-elle en bon \u00e9tat ?",
                    "does_leak": "La batterie fuit-elle ?"
                },
                "diesel_exhaust": {
                    "title": "Liquide d\u2019\u00e9chappement diesel (AdBlue)",
                    "has_adblue": "Y a-t-il suffisamment de liquide AdBlue dans votre v\u00e9hicule diesel (si n\u00e9cessaire)?"
                },
                "exhaust": {
                    "title": "Fum\u00e9e excessive d\u2019\u00e9chappement du moteur",
                    "excessive": "Les gaz d\u2019\u00e9chappement \u00e9mettent-ils une quantit\u00e9 excessive de fum\u00e9e?"
                },
                "body_security": {
                    "title": "S\u00e9curit\u00e9 du corps et des ailes",
                    "fasteners_working": "Tous les dispositifs de fixation fonctionnent-ils?",
                    "doors": "Les portes de cabine et les portes de remorque sont-elles s\u00e9curis\u00e9es lorsqu\u2019elles sont ferm\u00e9es?",
                    "panels": "Les panneaux de carrosserie du tracteur ou de la remorque sont-ils s\u00e9curis\u00e9s et ne risquent-ils pas de tomber?",
                    "legs": "Les jambes d\u2019atterrissage (le cas \u00e9ch\u00e9ant) sont-elles s\u00fbres et ne risquent-elles pas de tomber pendant la conduite?",
                    "guards": "V\u00e9rifiez que les protections lat\u00e9rales et les protections arri\u00e8re anti-encastrement sont install\u00e9es si n\u00e9cessaire, et qu\u2019elles ne sont pas dangereuses ou endommag\u00e9es"
                },
                "spray": {
                    "title": "Rabats antiprojections",
                    "required": "Des clapets antiprojections sont-ils n\u00e9cessaires?",
                    "secure": "Sont-ils s\u00e9curis\u00e9s?",
                    "damaged": "Sont-ils endommag\u00e9s?",
                    "clogged": "Sont-ils obstru\u00e9s par la boue ou les d\u00e9bris?"
                },
                "wheels": {
                    "title": "Fixation des pneus et des roues",
                    "secure": "Les pneus et les roues sont-ils s\u00e9curis\u00e9s?",
                    "tread": "Les pneus ont-ils une profondeur de bande de roulement d\u2019au moins 1 mm?",
                    "inflated": "Les pneus sont-ils gonfl\u00e9s correctement?",
                    "cuts": "Y a-t-il des coupures profondes dans le flanc du pneu ?",
                    "cord": "Y a-t-il un cordon visible n\u2019importe o\u00f9 sur le pneu ?",
                    "nuts": "Tous les \u00e9crous de roue sont-ils assez serr\u00e9s? - Pour ce faire, vous pouvez v\u00e9rifier si les indicateurs d\u2019\u00e9crou de roue (s\u2019ils sont install\u00e9s) ont boug\u00e9 ",
                    "twin": "Y a-t-il des objets ou des d\u00e9bris coinc\u00e9s entre les roues jumel\u00e9es?"
                },
                "brakes": {
                    "title": "Conduites de frein et frein de stationnement de la remorque",
                    "couplings": "Les raccords sont-ils exempts de d\u00e9bris et au bon endroit ?",
                    "damage": "Y a-t-il des dommages ou de l\u2019usure des conduites de frein?",
                    "trailer_brake": "Le frein de stationnement de la remorque fonctionne-t-il correctement?"
                },
                "electrical": {
                    "title": "Branchements \u00e9lectriques",
                    "insulated": "Le c\u00e2blage visible est-il isol\u00e9 ?",
                    "risk": "Un c\u00e2blage visible risque-t-il d\u2019\u00eatre coinc\u00e9 ou endommag\u00e9?",
                    "coupling": "Tous les attelages \u00e9lectriques de remorque sont-ils correctement branch\u00e9s ?",
                    "switches": "Tous les interrupteurs \u00e9lectriques fonctionnent-ils correctement?"
                },
                "coupling": {
                    "title": "S\u00e9curit\u00e9 du couplage",
                    "primary": "La remorque est-elle correctement situ\u00e9e dans la sellette d\u2019attelage ou l\u2019attelage?",
                    "secondary": "Les dispositifs de verrouillage secondaires sont-ils dans la bonne position?"
                },
                "load": {
                    "title": "S\u00e9curit\u00e9 du chargement",
                    "secure": "Votre charge est-elle prot\u00e9g\u00e9e contre les mouvements?",
                    "guidance": "Avez-vous besoin de conseils concernant la s\u00e9curit\u00e9 de votre chargement?"
                },
                "numberplate": {
                    "title": "Plaque d'immatriculation",
                    "broken": "La plaque d\u2019immatriculation est-elle cass\u00e9e?",
                    "incorrect": "La plaque d\u2019immatriculation est-elle incorrecte ou mal espac\u00e9e ?",
                    "dirty": "La plaque d\u2019immatriculation est-elle sale ?",
                    "faded": "La plaque d\u2019immatriculation est-elle fan\u00e9e?",
                    "covered": "La plaque d\u2019immatriculation est-elle recouverte de quelque chose?"
                },
                "reflectors": {
                    "title": "R\u00e9flecteurs (y compris les catadioptres lat\u00e9raux)",
                    "missing": "Les r\u00e9flecteurs manquent-ils?",
                    "broken": "Les r\u00e9flecteurs sont-ils cass\u00e9s?",
                    "insecure": "Les r\u00e9flecteurs ne sont-ils pas s\u00e9curis\u00e9s?",
                    "fitted": "Les r\u00e9flecteurs sont-ils correctement install\u00e9s?",
                    "colour": "Les r\u00e9flecteurs sont-ils de la mauvaise couleur?",
                    "covered": "Les r\u00e9flecteurs sont-ils masqu\u00e9s par la salet\u00e9 ou d\u2019autres objets?"
                },
                "plates": {
                    "title": "Marquages et plaques d\u2019avertissement (y compris les marquages \u00e0 grande visibilit\u00e9)",
                    "colour": "Les marquages et les plaques d\u2019avertissement sont-ils de la bonne couleur?",
                    "visible": "Les marquages et les plaques d\u2019avertissement sont-ils visibles?",
                    "secured": "Les marquages et les plaques d\u2019avertissement sont-ils solidement fix\u00e9s?",
                    "obscured": "Les marques et les plaques d\u2019avertissement sont-elles masqu\u00e9es par la salet\u00e9 ou d\u2019autres objets?",
                    "hazard": "Si le v\u00e9hicule transporte des marchandises dangereuses, les panneaux d\u2019information sur les dangers indiquent-ils les informations correctes pour le chargement?",
                    "hazard_visible": "Si le v\u00e9hicule transporte des marchandises dangereuses, les panneaux d\u2019information sur les dangers sont-ils visibles?",
                    "hazard_secured": "Si le v\u00e9hicule transporte des marchandises dangereuses, les panneaux d\u2019information sur les dangers sont-ils solidement fix\u00e9s?",
                    "hazard_covered": "Si le v\u00e9hicule transporte des marchandises dangereuses, les panneaux d\u2019information sur les dangers sont-ils masqu\u00e9s par la salet\u00e9 ou d\u2019autres objets?"
                }
            },
            "before_use": {
                "title": "Avant utilisation",
                "general": {
                    "title": "G\u00e9n\u00e9ralit\u00e9s",
                    "previous": "Tous les d\u00e9fauts signal\u00e9s pr\u00e9c\u00e9demment ont-ils \u00e9t\u00e9 corrig\u00e9s?"
                },
                "leaks": {
                    "title": "Fuites \u00e9videntes",
                    "any": "Y a-t-il des signes de fuite?"
                },
                "hydraulics": {
                    "title": "Niveau de fluide hydraulique",
                    "fluid_level": "Le r\u00e9servoir est-il au bon niveau?"
                },
                "carriage": {
                    "title": "M\u00e2t et chariot",
                    "damage": "Y a-t-il des dommages ou des objets \u00e9trangers au m\u00e2t et au chariot?",
                    "lubrication": "Y a-t-il une lubrification appropri\u00e9e du m\u00e2t et du chariot?"
                },
                "chains_bolts": {
                    "title": "Cha\u00eenes et boulons de fixation",
                    "damage": "Y a-t-il des liens ou des boulons de fixation endommag\u00e9s?",
                    "stretching": "Y a-t-il des \u00e9tirements \u00e9vidents?",
                    "lubrication": "Y a-t-il une lubrification appropri\u00e9e?"
                },
                "forks": {
                    "title": "Fourchettes",
                    "cracks": "Y a-t-il des signes de fissures sur les fourches, en particulier au niveau des talons, ou tout autre signe de dommage ou d\u2019usure excessive",
                    "damage": "Y a-t-il d\u2019autres signes de dommages ou d\u2019usure excessive sur les fourches?",
                    "pins": "Y a-t-il d\u2019autres signes de dommages ou d\u2019usure excessive sur les goupilles de fixation?"
                },
                "backrest": {
                    "title": "Dossier\/Extension",
                    "damage": "Y a-t-il des signes de dommages ou de d\u00e9bris sur le dossier et l\u2019extension?"
                },
                "attachments": {
                    "title": "Pi\u00e8ces jointes",
                    "secure": "Les accessoires sont-ils fix\u00e9s aux fixations et aux points de verrouillage?",
                    "hydraulics": "L\u2019hydraulique et les autres connexions sont-elles s\u00e9curis\u00e9es ?"
                },
                "wheels": {
                    "title": "Pneus \/ Roues \/ \u00c9crous",
                    "tyres": "Y a-t-il des signes \u00e9vidents de dommages aux pneus, y compris des coupures et l\u2019usure de la bande de roulement?",
                    "pressure": "Les pneus pneumatiques sont-ils \u00e0 la bonne pression ?",
                    "damage": "Les roues, y compris les jantes, sont-elles endommag\u00e9es ?",
                    "nuts": "Les \u00e9crous de roue sont-ils desserr\u00e9s?"
                },
                "seat": {
                    "title": "Si\u00e8ge et ceinture de s\u00e9curit\u00e9",
                    "secure": "Le si\u00e8ge est-il correctement fix\u00e9 au camion et n\u2019est-il pas desserr\u00e9 ou endommag\u00e9?",
                    "belt": "La ceinture de s\u00e9curit\u00e9 ou autre dispositif de retenue est-elle bien fix\u00e9e et fonctionne-t-elle correctement?"
                },
                "steering": {
                    "title": "Direction",
                    "acceptable": "La direction semble-t-elle normale, sans jeu excessif ni mouvement inhabituel?"
                },
                "service_brakes": {
                    "title": "Freins de service",
                    "acceptable": "Les freins sont-ils normaux et fonctionnent-ils efficacement?"
                },
                "parking_brakes": {
                    "title": "Freins de stationnement",
                    "acceptable": "Le frein de stationnement fonctionne-t-il efficacement et se desserre-t-il correctement?"
                },
                "controls": {
                    "title": "Contr\u00f4les de fonctionnement",
                    "acceptable": "Toutes les commandes au pied et \u00e0 la main fonctionnent-elles correctement et ne sont-elles pas obstru\u00e9es de quelque fa\u00e7on que ce soit?"
                },
                "system": {
                    "title": "Syst\u00e8me d\u2019exploitation",
                    "acceptable": "Si le camion est \u00e9quip\u00e9 de syst\u00e8mes informatis\u00e9s, ont-ils \u00e9t\u00e9 initialis\u00e9s correctement et fonctionnent-ils?"
                },
                "warning_lights": {
                    "title": "Voyants d\u2019avertissement",
                    "operating": "Tous les voyants d\u2019avertissement fonctionnent-ils correctement?",
                    "any_warning": "Des voyants d\u2019avertissement sont-ils allum\u00e9s?"
                },
                "dashboard": {
                    "title": "Jauges\/Instruments",
                    "acceptable": "Toutes les jauges et autres instruments fonctionnent-ils correctement ?"
                },
                "lights": {
                    "title": "Lumi\u00e8res\/Balise",
                    "acceptable": "Toutes les lumi\u00e8res et balises fonctionnent-elles correctement?"
                },
                "horn": {
                    "title": "Corne",
                    "acceptable": "Le klaxon fonctionne-t-il correctement?"
                },
                "alarms": {
                    "title": "Alarmes",
                    "acceptable": "L\u2019alarme fonctionne-t-elle correctement ?"
                },
                "warnings": {
                    "title": "Autres dispositifs d\u2019avertissement",
                    "acceptable": "Si d\u2019autres syst\u00e8mes d\u2019avertissement sont install\u00e9s, fonctionnent-ils correctement?"
                },
                "guards": {
                    "title": "Protecteurs et housses de s\u00e9curit\u00e9",
                    "acceptable": "Tous les protecteurs et couvercles de s\u00e9curit\u00e9 sont-ils correctement fix\u00e9s et s\u00e9curis\u00e9s?"
                },
                "body": {
                    "title": "Carrosserie",
                    "acceptable": "Y a-t-il des dommages apparents \u00e0 la carrosserie, y compris les rayonnages et autres \u00e9quipements?"
                }
            }
        },
        "LoadingReport": {
            "title": "Rapport de chargement",
            "location_name": "Nom de l\u2019emplacement de chargement",
            "dashboard_summary": "Les v\u00e9hicules doivent \u00eatre adapt\u00e9s \u00e0 toutes les charges transport\u00e9es, et il est particuli\u00e8rement important que le v\u00e9hicule dispose de points d\u2019ancrage ad\u00e9quats pour s\u2019assurer que les charges peuvent \u00eatre transport\u00e9es en toute s\u00e9curit\u00e9.  Utilisez ce rapport pour consigner vos observations et la preuve que toutes les pr\u00e9cautions n\u00e9cessaires ont \u00e9t\u00e9 prises.",
            "date": "Date du rapport",
            "cargo": "Cargaison"
        },
        "JourneyReport": {
            "title": "Rapport sur la s\u00e9curit\u00e9 du v\u00e9hicule",
            "new": "Commencer Nouveau",
            "list": "Acc\u00e8s enregistr\u00e9",
            "report_id": "ID du rapport",
            "start": "Commencer",
            "stop": "V\u00e9rifier",
            "end": "Fin",
            "start_time": "Heure de d\u00e9but",
            "end_time": "Heure de fin",
            "dashboard_summary": "Le rapport de s\u00e9curit\u00e9 du v\u00e9hicule vous permet d\u2019enregistrer, simplement et efficacement, un journal g\u00e9olocalis\u00e9 horodat\u00e9 des contr\u00f4les effectu\u00e9s, chaque fois que votre v\u00e9hicule est laiss\u00e9 sans surveillance ou que votre chargement est interagi afin de minimiser le risque d\u2019avoir des immigrants clandestins \u00e0 bord.",
            "name": "Titre du voyage",
            "date_time": "Date et heure",
            "trailer_number": "Num\u00e9ro de remorque",
            "security_device_type": "Type d\u2019appareil de s\u00e9curit\u00e9",
            "security_device_type_other": "Veuillez pr\u00e9ciser",
            "security_device_number": "Num\u00e9ro de s\u00e9rie de l\u2019appareil de s\u00e9curit\u00e9",
            "security_device_photos": "Images des dispositifs de s\u00e9curit\u00e9",
            "device_type": {
                "Seal": "Phoque",
                "Lock": "Serrure",
                "Other": "Autre"
            },
            "check_load_space": "V\u00e9rifier que l\u2019espace de chargement est efficacement prot\u00e9g\u00e9 contre les acc\u00e8s non autoris\u00e9s ?",
            "check_cargo": "V\u00e9rifier que la cargaison est efficacement prot\u00e9g\u00e9e contre tout acc\u00e8s non autoris\u00e9? (le cas \u00e9ch\u00e9ant)",
            "check_devices": "V\u00e9rifiez toutes les serrures, scell\u00e9s et autres dispositifs de s\u00e9curit\u00e9 pour d\u00e9tecter tout signe d\u2019alt\u00e9ration, de dommage et de r\u00e9paration ou de remplacement non autoris\u00e9?",
            "check_cables": "V\u00e9rifier que les c\u00e2bles et sangles TIR et les \u0153illets connexes ont \u00e9t\u00e9 v\u00e9rifi\u00e9s pour d\u00e9celer tout signe d\u2019alt\u00e9ration, de d\u00e9t\u00e9rioration ou de r\u00e9paration ou de remplacement non autoris\u00e9? (le cas \u00e9ch\u00e9ant)",
            "check_shell": "V\u00e9rifiez que la coque ext\u00e9rieure ou le couvercle en tissu est v\u00e9rifi\u00e9 pour d\u00e9tecter tout signe d\u2019acc\u00e8s non autoris\u00e9? (le cas \u00e9ch\u00e9ant)",
            "check_external_storage": "V\u00e9rifiez tous les compartiments de rangement externes pour d\u00e9tecter tout signe d\u2019acc\u00e8s non autoris\u00e9?",
            "check_wind_deflectors": "V\u00e9rifiez les d\u00e9flecteurs de vent pour d\u00e9tecter tout signe d\u2019acc\u00e8s non autoris\u00e9?",
            "check_beneath": "V\u00e9rifiez sous le v\u00e9hicule s\u2019il y a des signes d\u2019acc\u00e8s non autoris\u00e9?",
            "check_inside": "V\u00e9rifiez \u00e0 l\u2019int\u00e9rieur du v\u00e9hicule s\u2019il y a des signes d\u2019acc\u00e8s non autoris\u00e9?",
            "check_roof_inside": "V\u00e9rifiez le toit du v\u00e9hicule depuis l\u2019int\u00e9rieur du v\u00e9hicule pour d\u00e9tecter tout signe d\u2019acc\u00e8s non autoris\u00e9?",
            "check_person": "V\u00e9rifiez qu\u2019aucune personne n\u2019a obtenu un acc\u00e8s non autoris\u00e9 ?",
            "check_documentaion": "V\u00e9rifiez que toute la documentation n\u00e9cessaire est correcte?",
            "any_signs": "Y a-t-il des signes indiquant qu\u2019une personne a obtenu, ou a tent\u00e9 d\u2019obtenir, un acc\u00e8s non autoris\u00e9 au v\u00e9hicule?",
            "endorsement": "Approbation d\u2019un tiers qui a effectu\u00e9 les v\u00e9rifications ci-dessus (le cas \u00e9ch\u00e9ant)",
            "instructions_complete_journey": "Pour afficher vos ch\u00e8ques de trajet enregistr\u00e9s, s\u00e9lectionnez simplement l\u2019entr\u00e9e correspondante ci-dessous. Si vous souhaitez les partager avec un tiers, vous pouvez facilement exporter vos ch\u00e8ques de parcours au format PDF. Cliquez simplement sur le bouton \u00ab\u00a0Rapport PDF\u00a0\u00bb, puis s\u00e9lectionnez le bouton \u00ab\u00a0partager\u00a0\u00bb de votre navigateur et choisissez comment vous souhaitez le partager \u00e0 partir de votre appareil.",
            "add_stop": "Ajouter un nouveau ch\u00e8que",
            "add_final": "Fin de voyage"
        },
        "CrimeReport": {
            "title": "Rapport sur la criminalit\u00e9",
            "dashboard_summary": "Consignez \u00e0 des fins d\u2019audit et juridiques tous les incidents criminels qui se produisent dans le cadre de vos fonctions. Tenez un registre des mesures prises, des autorit\u00e9s impliqu\u00e9es et des d\u00e9tails pertinents.",
            "date": "Date de l\u2019incident",
            "type": "Type d\u2019incident",
            "types": {
                "Robbery": "Vol",
                "Mugging": "Agression",
                "Assault": "Assaut",
                "VehicleTheft": "Vol de v\u00e9hicules",
                "VehicleDamage": "Dommages au v\u00e9hicule",
                "FuelTheft": "Vol de carburant",
                "Hijacking": "D\u00e9tournement",
                "Terrorist": "Incident terroriste",
                "Stowaways": "Clandestins"
            },
            "description": "Description de l\u2019incident",
            "parties_involved": "Parties concern\u00e9es",
            "witnesses": "T\u00e9moins",
            "police_report": "Rapport de police",
            "filed_with_police": "Rapport de police d\u00e9pos\u00e9?",
            "police_branch_name": "Circonscription",
            "police_reference": "Num\u00e9ro de rapport d\u2019infraction",
            "officer_name": "D\u00e9clarant",
            "police_contact_number": "T\u00e9l\u00e9phone",
            "follow_up_actions": "Mesures de suivi"
        },
        "BorderReport": {
            "title": "Rapport sur la fronti\u00e8re",
            "dashboard_summary": "Preuve que vous avez pris toutes les mesures n\u00e9cessaires pour assurer l\u2019int\u00e9grit\u00e9 de votre chargement et de votre v\u00e9hicule lors du franchissement des fronti\u00e8res internationales avec votre v\u00e9hicule.",
            "time": "Heure du rapport",
            "general_checks": "V\u00e9rifications g\u00e9n\u00e9rales des v\u00e9hicules",
            "inside": "\u00c0 l\u2019int\u00e9rieur du v\u00e9hicule",
            "outside": "\u00c0 l\u2019ext\u00e9rieur du v\u00e9hicule",
            "is_damage": "Y a-t-il des dommages au toit ou aux panneaux \/ feuilles de carrosserie du v\u00e9hicule?",
            "other_checks": "Autres contr\u00f4les",
            "external_compartments": "Les compartiments ext\u00e9rieurs sont-ils d\u00e9gag\u00e9s et scell\u00e9s ?",
            "underside_accessible": "Le dessous du v\u00e9hicule et d\u2019autres cachettes accessibles sont-ils d\u00e9gag\u00e9s?",
            "seals_intact": "Tous les scell\u00e9s sont-ils intacts?",
            "locks_intact": "Tous les cadenas et les serrures de porte sont ferm\u00e9s?",
            "tir_intact": "La corde TIR est-elle mont\u00e9e et scell\u00e9e?",
            "life_signs_checked": "Les contr\u00f4les de CO2, de cam\u00e9ra et de moniteur Heartbeat ont-ils \u00e9t\u00e9 effectu\u00e9s par un organisme externe?"
        },
        "TrafficIncidentReport": {
            "title": "Rapport d\u2019accident",
            "dashboard_summary": "Si vous \u00eates malheureusement impliqu\u00e9 dans un accident impliquant votre v\u00e9hicule, utilisez ce rapport pour enregistrer tous les renseignements et preuves pertinents pour vous soutenir \u00e0 des fins de v\u00e9rification et juridiques.",
            "date": "Date\/heure de l\u2019accident",
            "description": "Description de l\u2019accident",
            "description_instructions": "Veuillez t\u00e9l\u00e9charger un dessin de l\u2019accident",
            "injuries_damage": "Blessures et dommages",
            "driver_injuries": "Le conducteur a-t-il subi des blessures?",
            "passengers_injuried": "Des passagers ont-ils subi des blessures?",
            "no_injuries": "Aucune blessure",
            "injured_passengers": "Noms et adresses des passagers bless\u00e9s",
            "no_damange": "Aucun dommage",
            "vehicle_damaged": "Le v\u00e9hicule a-t-il subi des dommages?",
            "vehicle_damage": "Description des dommages au v\u00e9hicule",
            "parties_involved": "D\u00e9tails du pilote tiers"
        },
        "inactive": {
            "title": "Contr\u00f4les de conformit\u00e9",
            "content": "Pr\u00e9sentation de la solution de conformit\u00e9 compl\u00e8te de M-PARK pour les gestionnaires de flottes et les conducteurs. Avec M-PARK, vous pouvez g\u00e9rer sans effort toutes vos t\u00e2ches de conformit\u00e9 en un seul endroit, assurant la s\u00e9curit\u00e9 de vos conducteurs, v\u00e9hicules et charges, respecter les r\u00e9glementations en vigueur et \u00e9viter les p\u00e9nalit\u00e9s co\u00fbteuses.\n\nM-PARK est une application pour les conducteurs pour enregistrer les contr\u00f4les quotidiens des v\u00e9hicules g\u00e9olocalis\u00e9s, les rapports de crime, les rapports de chargement, les rapports de passage frontalier, les rapports d'accident et les rapports de s\u00e9curit\u00e9 des v\u00e9hicules, ainsi que des photos et documents \u00e0 l'appui, tout sur un appareil intelligent.\n\nNotre syst\u00e8me de pointe dispose d'une interface intuitive et de capacit\u00e9s de reporting avanc\u00e9es, ce qui le rend facile \u00e0 utiliser pour surveiller les performances de votre conformit\u00e9 et identifier les domaines qui n\u00e9cessitent une attention particuli\u00e8re. M-PARK dispose de tout ce dont vous avez besoin pour rester conforme et minimiser votre risque.\n\nMais ce n'est pas tout. Avec M-PARK, vous b\u00e9n\u00e9ficierez \u00e9galement de :\n - Des workflows personnalisables pour r\u00e9pondre \u00e0 vos besoins de conformit\u00e9 sp\u00e9cifiques\n - Des alertes et notifications pour vous informer de tout probl\u00e8me et t\u00e2che\n - Un stockage s\u00e9curis\u00e9 bas\u00e9 sur le cloud pour toutes vos donn\u00e9es de conformit\u00e9, accessible de n'importe o\u00f9, \u00e0 tout moment\n - Facile \u00e0 partager avec tout tiers pertinent\n\nNe laissez pas la conformit\u00e9 \u00eatre un casse-t\u00eate. Choisissez M-PARK et rationalisez votre gestion de conformit\u00e9 d\u00e8s aujourd'hui. Visitez [www.m-park.co.uk](https:\/\/www.m-park.co.uk) pour en savoir plus. M-PARK, votre voyage commence ici.",
            "message_to_drivers": "Si ces fonctionnalit\u00e9s vous int\u00e9ressent, parlez-en \u00e0 votre gestionnaire de flotte.",
            "message_to_clients": "Si vous \u00eates int\u00e9ress\u00e9 par ces fonctionnalit\u00e9s, vous pouvez activer sur l\u2019une de vos flottes \u00e0 partir de la page d\u2019\u00e9dition",
            "button_text": "Activer la conformit\u00e9 dans les param\u00e8tres"
        }
    },
    "confirm_booking": "Confirmer la r\u00e9servation",
    "confirm_your_email": "Confirmez votre adresse email",
    "conform_dialog": {
        "are_you_sure": "Es-tu s\u00fbr?",
        "order_confirm": {
            "message": "Approuver la commande\u00a0?",
            "cancel": "Annuler",
            "accept": "D'accord"
        },
        "order_reject": {
            "message": "Refuser la commande\u00a0?",
            "cancel": "Annuler",
            "accept": "D'accord"
        },
        "cancel": "Annuler",
        "delete": "Supprimer",
        "accept": "D'accord",
        "reset": "R\u00e9initialiser",
        "no": "Non",
        "yes": "Oui",
        "cancel_order": "annuler la commande",
        "extend": "Prolonger la r\u00e9servation",
        "new_parking_price_will_be_calculated_and_order_will_be_extended": "Un nouveau prix de stationnement sera calcul\u00e9 et la r\u00e9servation sera prolong\u00e9e jusqu'\u00e0 la date et l'heure s\u00e9lectionn\u00e9es",
        "clear_all_selected_vehicles": "Effacer tous les v\u00e9hicules s\u00e9lectionn\u00e9s?"
    },
    "contact_name": "Nom du contact",
    "continue": "Continuez",
    "continue_sign_in": "Please continue to sign in below",
    "cost": "Co\u00fbt",
    "country": "Pays",
    "county": "Comt\u00e9",
    "covered_costs": "Co\u00fbts couverts",
    "create": "Cr\u00e9er",
    "create_a_booking": "Cr\u00e9er une r\u00e9servation",
    "create_account": "Cr\u00e9er un compte",
    "create_password_header": "Veuillez continuer \u00c3\u00a0 vous connecter ci-dessous",
    "create_user": "Cr\u00e9er un utilisateur",
    "credit": "Cr\u00e9dit",
    "credit_limit": "Limite de cr\u00e9dit M-Park",
    "credit_zero_definition": "0 = {{definition}}",
    "csv_file_should_contain_the_following_columns": "Le fichier CSV doit contenir les colonnes suivantes\u00a0:",
    "currencies": "Devises",
    "currency": "Monnaie",
    "custom": "Coutume",
    "daily": "du quotidien",
    "dashboard": {
        "dashboard": "Tableau de bord",
        "total_m_park_fee": "Frais totaux de M-Park",
        "parking_occurrences": "Cas de stationnement",
        "non_parking_occurrences": "\u00c9v\u00e9nements autres que le stationnement",
        "active_sites": "Sites actifs",
        "active_users": "Utilisateurs actifs",
        "active_accounts": "Comptes actifs",
        "total_site_revenue": "Revenu total du site",
        "total_revenue": "Revenu total",
        "total_non_parking_revenue": "Total des revenus hors stationnement",
        "average_order_value": "Valeur moyenne des commandes",
        "platform_dashboard": "Tableau de bord de la plateforme",
        "driver_dashboard": "Tableau de bord du conducteur",
        "company_dashboard": "Tableau de bord clients",
        "fleet_manager_dashboard": "Tableau de bord du gestionnaire de flotte",
        "supplier_dashboard": "Tableau de bord fournisseur",
        "site_manager_dashboard": "Tableau de bord du gestionnaire de site",
        "operator_dashboard": "Tableau de bord de l'op\u00e9rateur",
        "supplier_operator_dashboard": "Tableau de bord de l'op\u00e9rateur fournisseur",
        "client_operator_dashboard": "Tableau de bord de l'op\u00e9rateur client",
        "platform_operator_dashboard": "Tableau de bord de l'op\u00e9rateur de la plateforme",
        "platform_finance_dashboard": "Tableau de bord financier de la plateforme"
    },
    "date": "Date",
    "date_first_issued": "Date de premi\u00e8re utilisation",
    "date_first_used": "Date de premi\u00e8re utilisation",
    "date_time": "Date et l\u1f3aFheure",
    "date_time_completed": "Date\/Heure d\u2019ach\u00e8vement",
    "day": "Jour",
    "delete": "Supprimer",
    "delete_current_client": "Supprimer le client\u00a0?",
    "delete_payment_method": "Supprimer le mode de paiement\u00a0?",
    "delete_selected_image": "Supprimer l\u2019image s\u00e9lectionn\u00e9e ?",
    "delete_selected_vat_record": "Supprimer l\u2019enregistrement TVA s\u00e9lectionn\u00e9?",
    "description": "La description",
    "did_not_receive_security_code": "N'a pas re\u00e7u cliquez ici pour envoyer \u00e0 nouveau",
    "disapprove": "D\u00e9sapprouver",
    "discount": "Remise",
    "discount_type": "Type de remise",
    "discount_value": "Valeur de remise",
    "dismiss": "Rejeter",
    "dismiss_all": "Rejeter la totalit\u00e9",
    "dismiss_all_notification": "Ignorer toutes les notifications\u00a0?",
    "dismiss_this_notification": "Ignorer ces notifications\u00a0?",
    "distance": "Distance",
    "distance_units": "Unit\u00e9s de distance",
    "downgrade": "D\u00e9classer",
    "download_reports": "T\u00e9l\u00e9charger le rapport",
    "download_sample_file": "T\u00e9l\u00e9charger un exemple de fichier",
    "drawer_link_titles": {
        "dashboard": "Tableau de bord",
        "users": "Utilisateurs",
        "vehicles": "V\u00e9hicules",
        "favourite_sites": "Sites favoris",
        "settings": "R\u00e9glages",
        "need_help": "Besoin d'aide?",
        "clients": "Clients",
        "fleets": "Flottes",
        "suppliers": "Fournisseurs",
        "sites": "Des sites",
        "promotion": "Promotion",
        "promotions": "Promotions",
        "bookings": "R\u00e9servations",
        "orders": "Ordres",
        "reports": "Rapports",
        "find_site": "Trouver un site",
        "scan_qr_code": "Scanner le code QR",
        "my_history": "Mon histoire",
        "compliance": "Conformit\u00e9"
    },
    "driver": "Conducteur",
    "driver_checked_out_at": "Chauffeur sorti \u00e0 {{checkedOutTime}}",
    "driver_have_been_checked_in": "Le conducteur a \u00e9t\u00e9 enregistr\u00e9",
    "driver_have_been_checked_out": "Le pilote a \u00e9t\u00e9 v\u00e9rifi\u00e9",
    "driver_parking_period_expires_on": "La p\u00e9riode de stationnement payant du conducteur expire le {{expireDate}}",
    "driver_settings": "Param\u00e8tres du pilote",
    "drivers": "Conducteurs)",
    "drivers_imported": "Pilotes import\u00e9s",
    "duration": "Dur\u00e9e",
    "east": "Est",
    "east_short": "E",
    "edit": "Modifier",
    "edit_payment_method": "Modifier le mode de paiement",
    "email_address": "Adresse e-mail",
    "email_notifications": "Notifications par email",
    "enter_card_holder_name": "Entrez le nom du titulaire de la carte",
    "enter_card_number": "Entrez le num\u00e9ro de la carte",
    "enter_email_address": "Entrez l'e-mail",
    "enter_expire": "Entrez la date d'expiration",
    "enter_message": "Entrer un message",
    "enter_name": "Entrez le nom",
    "enter_order_id_manually": "Saisir l'ID de commande manuellement",
    "enter_parking_site_name": "Entrez un nom de site de stationnement",
    "enter_parking_site_website": "Acc\u00e9der au site Web du site de stationnement",
    "enter_phone_number": "Entrez le num\u00e9ro de t\u00e9l\u00e9phone",
    "enter_post_code": "Entrez le code postal",
    "enter_postcode_town": "Entrez le code postal\/la ville",
    "enter_town_city": "Entrez la ville\/la ville",
    "enter_username": "Saisissez votre nom d'utilisateur",
    "enter_your_security_code": "Entrer votre code de s\u00e9curit\u00e9",
    "entity": "Entit\u00e9",
    "errors": {
        "error_401_message": "401 | DEMANDE NON AUTORIS\u00c9E",
        "error_404_message": "404 | PAGE NON TROUV\u00c9E",
        "error_0_message": "Pas d'Internet",
        "page_error": "ERREUR DE PAGE\u00a0!",
        "sorry_an_error_has_occurred_to_this_page": "D\u00e9sol\u00e9, cette page n'a pas pu \u00eatre charg\u00e9e",
        "you_are_not_authorized_to_access_the_page_you_requested": "D\u00e9sol\u00e9, vous n'\u00eates pas autoris\u00e9 \u00e0 acc\u00e9der \u00e0 la page demand\u00e9e",
        "page_not_available": "D\u00e9sol\u00e9, la page que vous recherchez n'existe pas, a \u00e9t\u00e9 supprim\u00e9e, le nom a chang\u00e9 ou est temporairement indisponible"
    },
    "example": "Exemple",
    "expire": "Expirer",
    "expires": "Expire",
    "expiry_date": "Date d'expiration",
    "export": "Exporter",
    "export_code": "Exporter le code QR",
    "export_payouts": "Exporter les paiements",
    "export_payouts_file_format": "Exporter le format de fichier des paiements",
    "export_pdf": "Rapport d\u2019exportation",
    "extend_booking": "Prolonger la r\u00e9servation",
    "facilities": "Installations",
    "field": "Champ",
    "filter_by_site_name": "Filtrer par nom de site",
    "filters_labels": {
        "show_advance_filters": "Afficher les filtres avanc\u00e9s",
        "hide_advance_filters": "Masquer les filtres avanc\u00e9s",
        "filter_by_creation_date": "Filtrer par date de cr\u00e9ation de compte",
        "filter_by_country": "Filtrer par nom de pays",
        "filter_by_region": "Filtrer par r\u00e9gion",
        "filter_by_account_status": "Filtrer par statut de compte",
        "search_for_email_address": "Rechercher une adresse e-mail",
        "filter_by_company_name": "Filtrer par nom d'entreprise",
        "filter_by_site": "Filtrer par site",
        "filter_by_user": "Filtrer par utilisateur",
        "filter_by_client": "Filtrer par clients",
        "filter_by_suppliers": "Filtrer par fournisseur",
        "filter_by_fleet": "Filtrer par Flotte",
        "filter_by_product": "Filtrer par Produit",
        "start_date": "Date de d\u00e9but",
        "end_date": "Date de fin",
        "filter_by_primary_contact_name": "Filtrer par nom de contact principal",
        "credit_status": "Statut du cr\u00e9dit",
        "between_these_dates": "Entre ces dates",
        "company_name": "Nom de l'entreprise",
        "show_promotion": "Afficher la promotion\u00a0?",
        "active_date": "Date d'activation",
        "expire_date": "Date d'expiration",
        "order_date": "Date de commande",
        "due_date": "\u00c9ch\u00e9ance",
        "balance": "Solde",
        "figures": "Les figures",
        "any": "Quelconque",
        "no_min": "Pas de min",
        "no_max": "Pas de maximum",
        "show_filters": "Afficher les filtres",
        "apply": "Appliquer des filtres"
    },
    "find_site": "Trouver un site",
    "find_site_to_book": "Trouver un site pour r\u00e9server",
    "find_site_with_promotion_to_book": "Trouver un site avec des promotions",
    "find_sites_with_promotions": "Trouver un site avec des promotions",
    "find_user": "Rechercher un utilisateur",
    "first_line_address": "Adresse de 1\u00e8re ligne",
    "first_line_billing_address": "1\u00e8re ligne Adresse de facturation",
    "first_name": "Pr\u00e9nom",
    "fleet": "Flotte",
    "fleet_name": "Nom de la flotte",
    "fleet_name_company": "Client | Nom de la flotte",
    "fleet_spending_caps": "Plafonds de d\u00e9penses de flotte par d\u00e9faut",
    "fleets": "Flotte(s)",
    "fleets_imported": "Flottes import\u00e9es",
    "forgot_password": "Mot de passe oubli\u00e9?",
    "form_validation": {
        "is_required": "{{attribute}} est requis",
        "min": "{{attribute}} doit \u00eatre sup\u00e9rieur ou \u00e9gal \u00e0 {{value}}",
        "max_length": "Le {{attribute}} ne doit pas d\u00e9passer {{max}}{{length}}.",
        "invalid_email_address": "S'il vous pla\u00eet, utilisez une adresse mail valide",
        "password_invalid_min_length": "Le mot de passe ne doit pas comporter moins de {{length}}\u00a0caract\u00e8res",
        "code_invalid_min_length": "Le code ne doit pas \u00eatre inf\u00e9rieur \u00e0 {{length}}\u00a0chiffres",
        "code_invalid_max_length": "Le code ne doit pas comporter plus de {{length}}\u00a0chiffres",
        "password_is_required": "Mot de passe requis",
        "code_is_required": "Le code est requis",
        "password_is_must_match": "Le mot de passe doit correspondre",
        "password_confirm_required": "Confirmation du mot de passe requise",
        "email_address_is_required": "Adresse e-mail est n\u00e9cessaire",
        "name_is_required": "Le nom est requis",
        "username_is_required": "Nom d'utilisateur est n\u00e9cessaire",
        "user_name_is_required": "Nom d'utilisateur est n\u00e9cessaire",
        "phone_number_is_required": "Le num\u00e9ro de t\u00e9l\u00e9phone est requis",
        "user_type_is_required": "Le type d'utilisateur est requis",
        "site_is_required": "Le site est requis",
        "message_is_required": "Un message est requis",
        "product_is_required": "Le produit est requis",
        "discount_is_required": "Une r\u00e9duction est requise",
        "start_date_is_required": "La date de d\u00e9but est requise",
        "expiry_date_is_required": "La date d'expiration est requise",
        "expiry_date_min": "La date d'expiration ne peut pas \u00eatre inf\u00e9rieure \u00e0 la date actuelle",
        "call_to_action_required": "L'appel \u00e0 l'action est requis",
        "link_to_action_required": "Le lien est requis",
        "site_name_is_required": "Le nom du site est requis",
        "description_is_required": "La description est obligatoire",
        "total_spaces_available_is_required": "Le nombre total d'espaces disponibles est requis",
        "site_currency_is_required": "La devise du site est requise",
        "supplier_is_required": "Le fournisseur est requis",
        "longitude_is_required": "Veuillez indiquer la longitude",
        "latitude_is_required": "La latitude est requise",
        "address_is_required": "L'adresse de 1\u00e8re ligne est obligatoire",
        "address_line_2_is_required": "L'adresse de 2\u00e8me ligne est obligatoire",
        "city_town_is_required": "Ville\/Ville est requis",
        "country_is_required": "Le pays est requis",
        "county_is_required": "Le comt\u00e9 est requis",
        "post_code_is_required": "Le code postal est requis",
        "parking_price_is_required": "Le prix du stationnement est requis",
        "parking_price_period_value_is_required": "La valeur de la p\u00e9riode est requise",
        "parking_price_per_period_type_is_required": "Une valeur par p\u00e9riode est requise",
        "parking_price_up_to_value_is_required": "Jusqu'\u00e0 la valeur est requise",
        "parking_price_up_to_period_is_required": "Jusqu'\u00e0 la p\u00e9riode est requis",
        "category_is_required": "La cat\u00e9gorie est requise",
        "price_is_required": "Le prix est requis",
        "commission_rate_type_is_required": "Le type de taux de commission est requis",
        "commission_value_is_required": "Le taux de commission est requis",
        "vehicle_registration_number_is_required": "Le num\u00e9ro d'immatriculation du v\u00e9hicule est requis",
        "primary_contact_name_is_required": "Le nom du contact est requis",
        "contact_name_is_required": "Le nom du contact est requis",
        "account_status_is_required": "L'\u00e9tat du compte est requis",
        "account_number_is_required": "Le num\u00e9ro de compte est requis",
        "currency_is_required": "La devise est requise",
        "account_type_is_required": "Le type de compte est requis",
        "vat_registration_number_required": "Le num\u00e9ro d'immatriculation \u00e0 la TVA est requis",
        "client_credit_limit_required": "Une limite de cr\u00e9dit est requise",
        "client_payment_terms_required": "Le d\u00e9lai de paiement est requis",
        "client_payment_terms_days_required": "Jours de d\u00e9lai de paiement requis",
        "expire_date_is_required": "La date d'expiration est requise",
        "security_code_is_required": "Le code de s\u00e9curit\u00e9 est requis",
        "card_number_is_required": "Le num\u00e9ro de carte est requis",
        "invalid_user_email": "Aucun utilisateur trouv\u00e9 avec cet e-mail",
        "account_number_required": "Le num\u00e9ro de compte est requis",
        "order_id_is_required": "L'ID de commande est requis",
        "payment_date_is_required": "La date de paiement est requise",
        "amount_is_required": "Le montant est requis",
        "ccv_is_required": "Le CCV est requis",
        "discount_type_is_required": "Le type de r\u00e9duction est requis",
        "company_is_required": "Le client est requis",
        "parking_limit_reached": "Cela d\u00e9passera votre plafond de stationnement",
        "credit_limit_reached": "Cela d\u00e9passera la limite de cr\u00e9dit du compte",
        "invalid_card_number": "Le num\u00e9ro de carte est invalide",
        "invalid_expire": "La carte a expir\u00e9",
        "invalid_ccv": "Le CVC n\u2019est pas valide",
        "image_required": "Veuillez t\u00e9l\u00e9charger une image",
        "invalid_qr_code": "Veuillez scanner un code QR M-Park valide",
        "addons_required_without_parking": "Comme vous ne vous garez pas, vous devez s\u00e9lectionner au moins un module compl\u00e9mentaire",
        "booking": {
            "invalid_instant_order_token": "Votre session a expir\u00e9, veuillez rescanner le code QR",
            "invalid_location": "Vous devez \u00eatre sur place pour scanner le code QR"
        },
        "not_enough_licences": "Vous avez besoin de suffisamment de licences de conformit\u00e9 pour tous les conducteurs de cette flotte"
    },
    "formats": {
        "date": "Faire MMM AA",
        "date_long": "dddd Do MMMM AAAA",
        "datetime": "Faire MMM AA HH\u00a0:\u00a0mm",
        "short_datetime": "JJ\/MM\/AA HH\u00a0:\u00a0mm",
        "time": "HH:mm:ss",
        "full_date": "D MMMM, AAAA",
        "full_date_and_time": "D MMMM, AAAA HH:mm:ss",
        "short_date": "JJ\/MM\/AA",
    },
    "free": "Libre",
    "from": "Depuis",
    "full_period": "P\u00e9riode compl\u00e8te",
    "get_directions": "Obtenir des directions",
    "global_commission": "Commissions mondiales",
    "go_to_booking": "Aller \u00e0 la r\u00e9servation",
    "go_to_clients": "Aller \u00e0 Clients",
    "go_to_dashboard": "Aller au tableau de bord",
    "go_to_fleet": "Aller \u00e0 Flotte",
    "go_to_fleets": "Aller aux flottes",
    "go_to_promotions": "Aller aux promotions",
    "go_to_sites": "Aller sur Sites",
    "go_to_suppliers": "Aller aux Fournisseurs",
    "go_to_users": "Aller aux utilisateurs",
    "go_to_vehicles": "Aller aux v\u00e9hicules",
    "got_to_settings": "Aller aux param\u00e8tres",
    "gr_code_location_error": "Nous n'avons pas pu obtenir votre position, veuillez activer le service de localisation pour pouvoir scanner le code QR",
    "grand_total": "Total",
    "help_message_receive": "Merci de nous avoir contact\u00e9, votre message est bien re\u00e7u",
    "home": "Domicile",
    "hour": "Heure",
    "id": "IDENTIFIANT",
    "images": "Images",
    "import": "Importer",
    "import_clients": "Importer des clients",
    "import_drivers": "Importer des pilotes",
    "import_fleets": "Importer des flottes",
    "import_payments": "Importer des paiements",
    "import_payouts": "Importer des paiements",
    "import_promotions": "Promotions d'importation",
    "import_sites": "Sites d'importation",
    "import_suppliers": "Fournisseurs d'importation",
    "import_users": "Importer des utilisateurs",
    "import_vehicles": "Importer des v\u00e9hicules",
    "inactive": "Inactif",
    "incl_vat": "TVA incluse",
    "instant_order": "Reserve maintenant",
    "journey_time": "Temps de parcours",
    "last_name": "Nom de famille",
    "last_ten_orders": "10 derni\u00e8res commandes",
    "last_updated": "Derni\u00e8re mise \u00e0 jour\u00a0",
    "latitude": "Latitude",
    "leave_feedback": "Laisser les commentaires",
    "link": "Lien",
    "list": "Liste",
    "list_of_site_names_separated_by_comma": "Liste des noms de sites s\u00e9par\u00e9s par une virgule.",
    "list_of_vehicles_registration_numbers_separated_by_comma": "Liste des num\u00e9ros d'immatriculation des v\u00e9hicules s\u00e9par\u00e9s par une virgule. est requis si le type d'utilisateur est conducteur",
    "list_of_vehicles_registration_numbers_separated_by_comma_driver": "Liste des num\u00e9ros d'immatriculation des v\u00e9hicules s\u00e9par\u00e9s par une virgule.",
    "loading": "Chargement...",
    "location": "Emplacement",
    "login": "Connexion",
    "logout": "Se d\u00e9connecter",
    "longitude": "Longitude",
    "longitude_latitude": "Longitude latitude",
    "manager": "Gestionnaire",
    "marketing": {
        "title": "Marketing",
        "directory": "R\u00e9pertoire",
        "page": "Voir la page",
        "active": "Actif",
        "description": "Possibilit\u00e9 de cr\u00e9er une page Web publique pour pr\u00e9senter votre site",
        "edit_mode": "Mode d\u2019\u00e9dition",
        "options": "Options de commercialisation",
        "licence_price": "Prix de la licence",
        "switch_sides": "C\u00f4t\u00e9 de commutation",
        "colours": {
            "change": "Changer les couleurs",
            "primary": "Primaire",
            "secondary": "Secondaire"
        },
        "button": {
            "click_to_edit": "Cliquez pour modifier",
            "edit": "Bouton Modifier",
            "title": "Titre",
            "href": "Lien",
            "target": "Cible",
            "targets": {
                "_self": "Onglet actuel",
                "_blank": "Nouvel onglet"
            },
            "variant": "Style"
        },
        "blocks": {
            "Addons": "Extensions",
            "CallToAction": "Appel \u00e0 l\u2019action",
            "Details": "D\u00e9tails",
            "Gallery": "Galerie",
            "MediaWithContent": "Image avec contenu",
            "Pricing": "Pris\u00e9e",
            "Services": "Services"
        }
    },
    "max_journey_duration": "Dur\u00e9e maximale du trajet",
    "media_selector": {
        "title": "S\u00e9lectionner une image",
        "save": "Sauvegarder",
        "upload": "T\u00e9l\u00e9charger"
    },
    "message": "Message",
    "messages": {
        "user_updated": "Utilisateur mis \u00e0 jour",
        "user_created": "Utilisateur cr\u00e9\u00e9"
    },
    "month": "Mois",
    "monthly": "Mensuel",
    "monthly_bill": "Facture mensuelle",
    "more_information_about_the_site": "Plus d'informations sur le site",
    "more_than_500_spaces": "Plus de 500 places",
    "more_than_hours": "Plus de {{hours}} heures",
    "mpark_credit": "Cr\u00e9dit M-Park",
    "mpark_credit_account": "Cr\u00e9dit M-Park",
    "mpark_revenue": "Chiffre d'affaires de M-Park",
    "n_days": "Un jour",
    "n_days_plural": "{{count}}\u00a0jours",
    "n_have_now_been_completed": "\u00ab\u00a0{{n}}\u00a0\u00bb est maintenant termin\u00e9",
    "n_hours": "1 heure",
    "n_hours_plural": "{{count}}\u00a0heures",
    "na": "N\/A",
    "name": "Nom",
    "name_on_the_card": "Nom sur la Carte",
    "net_amount": "Montant net",
    "new_client": "Ajouter un nouveau client",
    "new_fleet": "Ajouter une nouvelle flotte",
    "new_promotion": "Ajouter une promotion",
    "new_site": "Ajouter un nouveau site",
    "new_supplier": "Ajouter un nouveau fournisseur",
    "new_user": "Nouvel utilisateur",
    "new_vat_rate": "Nouveau taux de TVA",
    "new_vehicle": "Ajouter un nouveau v\u00e9hicule",
    "no": "Non",
    "no_data": "Pas de donn\u00e9es",
    "no_new_notification": "Aucune nouvelle notification",
    "no_orders_available": "Aucune commande disponible",
    "no_parking_prices_defined": "Aucun prix de stationnement d\u00e9fini sur ce site",
    "no_payment_method_found": "Aucun moyen de paiement trouv\u00e9",
    "no_report_data": "Aucune donn\u00e9e de rapport",
    "no_sites_found_with_selected_filters": "Aucun site trouv\u00e9 avec les filtres s\u00e9lectionn\u00e9s",
    "none": "Rien",
    "north": "Nord",
    "north_east": "Nord-Est",
    "north_east_short": "NE",
    "north_short": "N",
    "north_west": "Nord Ouest",
    "north_west_short": "NO",
    "not_payouts_found_for_today": "Aucun paiement trouv\u00e9 pour aujourd'hui",
    "notes": "Remarques",
    "notifications": "Avis",
    "notifications_types": {
        "BookingConfirmed": {
            "header": "R\u00e9servation approuv\u00e9e",
            "body": "Votre r\u00e9servation #{{bookingId}} a \u00e9t\u00e9 approuv\u00e9e \u00e0 {{siteName}} pour {{bookingTime}}"
        },
        "BookingRejected": {
            "header": "R\u00e9servation refus\u00e9e",
            "body": "Votre r\u00e9servation #{{bookingId}} a \u00e9t\u00e9 refus\u00e9e \u00e0 {{siteName}}. Veuillez r\u00e9server \u00e0 nouveau ou contactez-nous"
        },
        "BookingCheckedIn": {
            "header": "Enregistr\u00e9",
            "body": "Vous vous \u00eates enregistr\u00e9 \u00e0 {{siteName}} sur votre r\u00e9servation #{{bookingId}} \u00e0 {{bookingTime}}"
        },
        "BookingCheckedOut": {
            "header": "V\u00e9rifi\u00e9",
            "body": "Vous avez quitt\u00e9 {{siteName}} sur votre r\u00e9servation #{{bookingId}} \u00e0 {{bookingTime}}"
        },
        "BookingRequested": {
            "header": "Nouvelle r\u00e9servation",
            "body": "Une nouvelle r\u00e9servation #{{bookingId}} a \u00e9t\u00e9 effectu\u00e9e \u00e0 {{siteName}} le pour {{bookingTime}}"
        }
    },
    "notify": {
        "None": "Non",
        "Inherit": "Faire d\u00e9faut",
        "Instant": "Imm\u00e9diatement",
        "Daily": "Une fois par jour",
        "Weekly": "Une fois par semaine"
    },
    "notify_border_report": "Alertes d\u2019ach\u00e8vement des rapports sur les postes frontaliers",
    "notify_crime_report": "Alertes d\u2019ach\u00e8vement des rapports de criminalit\u00e9",
    "notify_daily_report": "Alertes d\u2019ach\u00e8vement quotidiennes des v\u00e9rifications de v\u00e9hicules",
    "notify_default": "Valeur par d\u00e9faut pour les notifications",
    "notify_fault_daily_report": "Alertes de probl\u00e8mes mineurs de v\u00e9hicules",
    "notify_loading_report": "Alertes d\u2019ach\u00e8vement du rapport de chargement du v\u00e9hicule",
    "notify_new_orders": "Nouvelles commandes",
    "notify_orders_status": "Mises \u00e0 jour sur l'\u00e9tat de la commande",
    "notify_severe_daily_report": "Alertes de probl\u00e8mes graves de v\u00e9hicules",
    "notify_traffic_incident_report": "Alertes d\u2019ach\u00e8vement des rapports d\u2019accident de la route",
    "now": "\u00c0 pr\u00e9sent",
    "number_of_bookings": "Nombre de r\u00e9servations",
    "number_of_drivers": "Nombre de chauffeurs",
    "number_of_licences": "Nombre de licences",
    "number_of_vehicles": "Nombre de v\u00e9hicules",
    "of_preposition": "{{item1}} de {{item2}}",
    "okay": "D'ACCORD",
    "on_account": "Sur compte",
    "on_card": "Sur carte",
    "only_show_sales": "Afficher uniquement les sites avec des promotions\u00a0?",
    "open_24_hours": "Ouvert 24 heures",
    "options": "Choix",
    "order_approved": "Commande approuv\u00e9e",
    "order_canceled": "Commande annul\u00e9e\u00a0!",
    "order_declined": "Commande refus\u00e9e",
    "order_has_been_updated": "La commande a \u00e9t\u00e9 mise \u00e0 jour",
    "order_id": "num\u00e9ro de commande",
    "order_item_id": "ID de l\u2019article",
    "order_items": "Items command\u00e9s",
    "order_received": "Ordre re\u00e7u",
    "order_status": {
        "pending": "En attente",
        "paid": "Pay\u00e9",
        "approved": "A approuv\u00e9",
        "completed": "Termin\u00e9",
        "rejected": "Rejet\u00e9",
        "started": "A d\u00e9but\u00e9",
        "canceled": "Annul\u00e9",
        "cancelled": "Annul\u00e9"
    },
    "order_success_waiting_approval": "Veuillez attendre que {{siteName}} approuve votre commande. N'h\u00e9sitez pas \u00e0 quitter cette page et nous vous informerons lorsque nous aurons une mise \u00e0 jour.",
    "order_summary": "R\u00e9capitulatif de la commande",
    "order_summary_and_checkout": "R\u00e9capitulatif de la commande et paiement",
    "order_updated": "Commande mise \u00e0 jour",
    "orders": {
        "recent_orders": "Derni\u00e8res commandes",
        "view_all_orders": "Afficher toutes les commandes",
        "order_reference": "R\u00e9f\u00e9rence de l'achat",
        "last_ten_orders": "10 derni\u00e8res commandes"
    },
    "over_cap": "Surcapuchon",
    "paid": "Pay\u00e9",
    "parking_available": "Stationnement disponible ?",
    "parking_available_described": "Stationnement disponible (O\/N)",
    "parking_booking_question": "Avez-vous besoin de stationnement?",
    "parking_hourly_price": "Tarif horaire du stationnement",
    "parking_price": "Prix ??du stationnement",
    "parking_price_configuration_note": "Remarque\u00a0: le prix le plus bas jusqu'au prix compris dans la fourchette est utilis\u00e9 et la p\u00e9riode factur\u00e9e est arrondie \u00e0 la valeur \"par p\u00e9riode\" la plus proche.",
    "parking_prices": "Tarifs de stationnement (hors TVA)",
    "parking_prices_vat": "Prix du stationnement (TVA incluse)",
    "parking_site_name": "Nom du site de stationnement",
    "parking_site_website": "Site Web du stationnement",
    "parking_specific": "Sp\u00e9cifique au stationnement",
    "parking_with_trailer": "Avez-vous besoin d'espace pour une remorque?",
    "password": "Mot de passe",
    "password_is_reset": "Votre mot de passe est r\u00e9initialis\u00e9",
    "payment": "Paiement",
    "payment_date": "Date de paiement",
    "payment_details": "D\u00e9tails de paiement",
    "payment_method": "Mode de paiement",
    "payment_methods": "m\u00e9thodes de payement",
    "payment_terms_days": "Jours de paiement client",
    "payments_imported": "Paiements import\u00e9s",
    "payout": "Paiement",
    "payout_saved": "Paiement enregistr\u00e9\u00a0!",
    "payouts_imported": "Paiements import\u00e9s",
    "percentage": "Pourcentage",
    "period_per": "P\u00e9riode (par)",
    "period_value": "Valeur de la p\u00e9riode",
    "person_n": "Personne {{n}}",
    "phone_number": "Num\u00e9ro de t\u00e9l\u00e9phone",
    "place_order": "Passer la commande",
    "please_enter_a_valid_code": "Veuillez entrer un code valide",
    "please_set_up_a_valid_payment_method_for_your_fleet": "Veuillez contacter votre gestionnaire pour ajouter un mode de paiement valide \u00e0 votre flotte",
    "please_specify": "Veuillez pr\u00e9ciser",
    "please_wait": "S'il vous pla\u00eet, attendez...",
    "post_code": "Code postal",
    "postcode_town": "Code postal \/ Ville",
    "prebookable": "Pr\u00e9r\u00e9servable\u00a0?",
    "prebookable_facility": "Pr\u00e9-r\u00e9servation",
    "present_qr_code_for_payment": "Veuillez pr\u00e9senter le code QR \u00e0 la caisse pour le paiement.",
    "price": "Prix",
    "price_per_twelve_hours": "{{price}} {{currency}} par 12\u00a0heures",
    "price_range": "\u00c9chelle des prix",
    "price_range_between": "par 12 heures - {{min}} et {{max}}",
    "primary_contact": "Premier contact",
    "print": "Imprimer",
    "print_report": "Rapport PDF",
    "product": "Produit",
    "product_category": "cat\u00e9gorie de produit",
    "profile": "Profil",
    "promotion_cant_expire_before_it_is_started": "La promotion ne peut pas expirer avant de commencer",
    "promotion_message": "Message promotionnel",
    "promotions_available": "Promotions disponibles",
    "promotions_imported": "Promotions import\u00e9es",
    "provide_email_address_to_sent_reset_request": "Veuillez fournir votre e-mail ci-dessous pour demander le code de r\u00e9initialisation",
    "quantity": "Quantit\u00e9",
    "question_n": "Question {{count}}",
    "rate_base_currency": "Tarif par devise de base ({{baseCurrency}})",
    "rebook": "Nouvelle r\u00e9servation",
    "receive_marketing_emails": "Recevoir des e-mails marketing\u00a0?",
    "record_deleted": "L'enregistrement a \u00e9t\u00e9 archiv\u00e9",
    "reference": "R\u00e9f\u00e9rence",
    "region": "R\u00e9gion",
    "region_country": "R\u00e9gion (Pays)",
    "region_specific": "Sp\u00e9cifique \u00e0 la r\u00e9gion",
    "region_spending_cap_instructions": "si des plafonds de r\u00e9gion sont sp\u00e9cifi\u00e9s, les commandes pour les sites en dehors de la r\u00e9gion sp\u00e9cifi\u00e9e ne seront pas incluses",
    "registered_address": "Adresse enregistr\u00e9e",
    "registration": "Inscription",
    "reject": "Rejeter",
    "remaining": "({{figure}}\u00a0x restant de l'allocation de {{period}})",
    "remove_all_filters": "Supprimer tous les filtres",
    "remove_favourite": "Supprimer comme favori",
    "remove_person": "Supprimer une personne",
    "renew_date": "Date de renouvellement",
    "repeat_password": "R\u00e9p\u00e9ter le mot de passe",
    "reported_by": "Rapport\u00e9 par",
    "request_order": "Demande de r\u00e9servation",
    "reset": "R\u00e9initialiser",
    "reset_link_sent": "Le lien de r\u00e9initialisation du mot de passe est envoy\u00e9",
    "reset_parking_pricing_for_this_site_client": "R\u00e9initialiser les tarifs de stationnement pour ce site et ce client",
    "reset_password": "r\u00e9initialiser le mot de passe",
    "reset_to_default_pricing": "R\u00e9initialiser la tarification par d\u00e9faut",
    "role": "R\u00f4le",
    "roles": {
        "platform_admin": "Plate-forme mondiale",
        "platform_finance": "Financement de la plateforme",
        "supplier_admin": "Responsable Fournisseur",
        "site_manager": "Gestionnaire de site",
        "fleet_manager": "Gestionnaire de flotte",
        "operator": "Op\u00e9rateur de site",
        "client_admin": "Gestionnaire de client\u00e8le",
        "driver": "Conducteur",
        "client_operator": "Op\u00e9rateur client",
        "supplier_operator": "Fournisseur Op\u00e9rateur",
        "platform_operator": "Op\u00e9rateur de plateforme"
    },
    "save": "Sauvegarder",
    "save_client_pricing": "\u00c9conomisez le prix du client",
    "save_new_client": "Enregistrer le client",
    "save_new_fleet": "Enregistrer la flotte",
    "save_new_promotion": "Enregistrer la promotion",
    "save_new_site": "Enregistrer le site",
    "save_new_supplier": "Enregistrer le fournisseur",
    "save_new_vehicle": "Enregistrer le v\u00e9hicule",
    "save_payment_method": "Enregistrer le mode de paiement",
    "save_payout": "Enregistrer le paiement",
    "search": "Recherche",
    "search_for_anything": "Rechercher n'importe quoi",
    "search_placeholder": "Recherche...",
    "second_line_address": "Adresse de 2e ligne",
    "security_facilities": "Installations de s\u00e9curit\u00e9",
    "security_rating": "Cote de s\u00e9curit\u00e9",
    "security_rating_described": "Cote de s\u00e9curit\u00e9 TAPA (1-5)",
    "select_vehicle": "S\u00e9lectionnez votre v\u00e9hicule pour commencer...",
    "send_email": "Envoyer un e-mail",
    "service": "Un service",
    "share_app_on_social_media": "Partagez l'application avec vos coll\u00e8gues amis sur les r\u00e9seaux sociaux",
    "share_location": "Emplacement partag\u00e9?",
    "show_all": "Afficher tout",
    "show_only": "Afficher uniquement {{subject}}",
    "show_order_details": "Afficher les d\u00e9tails de la commande",
    "show_qr_code": "Afficher le code QR",
    "sign_up": "S'inscrire",
    "site": "Site",
    "site_created": "Site cr\u00e9\u00e9",
    "site_currency": "Devise du site",
    "site_description": "Description du site",
    "site_feedback": "Commentaires sur le site",
    "site_feedback_sent": "Commentaires sur le site envoy\u00e9s\u00a0!",
    "site_fees": "Frais de site",
    "site_id": "ID du site",
    "site_name": "Nom du site",
    "site_pricing": "Tarification du site",
    "site_size": "Taille du site",
    "site_specific": "Sp\u00e9cifique au site",
    "site_spending_cap_instructions": "Sur les sites sp\u00e9cifi\u00e9s seront limit\u00e9s",
    "site_updated": "Site mis \u00e0 jour",
    "sites": "Sites",
    "sites_found_with_current_filters": "{{sites}}\u00a0sites trouv\u00e9s \u00e0 l'aide du filtre actuel",
    "sites_imported": "Sites import\u00e9s",
    "south": "Sud",
    "south_east": "Sud-Est",
    "south_east_short": "SE",
    "south_short": "S",
    "south_west": "Sud Ouest",
    "south_west_short": "SW",
    "spaces_available": "Nombre total d'espaces disponibles",
    "spaces_available_display": "{{spaces}} Espaces disponibles",
    "spending_cap": "Plafond des d\u00e9penses",
    "spending_caps": "Plafonds de d\u00e9penses",
    "spending_caps_addon_not_defined_warning": "*Les addons non d\u00e9finis sur ce plafond de d\u00e9penses ne seront pas pris en charge par l'entreprise",
    "start_date": "Date de d\u00e9but",
    "state_code": "Code de l\u2019\u00c9tat",
    "state_code_placeholder": "ISO-3166-2 c\u2019est-\u00e0-dire GB-ENG",
    "status": "Statut",
    "steps": "({{current}} sur {{steps}})",
    "stopped": "Arr\u00eat\u00e9",
    "submit": "Soumettre",
    "subtotal": "Sous-total",
    "success_dialog": {
        "success": "Succ\u00e8s!",
        "error": "Erreur!",
        "fleet_setting_updated": "Les param\u00e8tres de la flotte ont \u00e9t\u00e9 mis \u00e0 jour",
        "driver_setting_updated": "Les param\u00e8tres ont \u00e9t\u00e9 mis \u00e0 jour",
        "site_suggestion_sent": "Suggestion de site envoy\u00e9e",
        "client_created": "Client cr\u00e9\u00e9\u00a0!",
        "client_updated": "Client mis \u00e0 jour\u00a0!",
        "fleet_created": "Flotte cr\u00e9\u00e9e\u00a0!",
        "fleet_updated": "Flotte mise \u00e0 jour\u00a0!",
        "vehicle_created": "V\u00e9hicule cr\u00e9\u00e9\u00a0!",
        "vehicle_updated": "V\u00e9hicule mis \u00e0 jour\u00a0!",
        "supplier_created": "Fournisseur cr\u00e9\u00e9\u00a0!",
        "supplier_updated": "Fournisseur mis \u00e0 jour\u00a0!",
        "promotion_created": "Promotion cr\u00e9\u00e9e\u00a0!",
        "promotion_updated": "Promotion mise \u00e0 jour\u00a0!"
    },
    "suggest_a_parking_site": "Sugg\u00e9rer un emplacement de stationnement",
    "summary": "R\u00e9sum\u00e9",
    "supplier": "Fournisseur",
    "supplier_payment_terms": "Conditions de paiement du fournisseur",
    "supplier_payment_terms_days": "D\u00e9lais de paiement fournisseur jours",
    "supplier_site": "Fournisseur | Site",
    "suppliers_imported": "Fournisseurs import\u00e9s",
    "switch_to_list_view": "Basculer vers la vue liste",
    "switch_to_map_view": "Basculer vers la vue Carte",
    "take_a_photo": "Prendre une photo",
    "tapa_rating": "Classement TAPA",
    "tapa_rating_display": "{{rating}} Classement TAPA",
    "terms_condition": "Termes et conditions",
    "this_order_has_been_completed_on": "Cette commande a \u00e9t\u00e9 compl\u00e9t\u00e9e le {{time}}",
    "this_order_has_been_rejected": "Cette commande a \u00e9t\u00e9 rejet\u00e9e",
    "this_order_is_not_approved_yet": "Cette commande n'est pas encore approuv\u00e9e",
    "time": "Heure",
    "title": "Titre",
    "to": "Pour",
    "total": "Total",
    "total_addons": "Nombre total d'ajouts",
    "total_inc_vat": "Total (TVA incluse)",
    "total_parking": "Stationnement total",
    "total_revenue": "Revenu total",
    "total_spaces": "Espaces totaux",
    "total_to_pay_on_card": "Total \u00e0 payer sur carte",
    "town_city": "Ville\/Ville",
    "trailer": "Bande annonce",
    "transactions": "Transactions",
    "truck_park": "Parc \u00e0 camions",
    "twelve_hours": "12 heures",
    "type": "Taper",
    "type_of_user": "Type d'utilisateur",
    "uncertified": "Non certifi\u00e9",
    "unit_price_gbp": "Prix unitaire (GBP)",
    "unlimited": "Illimit\u00e9",
    "unpaid": "Non pay\u00e9",
    "up_to_500_spaces": "Jusqu'\u00e0 500 places",
    "up_to_hours": "Jusqu'\u00e0 {{hours}} heures",
    "up_to_one_hour": "Jusqu'\u00e0 1 heure",
    "update": "Mise \u00e0 jour",
    "update_client": "Mettre \u00e0 jour le client",
    "update_exchange_rates": "Mettre \u00e0 jour les taux de change",
    "update_fleet": "Mettre \u00e0 jour la flotte",
    "update_fleet_settings": "Mettre \u00e0 jour les param\u00e8tres de la flotte",
    "update_promotion": "Mettre \u00e0 jour la promotion",
    "update_settings": "Mettre \u00e0 jour les param\u00e8tres",
    "update_site": "Mettre \u00e0 jour le site",
    "update_supplier": "Mettre \u00e0 jour le fournisseur",
    "update_user": "Mettre \u00e0 jour l'utilisateur",
    "update_vehicle": "Mettre \u00e0 jour le v\u00e9hicule",
    "upgrade": "Mise \u00e0 niveau",
    "upload": "T\u00e9l\u00e9charger",
    "upload_a_photo": "T\u00e9l\u00e9charger une photo",
    "upload_payments": "T\u00e9l\u00e9charger les paiements",
    "upto": "jusqu'\u00e0",
    "upto_period": "Jusqu'\u00e0 la p\u00e9riode",
    "upto_value": "Jusqu'\u00e0 la valeur",
    "use_current_location": "Utiliser l\u2019emplacement actuel",
    "use_fleet_defaults": "Utiliser les param\u00e8tres par d\u00e9faut de la flotte",
    "use_standard_price": "Utiliser le prix standard",
    "used": "Utilis\u00e9?",
    "user": "Utilisateur",
    "username": "Nom d'utilisateur",
    "users_imported": "Utilisateurs import\u00e9s",
    "valid_fleet_name_that_is_created_for_this_driver_supplier": "Nom de flotte valide li\u00e9 au compte client du conducteur",
    "vat": "T.V.A.",
    "vat_rate": "Taux de TVA",
    "vat_rates": "Taux de TVA",
    "vat_registration_number": "Num\u00e9ro d'immatriculation \u00e0 la TVA",
    "vehicle": "V\u00e9hicule",
    "vehicle_registration_nr": "N\u00b0 d'enregistrement",
    "vehicle_registration_number": "N\u00b0 d'enregistrement",
    "vehicle_type": "Type de v\u00e9hicule",
    "vehicle_types": {
        "Forklift": "Chariot \u00e9l\u00e9vateur",
        "Van": "Van",
        "Truck": "Rigide",
        "TractorUnit": "Arctique"
    },
    "vehicles": "V\u00e9hicules",
    "vehicles_imported": "V\u00e9hicules import\u00e9s",
    "view_all_bookings": "Afficher toutes les r\u00e9servations",
    "view_all_sites": "Afficher tous les sites",
    "view_all_users": "Afficher tous les utilisateurs",
    "view_details": "Voir les d\u00e9tails",
    "view_order": "Voir l'ordre",
    "waiting_approval_title": "En attente d'approbation du site....",
    "we_have_sent_a_security_code": "Nous avons envoy\u00e9 un code de s\u00e9curit\u00e9 \u00e0 {{email}}",
    "weekly": "Hebdomadaire",
    "west": "Ouest",
    "west_short": "O",
    "year": "An",
    "yearly": "Annuel",
    "yes": "Oui",
    "you_have_been_checked_in": "Vous avez \u00e9t\u00e9 enregistr\u00e9",
    "you_have_been_checked_out": "Vous avez \u00e9t\u00e9 v\u00e9rifi\u00e9",
    "you_have_checked_in": "Vous vous \u00eates enregistr\u00e9 \u00e0 {{siteName}} pour votre commande n\u00b0\u00a0{{orderId}} \u00e0 {{bookingTime}}",
    "you_have_checked_out": "Vous avez quitt\u00e9 {{siteName}} sur votre commande #{{orderId}} \u00e0 {{bookingTime}}",
    "you_have_not_selected_any_site_as_favourite": "Vous n'avez s\u00e9lectionn\u00e9 aucun site comme favori",
    "your_account_is_current_on_stop": "Votre compte est actuellement en arr\u00eat. Veuillez contacter votre gestionnaire.",
    "your_booking_has_been_extended": "La r\u00e9servation a \u00e9t\u00e9 prolong\u00e9e jusqu'au {{expireDate}}",
    "your_booking_is_ready": "Votre r\u00e9servation est pr\u00eate",
    "your_order_has_been_approved": "Votre commande n\u00b0\u00a0{{orderId}} a \u00e9t\u00e9 approuv\u00e9e sur {{siteName}} pour {{bookingTime}}",
    "your_order_has_been_declined": "Votre commande n\u00b0\u00a0{{orderId}} a \u00e9t\u00e9 refus\u00e9e sur {{siteName}}. Veuillez r\u00e9server \u00e0 nouveau ou contactez-nous",
    "your_order_is_successful_show_qr_code": "Votre r\u00e9servation a \u00e9t\u00e9 approuv\u00e9e, veuillez afficher le code QR sur {{siteName}} pour traitement",
    "your_parking_period_expires_on": "Votre p\u00e9riode de stationnement payant expire le {{expireDate}}"
}
