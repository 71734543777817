import {Menu, MenuItem, ProSidebar, SidebarContent} from 'react-pro-sidebar';
import routes                                                                      from "@/router/router";
import React, { useEffect }                                                          from "react";
import {Link, useLocation}                                                         from "react-router-dom";
import {useTranslation}                                                            from "react-i18next";
import {useAuth}                                                                   from "@/services/Auth";
import Dashboard                                                                   from "@/views/pages/dashboard/Dashboard";
import {Button, Card}                                                              from "react-bootstrap";
import { ClientsIcon, LogoutIcon, NotificationsIcon }                                                                 from "@/components/icons";
import { useLayout }                                                               from "@/layouts/Layout";


function Aside() {
    const location = useLocation();
    const {t, i18n} = useTranslation();
    let auth = useAuth();
    const layout = useLayout();

    const logOut = () => {
        auth.signOut().then(response => {
            window.location.reload();
        });
    };

    const menuRoutes = routes.filter(route => (auth.roles != null && (!route.gate || auth.roles[route.gate]) && route.main) || route.title === 'Dashboard');
    return (
        <ProSidebar
            toggled={layout.toggled}
            breakPoint="md"
            className="d-print-none"
            onToggle={layout.setToggled}
        >
            <SidebarContent>

                <div className="d-block d-lg-none px-2 pt-2 px-sm-3 pt-sm-3 text-center text-sm-start mt-2">

                    {
                        (auth !== undefined && auth.user && auth.user.is_driver && auth.user.company) &&
                        <h5 className="text-white mb-0">{auth.user.company.name}</h5>
                    }
                </div>

                <div className="d-block d-sm-none px-1 mt-2">
                    <div
                        className="d-grid gap-2"
                        style={{
                            padding: '12px 12px',
                        }}
                    >
                        <Button className="btn-icon" variant="light" onClick={() => logOut()}>
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="me-2">{t('logout')}</span>
                                <LogoutIcon classDefined="side-logout" size={16}/>
                            </div>
                        </Button>
                    </div>
                </div>

                <div className={`d-sm-none row p-3 pt-0 d-flex grid-menu`}>
                    <div className="col col-6 my-2">
                        <Link to="/notifications" onClick={() => layout.setToggled(false)}>
                            <Card className={`${("/notifications" === location.pathname || ("/notifications" !== '/' && location.pathname.includes("/notifications"))) ? "active-link" : "bg-primary text-white"}  dash-card border-0`}>

                                <Card.Body className="">
                                    <div className="d-flex align-items-center justify-content-center flex-column text-center">
                                        <div className="badges">

                                        {auth.notifications?.length > 0 &&
                                        <span className="badge badge-danger rounded-pill bg-danger">{auth.notifications?.length}</span>}
                                        <NotificationsIcon/>
                                        </div>
                                        <span className="my-2">{t(`notifications`)}</span>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Link>
                    </div>

                    {menuRoutes.map((route, index) => (
                        <div className="col col-6 my-2" key={route.path}>
                            <Link to={route.path} onClick={() => layout.setToggled(false)}>
                                <Card className={`${(route.path === location.pathname || (route.path !== '/' && location.pathname.includes(route.path))) ? "active-link" : "bg-primary text-white"}  dash-card border-0`}>

                                    <Card.Body className="">
                                        <div className="d-flex align-items-center justify-content-center flex-column text-center">
                                            {route.icon}
                                            <span className="my-2">{t(`drawer_link_titles.${route.translate_key}`)}</span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </div>
                    ))}
                </div>

                <div className="d-none  d-sm-block">
                    <Menu iconShape="circle">
                        {menuRoutes.map((route, index) => (
                            <MenuItem key={index} className={`${(route.path === location.pathname || (route.path !== '/' && location.pathname.includes(route.path))) ? "active-link" : "bg-primary text-white"}`} suffix={route.icon} onClick={(e) => {
                                layout.setToggled(false)
                            }}>
                                <span>{t(`drawer_link_titles.${route.translate_key}`)}</span>
                                <Link to={route.path}/>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            </SidebarContent>
        </ProSidebar>
    );
}

function DashboardName({user}) {
    const {t, i18n} = useTranslation();
    const name = () => {
        if (user.is_platform_admin) {
            return t('dashboard.platform_dashboard');
        } else if (user.is_driver) {
            return t('dashboard.driver_dashboard');
        } else if (user.is_client_admin) {
            return t('dashboard.company_dashboard');
        } else if (user.is_fleet_manager) {
            return t('dashboard.fleet_manager_dashboard');
        } else if (user.is_supplier_admin) {
            return t('dashboard.supplier_dashboard');
        } else if (user.is_site_manager) {
            return t('dashboard.site_manager_dashboard');
        } else if (user.is_operator) {
            return t('dashboard.operator_dashboard');
        }
    }
    return (
        <span>
            {name()}
        </span>
    )
}

function IconComponent({name}) {
    return (
        // <i className={`bi ${name} menu-icon`}/>
        <ClientsIcon classDefined=""/>
    )
}

export default Aside;
