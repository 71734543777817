import {useAuth} from "@/services/Auth";
import {useTranslation} from "react-i18next";
import FleetSettings from "./components/FleetSettings";
import DriverSettings from "./components/DriverSettings";
import SiteSettings from "./components/SiteSettings";
import ClientSingle from "../clients/ClientSingle";
import SupplierSingle from "../suppliers/SupplierSingle";
import { Helmet } from "react-helmet";

function Settings() {
    const {t} = useTranslation();
    let auth = useAuth();
    return (
        <div>
            <Helmet>
                <title>{t('drawer_link_titles.settings')} - {t('app')}</title>
            </Helmet>

            {auth.user.is_fleet_manager && <FleetSettings/>}
            {(auth.user.is_driver && !auth.user.is_fleet_manager) && <DriverSettings/>}
            {(auth.user.is_client_admin || auth.user.is_client_operator) && <ClientSingle companyId={auth.user.company_id}/>}
            {(auth.user.is_site_manager || auth.user.is_site_operator) && <SiteSettings />}
            {(auth.user.is_supplier_admin || auth.user.is_supplier_operator) && <SupplierSingle supplierId={auth.user.supplier_id}/>}
        </div>
    );
}

export default Settings;
