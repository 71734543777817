import {Button, Card, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import React from "react";
import {Helmet} from "react-helmet";
import useMoment from "@/hooks/useMoment";

function Confirmation() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const {t} = useTranslation();
    const route = useLocation();
    const history = useHistory();
    const site = route.state.site;
    const vehicle = route.state.vehicle;
    const driver = route.state.driver;
    const canChange = route.state.canChange ?? false;

    const {momentLocal, moment} = useMoment()

    const handleConfirmation = () => {
        history.push('/instant-booking/create', {
            site,
            vehicle,
            driver
        })
    }

    return (
        <Card className="container-fluid">
            <Helmet>
                <title> {t('drawer_link_titles.pay_m_park')} - {t('app')}</title>
            </Helmet>

            <h6 className="text-primary">
                {t('booking.instant_booking')}
            </h6>

            <p className="extra-small mb-0">{site.name}</p>
            {canChange && (
                <div
                    className="align-items-center d-flex mb-2 small text-primary"
                    onClick={() => history.replace('/instant-booking/select-site', {...route.state})}
                >
                    <span className="small me-2">{t('change')}</span>
                    <img src="/images/edit-icon.png" alt=""/>
                </div>
            )}
            <p className="extra-small">
                {momentLocal(moment.now()).format(t('formats.short_datetime_reverse'))}
            </p>
            <p className="extra-small">{driver.name}</p>
            <p className="extra-small">{vehicle.registration_number}</p>


            <div className="mt-4">
                <Button className="w-100" variant="success" onClick={handleConfirmation}>
                    {t('instant_booking.confirm')}
                </Button>
            </div>
            <div className="mt-4">
                <Button className="w-100" variant="danger" onClick={() => history.goBack()}>
                    {t('cancel')}
                </Button>
            </div>

        </Card>
    )
}

export default Confirmation;
