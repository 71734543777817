import {ErrorMessage, Field, Form, Formik} from "formik";
import DatePicker                          from "react-datepicker";
import {Button, Col, Spinner}              from "react-bootstrap";
import React, {useEffect, useState, useRef}        from "react";
import * as Yup                            from "yup";
import {useTranslation}                    from "react-i18next";
import Select                              from "react-select";
import AsyncSelect                         from "react-select/async";
import * as moment                         from "moment";
import {useAuth} from "@/services/Auth";
import {ServiceCategories} from "@/services";
import LoadingPage from "@/components/LoadingPage";

function PromotionForm({createForSite, initialValues, handleSubmit, loading, promotionSite, buttonText, serviceSent}) {
    const {t, i18n} = useTranslation();
    const auth = useAuth();


    const requestStore = useRef({});
    const { current: requests } = requestStore;


    const [site, setSite] = useState('');
    const [service_categories, setServiceCategories] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [invalidDateRangeMessage, setInvalidDateRangeMessage] = useState('');

    const minDate = new Date();

    const validation = Yup.object().shape({
        site_id        : Yup.number()
                            .typeError('')
                            .nullable(),
        message        : Yup.string()
                            .required(t('form_validation.message_is_required')),
        category_id: Yup.number()
                            .typeError('')
                            .nullable(),
        discount       : Yup.number()
                            .required(t('form_validation.discount_is_required')),
        start_at       : Yup.string()
                            .required(t('form_validation.start_date_is_required')),
        expire_at      : Yup.date()
                            .required(t('form_validation.expiry_date_is_required'))
                            .min(minDate, t('form_validation.expiry_date_min'))
                            .nullable(),
        discount_type  : Yup.string()
                            .required(t('form_validation.discount_type_is_required')),
    });


    const updateFromDate = (date) => {
        setToDate('');
        setFromDate(date);
    }

    const handleSubmitLocal = (values) => {
        setInvalidDateRangeMessage('');
        if (validateDates) {
            handleSubmit(values);
        }
    }

    const validateDates = () => {
        if (moment(fromDate) < moment(toDate)) {
            setInvalidDateRangeMessage('promotion_cant_expire_before_it_is_started')
            return false
        }
        return true;
    }

    const formatRecord = (record) => {
        const  object = {...record};

        object.value = record.id;
        object.label = record?.supplier?.company_name  + ' | ' + record?.name;

        return object;
    };

    const getSites = (search) => {
        requests.sites && requests?.sites?.abort && requests.sites.abort();

        return new Promise((resolve, reject) => {
            requests.sites = auth.getRequest('sites', { search, include: 'all' });

            requests.sites.then(response => {
                    resolve(response.data.sites.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    };

    useEffect(() => {
        if(!service_categories)
        {
            ServiceCategories.get().then(_ => setServiceCategories(_));
        }

        if (promotionSite != null) {
            setSite(formatRecord(promotionSite));
        }
        if (initialValues.start_at !== '') {
            setFromDate(new Date(initialValues.start_at));
        }
        if (initialValues.expire_at !== '') {
            setToDate(new Date(initialValues.expire_at));
        }
    }, [])

    useEffect(() => {
        return () => {
            for(var key in requests)
            {
                requests[key] && requests[key].abort && requests[key].abort();
            }
        }
    }, []);

    if(!service_categories)
    {
        return <LoadingPage />;
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSubmitLocal}
        >
            {({values, errors, touched}) => (
                <Form>
                    <div className="form-group mt-4">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-4">
                                <label htmlFor="message" className="my-2">{t('promotion_message')}</label>
                                <Field
                                    type="text"
                                    name="message"
                                    placeholder={t('message')}
                                    className={`form-control ${errors.message && touched.message ? 'is-invalid' : ''}`}
                                />
                                <small>
                                    <ErrorMessage component="div" name="message" className="text-danger  py-1"/>
                                </small>
                            </div>
                            {
                                createForSite == null &&
                                <div className="col-12 col-sm-6 col-md-4">
                                    <label htmlFor="site_id" className="my-2">{t('supplier_site')}</label>

                                    <AsyncSelect
                                        className="react-select"
                                        isClearable={true}
                                        value={site}
                                        onChange={(e) => {
                                            setSite(e)
                                            values.site_id = e ? e.value : '';
                                        }}
                                        placeholder={t('supplier_site')}
                                        defaultOptions={true}
                                        loadOptions={getSites} />
                                    <small>
                                        <ErrorMessage component="div" name="site_id" className="text-danger  py-1"/>
                                    </small>
                                </div>
                            }
                            <div className="col-12 col-sm-6 col-md-4">
                                <label htmlFor="category_id" className="my-2">{t('product_category')}</label>

                                <Field
                                    type="number"
                                    as="select"
                                    name="category_id"
                                    className={`form-control ${errors.category_id && touched.category_id ? 'is-invalid' : ''}`}
                                >
                                    <option value="">{t('choose')}</option>
                                    {
                                        service_categories?.map && service_categories.map(product => product.addon && <option key={product.id} value={product.id}>{product.name}</option>) || []
                                    }
                                </Field>
                                <small>
                                    <ErrorMessage component="div" name="category_id" className="text-danger  py-1"/>
                                </small>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <label htmlFor="start_at" className="my-2">{t('start_date')}</label>

                                <input
                                    placeholder={t('date')}
                                    className="form-control"
                                    type="datetime-local"
                                    name="start_at"
                                    value={values.start_at}
                                    onChange={(e) => {
                                        values.start_at = e.target.value;
                                        updateFromDate(e.target.value)
                                    }}
                                />
                                <small>
                                    <ErrorMessage component="div" name="start_at" className="text-danger  py-1"/>
                                </small>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <label htmlFor="expire_at" className="my-2">{t('expiry_date')}</label>

                                <input
                                    placeholder={t('date')}
                                    className="form-control"
                                    type="datetime-local"
                                    name="expire_at"
                                    value={values.expire_at}
                                    min={moment(fromDate).format('YYYY-MM-DDThh:mm')}
                                    onChange={(e) => {
                                        values.expire_at = e.target.value;
                                        setToDate(e.target.value)
                                    }}
                                />
                                <small>
                                    <ErrorMessage component="div" name="expire_at" className="text-danger  py-1"/>
                                </small>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <label htmlFor="discount" className="my-2">{t('discount_value')}</label>
                                <Field
                                    type="number"
                                    name="discount"
                                    placeholder={t('discount_value')}
                                    className={`form-control ${errors.discount && touched.discount ? 'is-invalid' : ''}`}
                                />
                                <small>
                                    <ErrorMessage component="div" name="discount" className="text-danger  py-1"/>
                                </small>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <label htmlFor="discount" className="my-2">{t('discount_type')}</label>
                                <Field
                                    as="select"
                                    type="text"
                                    name="discount_type"
                                    placeholder={t('discount_type')}
                                    className={`form-control ${errors.discount_type && touched.discount_type ? 'is-invalid' : ''}`}
                                >
                                    <option value="">--{t('choose')}--</option>
                                    <option value="Percentage">{t('percentage')}</option>
                                    <option value="Amount">{t('amount')}</option>
                                </Field>
                                <small>
                                    <ErrorMessage component="div" name="discount_type" className="text-danger  py-1"/>
                                </small>
                            </div>
                        </div>
                    </div>
                    {
                        invalidDateRangeMessage !== '' &&
                        <div className="d-grid gap-2 mt-4">
                            <p className="text-danger">{t(invalidDateRangeMessage)}</p>
                        </div>
                    }
                    <div className="d-grid gap-2 mt-4">
                        {!loading ?
                         <Button type="submit" variant="primary">{t(buttonText)}</Button>
                                  :
                         <Button variant="primary" disabled>
                             <Spinner
                                 as="span"
                                 animation="border"
                                 size="sm"
                                 role="status"
                                 aria-hidden="true"
                             />
                             <span className="mx-2">{t('please_wait')}</span>
                         </Button>
                        }
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default PromotionForm;
