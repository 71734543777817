export default {
    "app": "M-Park App",

    "errors": {
        "error_401_message": "401 | UNAUTHORIZED REQUEST",
        "error_404_message": "404 | PAGE NOT FOUND",
        "error_0_message": "No internet",
        "page_error": "PAGE ERROR!",
        "sorry_an_error_has_occurred_to_this_page": "Sorry, this page could not be loaded",
        "you_are_not_authorized_to_access_the_page_you_requested": "Sorry, you are not authorized to access the page you requested",
        "page_not_available": "Sorry, the page you are looking for does not exist, have been removed, name changed or is temporary unavailable",
        "map_error": "Sorry there has been an error with the map, please try refreshing the page"
    },
    "profile": "Profile",
    "logout": "Log Out",
    "drawer_link_titles": {
        "dashboard": "Dashboard",
        "users": "Users",
        "vehicles": "Vehicles",
        "favourite_sites": "Favourite Sites",
        "settings": "Settings",
        "need_help": "Need Help?",
        "clients": "Clients",
        "fleets": "Fleets",
        "suppliers": "Suppliers",
        "sites": "Sites",
        "promotion": "Promotion",
        "promotions": "Promotions",
        "bookings": "Bookings",
        "pay_m_park": "Pay with M-Park",
        "orders": "Orders",
        "reports": "Reports",
        "find_site": "Find a Site",
        "scan_qr_code": "Scan QR Code",
        "my_history": "My History",
        "compliance": "Compliance",
        "marketing": "Marketing",
        "pay_via_m_park": "Pay Via M-Park",
    },
    "find_site": "Find Site",
    "form_validation": {
        "is_required": "{{attribute}} is required",
        "min": "{{attribute}} must be greater or equal to {{value}}",
        "max_length": "The {{attribute}} must not be greater than {{max}}{{length}} characters.",
        "invalid_email_address": "Please use valid email address",
        "password_invalid_min_length": "Password must not be less than {{length}} characters",
        "code_invalid_min_length": "Code must not be less than {{length}} numbers",
        "code_invalid_max_length": "Code must not be more than {{length}} numbers",
        "password_is_required": "Password is required",
        "code_expired": "The security code has expired.",
        "code_is_required": "Code is required",
        "password_is_must_match": "Password must match",
        "password_confirm_required": "Password confirm required",
        "email_address_is_required": "Email address is required",
        "name_is_required": "Name is required",
        "username_is_required": "Username is required",
        "user_name_is_required": "Username is required",
        "phone_number_is_required": "Phone Number is required",
        "user_type_is_required": "User type is required",
        "site_is_required": "Site is required",
        "message_is_required": "Message is required",
        "product_is_required": "Product is required",
        "discount_is_required": "Discount is required",
        "start_date_is_required": "Start date is required",
        "expiry_date_is_required": "Expiry date is required",
        "expiry_date_min": "The expiry date cannot be less than the current date",
        "call_to_action_required": "Call to action is required",
        "link_to_action_required": "Link is required",
        "site_name_is_required": "Site name is required",
        "description_is_required": "Description is required",
        "total_spaces_available_is_required": "Total spaces available is required",
        "site_currency_is_required": "Site currency is required",
        "supplier_is_required": "Supplier is required",
        "longitude_is_required": "Longitude is required",
        "latitude_is_required": "Latitude is required",
        "address_is_required": "1st line Address is required",
        "address_line_2_is_required": "2nd line Address is required",
        "city_town_is_required": "City/Town is required",
        "country_is_required": "Country is required",
        "county_is_required": "County is required",
        "post_code_is_required": "Post Code is required",
        "parking_price_is_required": "Parking Price is required",
        "parking_price_period_value_is_required": "Period value is required",
        "parking_price_per_period_type_is_required": "Per Period value is required",
        "parking_price_up_to_value_is_required": "Up to value is required",
        "parking_price_up_to_period_is_required": "Up to period is required",
        "category_is_required": "Category is required",
        "price_is_required": "Price is required",
        "commission_rate_type_is_required": "Mark Up rate type is required",
        "commission_value_is_required": "Mark Up rate is required",
        "vehicle_registration_number_is_required": "Vehicle Registration Number is required",
        "primary_contact_name_is_required": "Contact name is required",
        "contact_name_is_required": "Contact name is required",
        "account_status_is_required": "Account status is required",
        "account_number_is_required": "Account number is required",
        "currency_is_required": "Currency is required",
        "account_type_is_required": "Account type is required",
        "vat_registration_number_required": "VAT registration number is required",
        "client_credit_limit_required": "Credit Limit is required",
        "client_payment_terms_required": "Payment Term is required",
        "client_payment_terms_days_required": "Payment Term days required",
        "expire_date_is_required": "Expire date is required",
        "security_code_is_required": "Security Code is required",
        "card_number_is_required": "Card Number is required",
        "invalid_user_email": "No user found with that email",
        "invalid_user": "No user found with the provided details",
        "account_number_required": "Account number is required",
        "order_id_is_required": "Order ID is required",
        "payment_date_is_required": "Payment date is required",
        "amount_is_required": "Amount is required",
        "ccv_is_required": "CCV is required",
        "discount_type_is_required": "Discount type is required",
        "company_is_required": "Client is required",
        "parking_limit_reached": "This will exceed your parking cap",
        "credit_limit_reached": "This will exceed the account credit limit",
        "invalid_card_number": "Card Number is invalid",
        "invalid_expire": "Card has expired",
        "invalid_ccv": "CCV is invalid",
        "image_required": "Please upload an image",
        "invalid_qr_code": "Please scan a valid M-Park QR code",
        "addons_required_without_parking": "As you are not parking you must select at least one add-on",
        "instant_prebook_required": "Please select anyone either pre-bookable or instant booking",
        "booking": {
            "invalid_instant_order_token": "Your session has expired please rescan the QR code",
            "invalid_location": "You must be on site to scan the QR code"
        },
        "not_enough_licences": "You need enough compliance licenses for all drivers in this fleet",
    },
    "dashboard": {
        "dashboard": "Dashboard",
        "total_m_park_fee": "Total M-Park Fee",
        "parking_occurrences": "Parking Occurrences",
        "non_parking_occurrences": "Non-Parking Occurrences",
        "active_sites": "Active Sites",
        "active_users": "Active Users",
        "active_accounts": "Active Accounts",
        "total_site_revenue": "Total Site Revenue",
        "total_revenue": "Total Revenue",
        "total_non_parking_revenue": "Total Non Parking Revenue",
        "average_order_value": "Average Order Value",
        "platform_dashboard": "Platform Dashboard",
        "driver_dashboard": "Driver Dashboard",
        "company_dashboard": "Client Dashboard",
        "fleet_manager_dashboard": "Fleet Manager Dashboard",
        "supplier_dashboard": "Supplier Dashboard",
        "site_manager_dashboard": "Site Manager Dashboard",
        "operator_dashboard": "Operator Dashboard",
        "supplier_operator_dashboard": "Supplier Operator Dashboard",
        "client_operator_dashboard": "Client Operator Dashboard",
        "platform_operator_dashboard": "Platform Operator Dashboard",
        "platform_finance_dashboard": "Platform Finance Dashboard",
        "driver": {
            "upcoming_bookings": "Upcoming Bookings",
            "new_booking": "New Booking",
            "view_map": "View Map",
            "pay_m_park": "Pay with M-Park",
            "nearest_sites": "Nearest Sites",
            "pay_on_arrival": "Pay on arrival",
            "prebook": "Pre Book",
            "prebook_only": "Pre Book Only",
            "search_site": "Search for a site"
        }
    },
    "roles": {
        "platform_admin": "Platform Global",
        "platform_finance": "Platform Finance",
        "supplier_admin": "Supplier Manager",
        "site_manager": "Site Manager",
        "fleet_manager": "Fleet Manager",
        "operator": "Site Operator",
        "client_admin": "Client Manager",
        "driver": "Driver",
        "client_operator": "Client Operator",
        "supplier_operator": "Supplier Operator",
        "platform_operator": "Platform Operator"
    },
    "home": "Home",
    "driver_settings": "Driver Settings",
    "update_fleet_settings": "Update Fleet Settings",
    "go_to_dashboard": "Go to Dashboard",
    "no_payment_method_found": "No Payment Method Found",
    "please_set_up_a_valid_payment_method_for_your_fleet": "Please contact your manager to add a valid payment method to your fleet",
    "got_to_settings": "Go to Settings",
    "please_wait": "Please Wait...",
    "field": "Field",
    "login": "Log In",
    "active": "Active",
    "edit": "Edit",
    "stopped": "Stopped",
    "continue": "Continue",
    "complete": "Complete",
    "email_address": "Email address",
    "enter_email_address": "Enter email",
    "either_or": "{{attribute1}} or {{attribute2}}",
    "password": "Password",
    "repeat_password": "Repeat Password",
    "sign_up": "Sign Up",
    "forgot_password": "Forgot your password?",
    "name": "Name",
    "username": "Username",
    "enter_name": "Enter Name",
    "enter_username": "Enter Username",
    "phone_number": "Phone Number",
    "phone_number_intl": "Phone Number (incl. country code with '+')",
    "enter_phone_number": "Enter Phone Number",
    "vehicle_registration_number": "Registration Nr.",
    "vehicle_registration_nr": "Registration Nr.",
    "vehicle_type": "Vehicle Type",
    "choose_type": "Choose Type",
    "create_account": "Create Account",
    "reset_link_sent": "Password reset link is sent",
    "check_email": "Please please check your email",
    "provide_email_address_to_sent_reset_request": "Please provide your email or phone number below to request reset code",
    "client_supplier": "Client / Supplier",
    "send_email": "Send Email or SMS",
    "password_is_reset": "Your password is reset",
    "continue_sign_in": "Please continue to sign in with your new password",
    "create_password_header": "Create new Password below",
    "confirm_your_email": "Confirm Your email address",
    "reset_password": "Reset Password",
    "new_user": "New User",
    "create_user": "Save User",
    "update_user": "Update User",
    "update_client": "Update Client",
    "update_fleet": "Update Fleet",
    "update_vehicle": "Update Vehicle",
    "update_site": "Update Site",
    "update_settings": "Update Settings",
    "update_supplier": "Update Supplier",
    "update_promotion": "Update promotion",
    "choose_driver": "Choose Driver",
    "choose_client": "Choose Client",
    "add_drivers": "Add Drivers",
    "add_driver": "New Driver",
    "add_parking_price": "Add Parking Price",
    "add_promotion": "Add Promotion",
    "find_user": "Find User",
    "import_users": "Import Users",
    "import": "Import",
    "download_reports": "Download Report",
    "filters_labels": {
        "filters": "Filters",
        "show_advance_filters": "Show Advanced Filters",
        "hide_advance_filters": "Hide Advanced Filters",
        "filter_by_creation_date": "Filter by account creation date",
        "filter_by_country": "Filter by Country Name",
        "filter_by_region": "Filter by Region",
        "filter_by_account_status": "Filter by Account Status",
        "search_for_email_address": "Search for Email Address",
        "filter_by_company_name": "Filter by company name",
        "filter_by_site": "Filter by site",
        "filter_by_user": "Filter by user",
        "filter_by_client": "Filter by client",
        "filter_by_suppliers": "Filter by Supplier",
        "filter_by_fleet": "Filter by Fleet",
        "filter_by_product": "Filter by Product",
        "start_date": "Start Date",
        "end_date": "End Date",
        "filter_by_primary_contact_name": "Filter by Primary Contact Name",
        "credit_status": "Credit Status",
        "between_these_dates": "Between These Dates",
        "company_name": "Company Name",
        "show_promotion": "Show Promotion?",
        "active_date": "Active Date",
        "expire_date": "Expire Date",
        "order_date": "Order Date",
        "due_date": "Due Date",
        "balance": "Balance",
        "figures": "Figures",
        "any": "Any",
        "no_min": "No Min",
        "no_max": "No Max",
        "show_filters": "Show Filters",
        "apply": "Apply Filters"
    },
    "month": "Month",
    "year": "Year",
    "country": "Country",
    "country_code": "Country Code",
    "county": "County",
    "billing_county": "County",
    "region": "Region",
    "site": "Site",
    "sites": "Sites",
    "search": "Search",
    "search_placeholder": "Search...",
    "account_status": "Account Status",
    "account_type": "Account Type",
    "user": "User",
    "type_of_user": "Type of User",
    "manager": "Manager",
    "account_number": "Account Number",
    "driver": "Driver",
    "date": "Date",
    "time": "Time",
    "amount": "Amount",
    "service": "Service",
    "entity": "Entity",
    "role": "Role",
    "okay": "OK",
    "search_for_anything": "Search for anything",
    "person_n": "Person {{n}}",
    "add_person": "Add Person",
    "remove_person": "Remove Person",
    "orders": {
        "recent_orders": "Recent Orders",
        "view_all_orders": "View All Orders",
        "order_reference": "Order Reference",
        "last_ten_orders": "Last 10 Orders"
    },
    "order_status": {
        "pending": "Pending",
        "paid": "Paid",
        "approved": "Approved",
        "completed": "Completed",
        "rejected": "Rejected",
        "started": "Started",
        "canceled": "Cancelled",
        "cancelled": "Cancelled"
    },
    "conform_dialog": {
        "are_you_sure": "Are you Sure?",
        "order_confirm": {
            "message": "Approve Order?",
            "cancel": "Cancel",
            "accept": "Okay"
        },
        "order_reject": {
            "message": "Reject Order?",
            "cancel": "Cancel",
            "accept": "Okay"
        },
        "cancel": "Cancel",
        "delete": "Delete",
        "accept": "Okay",
        "reset": "Reset",
        "no": "No",
        "yes": "Yes",
        "cancel_order": "Cancel Order",
        "extend": "Extend Booking",
        "new_parking_price_will_be_calculated_and_order_will_be_extended": "New Parking Price will be calculated and booking will be extended to selected date and time",
        "clear_all_selected_vehicles": "Clear all selected vehicles?",
    },
    "success_dialog": {
        "success": "Success!",
        "error": "Error!",
        "fleet_setting_updated": "Fleet settings have been updated",
        "driver_setting_updated": "Settings have been updated",
        "site_suggestion_sent": "Site Suggestion sent",
        "client_created": "Client Created!",
        "client_updated": "Client Updated!",
        "fleet_created": "Fleet Created!",
        "fleet_updated": "Fleet Updated!",
        "vehicle_created": "Vehicle Created!",
        "vehicle_updated": "Vehicle Updated!",
        "supplier_created": "Supplier Created!",
        "supplier_updated": "Supplier Updated!",
        "promotion_created": "Promotion Created!",
        "promotion_updated": "Promotion Updated!"
    },
    "archived": "ARCHIVED: ",
    "record_deleted": "Record has been Archived",
    "order_canceled": "Order Canceled!",
    "cancel_question": "Cancel?",
    "trailer": "Trailer",
    "parking_with_trailer": "Do you need space for a trailer?",
    "none": "None",
    "choose_csv_file_to_upload": "Choose CSV file to upload",
    "no_sites_found_with_selected_filters": "No sites found with selected filters",
    "notifications": "Notifications",
    "admin_dashboard": "Admin Dashboard",
    "client_name": "Client Name",
    "company_name": "Company Name",
    "contact_name": "Contact Name",
    "company_details": "Client Details",
    "fleet_details": "Fleet Details",
    "fleet_name": "Fleet Name",
    "fleet_name_company": "Client | Fleet Name",
    "fleet": "Fleet",
    "choose_fleet": "Choose Fleet",
    "primary_contact": "Primary Contact",
    "credit": "Credit",
    "Credit": "Credit",
    "mpark_credit": "M-Park Credit",
    "import_clients": "Import Clients",
    "import_fleets": "Import Fleets",
    "import_vehicles": "Import Vehicles",
    "import_payouts": "Import Payouts",
    "import_suppliers": "Import Suppliers",
    "import_sites": "Import Sites",
    "import_promotions": "Import Promotions",
    "import_drivers": "Import Drivers",
    "import_payments": "Import Payments",
    "upload_payments": "Upload Payments",
    "new_client": "Add New Client",
    "new_fleet": "Add New Fleet",
    "new_vehicle": "Add New Vehicle",
    "save_new_client": "Save Client",
    "save_new_fleet": "Save Fleet",
    "save_new_vehicle": "Save Vehicle",
    "vehicle": "Vehicle",
    "date_time": "Date and Time",
    "new_site": "Add New Site",
    "new_supplier": "Add New Supplier",
    "new_promotion": "Add Promotion",
    "save_new_site": "Save Site",
    "save_new_supplier": "Save Supplier",
    "save_new_promotion": "Save Promotion",
    "export": "Export",
    "export_payouts": "Export Payouts",
    "export_payouts_file_format": "Export Payouts' file format",
    "number_of_vehicles": "Number of Vehicles",
    "number_of_drivers": "Number of Drivers",
    "account_payment_method": "Account Payment Method",
    "payment_methods": "Payment Methods",
    "add_payment_method": "Add Payment Method",
    "edit_payment_method": "Edit Payment Method",
    "save_payment_method": "Save Payment Method",
    "view_all_users": "View All users",
    "view_all_sites": "View All Sites",
    "drivers": "Driver(s)",
    "date_first_used": "Date First Used",
    "date_first_issued": "Date First Used",
    "client_payment_terms": "Client Payment Terms",
    "payment_terms_days": "Client Payment Days",
    "client_payment_terms_days": "Client Payment Days",
    "alternative_payment_method": "Alternative Payment Method",
    "registered_address": "Registered Address",
    "address": "Address",
    "first_line_address": "1st Line Address",
    "address_1": "1st Line Address",
    "address_line_2": "2nd Line Address",
    "second_line_address": "2nd Line Address",
    "address_2": "2nd Line Address",
    "billing_address_line_2": "2nd Line Billing Address",
    "address_3": "3rd Line Address",
    "postcode_town": "Postcode / Town",
    "enter_postcode_town": "Enter Postcode/ Town",
    "town_city": "Town/City",
    "enter_town_city": "Enter Town/City",
    "city_country": "City (Country)",
    "city": "Town/City",
    "region_country": "Region (Country)",
    "post_code": "Postcode",
    "enter_post_code": "Enter Postcode",
    "latitude": "Latitude",
    "longitude": "Longitude",
    "billing_currency": "Billing Currency",
    "credit_limit": "M-Park Credit Limit",
    "credit_zero_definition": "0 = {{definition}}",
    "number_of_bookings": "Number of Bookings",
    "type": "Type",
    "site_name": "Site Name",
    "security_rating": "Security Rating",
    "last_ten_orders": "Last 10 Orders",
    "view_all_bookings": "View All Bookings",
    "total_spaces": "Total Spaces",
    "site_currency": "Site Currency",
    "transactions": "Transactions",
    "supplier": "Supplier",
    "client": "Client",
    "supplier_payment_terms": "Supplier Payment Terms",
    "supplier_payment_terms_days": "Supplier Payment Terms days",
    "vat_registration_number": "VAT Registration Number",
    "parking_available": "Parking Available?",
    "spaces_available": "Total Spaces Available",
    "billing_address_same_as_registered_address": "Billing address same as registered address?",
    "billing_address": "Billing address",
    "first_line_billing_address": "1st line Billing address",
    "site_description": "Site Description",
    "images": "Images",
    "parking_price_configuration_note": "Please note: the lowest upto price that Is within range is used and the Period charged is rounded upto the nearest “Per Period”",
    "price": "Price",
    "period_value": "Period value",
    "period_per": "Period (per)",
    "upto": "upto",
    "upto_value": "Upto Value",
    "upto_period": "Upto Period",
    "category": "Category",
    "add_service": "Add Service",
    "facilities": "Facilities",
    "security_facilities": "Security Facilities",
    "choose_facility": "Choose Facility",
    "create": "Create",

    "comments": "Comments",
    "comments_to_driver_placeholder": "Enter your comments to the driver here",

    "commission_rates": "Mark Up Rates",
    "commission_rate_options": {
        "percentage": "Percentage",
        "flat_rate": "Flat Rate",
    },

    "vehicle_types": {
        "Forklift": "Forklift",
        "Van": "Van",
        "Truck": "Rigid",
        "TractorUnit": "Artic",
    },
    "commission_percentage": "Mark Up Percentage",
    "commission_amount": "Mark Up Amount",
    "commission_rate_value": "Mark Up rate value",
    "commission_rate_type": "Mark Up rate type",
    "open_24_hours": "Open 24 Hours",
    "site_size": "Site Size",
    "prebookable": "Prebookable?",
    "prebookable_facility": "Prebookable",
    "up_to_500_spaces": "Up to 500 spaces",
    "more_than_500_spaces": "More than 500 spaces",
    "yes": "Yes",
    "no": "No",
    "na": "N/A",
    "max_journey_duration": "Maximum Journey Duration",
    "up_to_one_hour": "Up to 1 Hour",
    "up_to_hours": "Up to {{hours}} Hours",
    "twelve_hours": "12 Hours",
    "more_than_hours": "More than {{hours}} Hours",
    "only_show_sales": "Only show sites with Promotions?",
    "switch_to_list_view": "Switch to list view",
    "switch_to_map_view": "Switch to Map view",
    "map_view": "Map",
    "list_view": "List",
    "view_details": "View Details",
    "tapa_rating_display": "{{rating}} TAPA Rating",
    "uncertified": "Uncertified",
    "best": "Best",
    "spaces_available_display": "{{spaces}} Spaces available",
    "price_per_twelve_hours": "{{price}} {{currency}} per 12 hours ",
    "request_order": "Request Booking",
    "instant_order": "Book Now",
    "booking_id": "Booking ID",
    "order_item_id": "Item ID",
    "order_id": "Order ID",
    "site_id": "Site ID",
    "update_exchange_rates": "Update Exchange rates",
    "registration": "Registration",
    "enter_your_security_code": "Enter Your Security Code",
    "we_have_sent_a_security_code": "We have sent a security code to {{email}}",
    "did_not_receive_security_code": "Didn't receive click here to send again",
    "description": "Description",
    "used": "Used?",
    "net_amount": "NET amount",
    "vat_rates": "VAT Rates",
    "vat_rate": "VAT Rate",
    "vat": "VAT",
    "new_vat_rate": "New VAT Rate",
    "state_code": "State Code",
    "state_code_placeholder": "ISO-3166-2 i.e. GB-ENG",
    "total": "Total",
    "status": "Status",
    "all": "All",
    "inactive": "Inactive",
    "unpaid": "Unpaid",
    "paid": "Paid",
    "addons": "Add Ons",
    "from": "From",
    "free": "Free",
    "to": "To",
    "payment": "Payment",
    "truck_park": "Truck Park",
    "card": "Card",
    "parking_price": "Parking Price",
    "subtotal": "Sub Total",
    "account_payment": "Account Payment",
    "total_to_pay_on_card": "Total to Pay on Card",
    "total_inc_vat": "Total (inc VAT)",
    "order_success_waiting_approval": "Please wait for {{siteName}} to approve your order. Feel free to leave this page and we will notify you when we have an update.",
    "waiting_approval_title": "Waiting for site approval....",
    "your_booking_is_ready": "Your booking is ready",
    "your_order_is_successful_show_qr_code": "Your booking has been approved, please show QR code at {{siteName}} for processing",
    "approve": "Approve",
    "disapprove": "Disapprove",
    "reject": "Reject",
    "you_have_not_selected_any_site_as_favourite": "You have not selected any site as favourite",
    "remove_favourite": "Remove as favourite",
    "add_favourite": "Add as favourite",
    "no_orders_available": "No Orders available",
    "promotion_message": "Promotion Message",
    "call_to_action": "Call to Action",
    "call_to_action_link_text": "Call to Action/Link Text",
    "product": "Product",
    "product_category": "Product Category",
    "message": "Message",
    "enter_message": "Enter Message",
    "discount": "Discount",
    "discount_type": "Discount Type",
    "expires": "Expires",
    "start_date": "Start Date",
    "expiry_date": "Expire Date",
    "link": "Link",
    "get_directions": "Get Directions",
    "show_qr_code": "Show QR Code",
    "show_all": "Show All",
    "show_only": "Show only {{subject}}",
    "id": "ID",
    "choose": "Choose",
    "spending_caps": "Spending Caps",
    "spending_cap": "Spending Cap",
    "fleet_spending_caps": "Default Fleet Spending Caps",
    "use_fleet_defaults": "Use Fleet Defaults",
    "parking_specific": "Parking Specific",
    "addon_specific": "Service and Add On Specific",
    "addon_spending_cap_instructions": "To restrict a particular Service/Add On, list them below",
    "site_specific": "Site Specific",
    "site_spending_cap_instructions": "On sites specified will be restricted",
    "region_specific": "Region Specific",
    "region_spending_cap_instructions": "if any region caps are specified, orders for sites outside the specified region will not be included",
    "quantity": "Quantity",
    "please_specify": "Please Specify",
    "cost": "Cost",
    "daily": "Daily",
    "weekly": "Weekly",
    "monthly": "Monthly",
    "yearly": "Yearly",
    "unlimited": "Unlimited",
    "add_addon": "Add Add On",
    "add_region": "Add Region",
    "add_site": "Add Site",
    "addon": "Add On",
    "covered_costs": "Covered Costs",
    "payment_details": "Payment Details",
    "email_notifications": "Email Notifications",
    "suggest_a_parking_site": "Suggest a Parking Site",
    "card_holder_name": "Card Holder name",
    "enter_card_holder_name": "Enter Card Holder name",
    "card_number": "Card Number",
    "card_expiry": "Card expiry",
    "name_on_the_card": "Name on the Card",
    "enter_card_number": "Enter Card number",
    "expire": "Expire",
    "enter_expire": "Enter Expire date",
    "ccv": "CCV",
    "fleets": "Fleet(s)",
    "notify_new_orders": "New Orders",
    "notify_orders_status": "Updates on Order Status",
    "notify_default": "Default for Notifications",
    "notify_daily_report": "Daily Vehicle Checks Completion Alerts",
    "notify_fault_daily_report": "Minor Vehicle Issue Alerts",
    "notify_severe_daily_report": "Severe Vehicle Issue Alerts",
    "notify_traffic_incident_report": "Road Traffic Accident Report Completion Alerts",
    "notify_loading_report": "Vehicle Loading Report Completion Alerts",
    "notify_border_report": "Border Crossing Report Completion Alerts",
    "notify_crime_report": "Crime Report Completion Alerts",

    "notify_journey_report": "New Vehicle Security Journey",
    "notify_journey_report_completion": "Vehicle Security Journey completed",
    "notify_journey_leg_completion": "Vehicle Security Journey Leg completed",

    "notify_booking_request": "Booking Request",
    "notify_booking_approved": "Booking Approved",
    "notify_booking_rejected": "Booking Rejected",

    "notify": {
        "None": "No",
        "Inherit": "Default",
        "Instant": "Instantly",
        "Daily": "Once a Day",
        "Weekly": "Once a Week",
    },
    "addon_purchase_using_fleet_account": "Driver Purchases Add On using Fleet Account",
    "receive_marketing_emails": "Receive Marketing Emails?",
    "preferred_notification_channel": "Preferred Notification Channel",
    "notification_channels": {
      "email":"Email",
      "sms":"Sms",
      "email_and_sms":"EmailAndSms",
    },
    "parking_site_name": "Parking Site Name",
    "enter_parking_site_name": "Enter a Parking Site Name",
    "parking_site_website": "Parking Site Website",
    "enter_parking_site_website": "Enter Parking Site Website",
    "distance_units": "Distance Units",
    "share_location": "Share Location?",
    "submit": "Submit",
    "reference": "Reference",
    "add_parking_prices": "Add Parking Prices",
    "global_commission": "Global Mark Up",
    "site_updated": "Site Updated",
    "site_created": "Site Created",
    "day": "Day",
    "hour": "Hour",
    "over_cap": "Over Cap",
    "remaining": "({{figure}} x remaining from {{period}} allowance)",
    "full_period": "Full Period",

    "formats": {
        "date": "Do MMM YY",
        "date_long": "dddd Do MMMM YYYY",
        "datetime": "Do MMM YY HH:mm",
        "short_datetime": "DD/MM/YY HH:mm",
        "short_datetime_reverse": "HH:mm - DD/MM/YYYY",
        "time": "HH:mm:ss",
        "full_date": "D MMMM, YYYY",
        "full_date_and_time": "D MMMM, YYYY HH:mm:ss",
        "short_date": "DD/MM/YYYY",
    },

    "Jan": "January",
    "Feb": "February",
    "Mar": "March",
    "Apr": "April",
    "May": "May",
    "Jun": "June",
    "Jul": "July",
    "Aug": "August",
    "Sep": "September",
    "Oct": "October",
    "Nov": "November",
    "Dec": "December",
    "total_parking": "Total Parking",
    "total_addons": "Total Addons",
    "grand_total": "Grand Total",
    "help_message_receive": "Thanks for contacting us, your message is received",
    "present_qr_code_for_payment": "Please present QR code at till for payment.",
    "enter_order_id_manually": "Enter Order ID Manually",
    "check_in": "Check In",
    "checked_in_at": "Checked in at",
    "checked_out_at": "Checked out at",
    "check_out": "Check Out",
    "check_off_what_customer_has_received": "Please check off what the customer has received.",
    "update": "Update",
    "you_have_been_checked_in": "You have been checked in",
    "your_parking_period_expires_on": "Your paid parking period expires {{expireDate}}",
    "driver_have_been_checked_in": "Driver has been checked in",
    "driver_parking_period_expires_on": "Driver's paid parking period expires {{expireDate}}",
    "you_have_been_checked_out": "You have been checked out",
    "driver_have_been_checked_out": "Driver has been checked out",
    "driver_checked_out_at": "Driver checked out at {{checkedOutTime}}",
    "delete_payment_method": "Delete Payment Method?",
    "delete_selected_image": "Delete selected image?",
    "delete_selected_vat_record": "Delete selected VAT record?",
    "no_data": "No Data",
    "no_report_data": "No Report Data",
    "your_account_is_current_on_stop": "Your Account is currently on stop. Please contact your Manager.",
    "this_order_is_not_approved_yet": "This order is not approved yet",
    "this_order_has_been_rejected": "This order is has been rejected",
    "this_order_has_been_completed_on": "This order is has been completed on {{time}}",
    "show_order_details": "Show order Details",
    "order_approved": "Order Approved",
    "order_declined": "Order Declined",
    "go_to_booking": "Go to Booking",
    "view_order": "View Order",
    "rebook": "Rebook",
    "your_order_has_been_approved": "Your order #{{orderId}} has been approved at {{siteName}} for {{bookingTime}}",
    "your_order_has_been_declined": "Your order #{{orderId}} has been declined at {{siteName}}. Please rebook or get in touch with us",
    "booking_checked_in": "Checked in",
    "you_have_checked_in": "You have checked in at {{siteName}} on your order #{{orderId}} at {{bookingTime}}",
    "booking_checked_out": "Checked out",
    "you_have_checked_out": "You have checked out of {{siteName}} on your order #{{orderId}} at {{bookingTime}}",
    "order_received": "Order received",
    "a_new_order_has_been_made": "A new order #{{orderId}} has been made at {{siteName}} on for {{bookingTime}}",
    "drivers_imported": "Drivers Imported",
    "users_imported": "Users Imported",
    "clients_imported": "Clients Imported",
    "fleets_imported": "Fleets Imported",
    "suppliers_imported": "Suppliers Imported",
    "sites_imported": "Sites Imported",
    "payments_imported": "Payments Imported",
    "payouts_imported": "Payouts Imported",
    "promotions_imported": "Promotions Imported",
    "vehicles_imported": "Vehicles Imported",
    "all_drivers_are_imported": "All drivers have been successful imported",
    "all_users_are_imported": "All users have been successful imported",
    "all_clients_are_imported": "All clients have been successful imported",
    "all_fleets_are_imported": "All Fleets have been successful imported",
    "all_suppliers_are_imported": "All Suppliers have been successful imported",
    "all_sites_are_imported": "All Sites have been successful imported",
    "all_payments_are_imported": "All Payments have been successful imported",
    "all_payouts_are_imported": "All Payouts have been successful imported",
    "all_promotions_are_imported": "All Promotions have been successful imported",
    "all_vehicles_are_imported": "All Vehicles have been successful imported",
    "go_to_fleet": "Go to Fleet",
    "go_to_users": "Go to Users",
    "go_to_clients": "Go to Clients",
    "go_to_fleets": "Go to Fleets",
    "go_to_suppliers": "Go to Suppliers",
    "go_to_sites": "Go to Sites",
    "go_to_promotions": "Go to Promotions",
    "go_to_vehicles": "Go to Vehicles",
    "spending_caps_addon_not_defined_warning": "*The addons not defined on this spending caps will not be covered by the company",
    "parking_booking_question": "Do you need parking?",
    "extend_booking": "Extend Booking",
    "cancel_extend": "Cancel Extend",
    "booking_extended": "Booking Extended",
    "order_updated": "Order Updated",
    "your_booking_has_been_extended": "Booking has been extended to {{expireDate}}",
    "order_has_been_updated": "Order has been updated",
    "currencies": "Currencies",
    "rate_base_currency": "1 {{baseCurrency}} =",
    "last_updated": "Last updated",
    "upload": "Upload",
    "csv_file_should_contain_the_following_columns": "CSV file should contain the following columns:",
    "list_of_vehicles_registration_numbers_separated_by_comma": "List of vehicles registration numbers separated by comma. is required if user type is driver",
    "list_of_vehicles_registration_numbers_separated_by_comma_driver": "List of vehicles registration numbers separated by comma.",
    "list_of_site_names_separated_by_comma": "List of site names separated by comma.",
    "first_name": "First name",
    "last_name": "Last name",
    "vehicles": "Vehicles",
    "options": "Options",
    "required": "Required",
    "column": "Column",
    "example": "Example",
    "notes": "Notes",
    "download_sample_file": "Download sample file",
    "please_enter_a_valid_code": "Please enter a valid code",
    "sites_found_with_current_filters": "{{sites}} sites found using the current filter",
    "addons_services": "Add ons & Services",
    "location": "Location",
    "dismiss_all": "Dismiss All",
    "no_new_notification": "No new Notification",
    "order_summary_and_checkout": "Order Summary and Checkout",
    "checkout_now": "Checkout now",
    "complete_purchase": "Complete Purchase and pay {{amount}}",
    "order_summary": "Order Summary",
    "billing_information": "Billing Information",
    "terms_condition": "Terms and Conditions",
    "more_information_about_the_site": "More information about the site",
    "security_rating_described": "Security Rating TAPA (1-5)",
    "parking_available_described": "Parking Available (Y/N)",
    "comma_separated_available_options": "Comma separated, Available options",
    "parking_hourly_price": "Parking Hourly Price",
    "place_order": "Place Order",
    "find_site_to_book": "Find a site to book",
    "find_sites_with_promotions": "Find a site with Promotions",
    "find_site_with_promotion_to_book": "Find a sites with promotions",
    "gr_code_location_error": "We could not get your location, Please enable location service to be able to to scan QR Code",
    "payment_date": "Payment Date",
    "leave_feedback": "Leave Feedback",
    "site_feedback": "Site Feedback",
    "site_feedback_sent": "Site Feedback sent!",
    "client_pricing": "Client Pricing",
    "save_client_pricing": "Save Client Pricing",
    "client_pricing_saved": "Client Pricing saved",
    "client_pricing_reset": "Client Pricing reset",
    "currency": "Currency",
    "add_single_payout": "Add Single Payout",
    "save_payout": "Save Payout",
    "payout_saved": "Payout Saved!",
    "mpark_credit_account": "M-Park Credit",
    "site_pricing": "Site Pricing",
    "add_site_pricing": "Add Site Pricing",
    "add_client_pricing": "Add Client Pricing",
    "reset_to_default_pricing": "Reset To Default Pricing",
    "not_payouts_found_for_today": "No Payouts found for today",
    "reset_parking_pricing_for_this_site_client": "Reset Parking Pricing for this site and client",
    "company": "Company",
    "share_app_on_social_media": "Share the app with friends colleagues on Social Media",
    "booking_cant_start_on_the_past": "Booking can't start in the past and must be 45 minutes or more from current time",
    "booking_cant_end_end_before_it_stated": "Booking can't end before it started",
    "booking_duration_must_be_at_lest_one_hour": "Booking duration must be at least 1 hour",
    "booking_cant_be_extend_to_before_initial_expire_date": "Booking Can't be extended to before initial expire date and must be extend by at least 1 hour",
    "payment_method": "Payment Method",
    "longitude_latitude": "Longitude/Latitude",
    "balance": "Balance",
    "tapa_rating": "TAPA Rating",
    "percentage": "Percentage",
    "price_range": "Price Range",
    "promotion_cant_expire_before_it_is_started": "Promotion cant expire before starting",
    "price_range_between": "per 12 hours - {{min}} and {{max}}",
    "delete_current_client": "Delete client?",
    "delete": "Delete",
    "client_deleted": "Client Deleted!",
    "supplier_site": "Supplier | Site",
    "total_revenue": "Total Revenue",
    "site_fees": "Site Fees",
    "mpark_revenue": "M-Park revenue",
    "north_short": "N",
    "north_west_short": "NW",
    "west_short": "W",
    "south_west_short": "SW",
    "south_short": "S",
    "south_east_short": "SE",
    "east_short": "E",
    "north_east_short": "NE",
    "north": "North",
    "north_west": "North West",
    "west": "West",
    "south_west": "South West",
    "south": "South",
    "south_east": "South East",
    "east": "East",
    "north_east": "North East",
    "change": "Change",
    "close": "Close",
    "messages": {
        "user_updated": "User Updated",
        "user_created": "User Created"
    },
    "discount_value": "Discount Value",
    "valid_fleet_name_that_is_created_for_this_driver_supplier": "Valid Fleet name that is linked to driver's Client account",
    "dismiss": "Dismiss",
    "filter_by_site_name": "Filter by Site Name",
    "reset": "Reset",
    "remove_all_filters": "Remove all Filters",
    "back_to_map_view": "Back to Map View",
    "promotions_available": "Promotions Available",
    "dismiss_all_notification": "Dismiss all notifications?",
    "dismiss_this_notification": "Dismiss this notifications?",
    "export_code": "Export QR Code",
    "parking_prices": "Parking Prices (ex VAT)",
    "parking_prices_vat": "Parking Prices (incl. VAT)",
    "incl_vat": "incl. VAT",
    "no_parking_prices_defined": "No Parking Prices defined on this site",
    "select_vehicle": "Select your vehicle to begin...",
    "create_a_booking": "Create a Booking",
    "steps": "({{current}} of {{steps}})",
    "distance": "Distance",
    "duration": "Duration",
    "book_now": "Book Now",
    "now": "Now",
    "booking_from": "Enter arriving date/time...",
    "booking_to": "Enter leaving date/time",
    "booking_duration": "How long for?",
    "journey_time": "Journey Time",

    "booking_summary": "Summary",
    "confirm_booking": "Confirm Booking",
    "add_to_order": "Add to order",
    "order_items": "Order Items",
    "on_account": "On Account",
    "on_card": "On Card",
    "loading": "Loading...",

    "payout": "Payout",

    "n_hours": "1 hour",
    "n_hours_plural": "{{count}} hours",
    "n_days": "1 day",
    "n_days_plural": "{{count}} days",
    "of_preposition": "{{item1}} of {{item2}}",
    "question_n": "Question {{count}}",
    "custom": "Custom",


    "print": "Print",
    "print_report": "PDF Report",

    "export_pdf": "Export Report",

    "notifications_types": {
        "BookingConfirmed": {
            "header": "Booking Approved",
            "body": "Your booking {{bookingNumber}} has been approved at {{siteName}} for {{bookingTime}}",
        },
        "BookingRejected": {
            "header": "Booking Declined",
            "body": "Your booking {{bookingNumber}} has been declined at {{siteName}}. Please rebook or get in touch with us",
        },
        "BookingCheckedIn": {
            "header": "Checked In",
            "body": "You have checked in at {{siteName}} on your booking {{bookingNumber}} at {{bookingTime}}",
        },
        "BookingCheckedOut": {
            "header": "Checked Out",
            "body": "You have checked out of {{siteName}} on your booking {{bookingNumber}} at {{bookingTime}}",
        },
        "BookingRequested": {
            "header": "New Booking",
            "body": "A new booking {{bookingNumber}} has been made at {{siteName}} on {{bookingTime}} for {{registration_number}}",
        },
        "instant": {
            "body": "A new instant booking {{bookingNumber}} has been made at {{siteName}} on {{created}}",
        },
    },
    "save": "Save",
    "use_current_location": "Use Current Location",
    "take_a_photo": "Take a Photo",
    "upload_a_photo": "Upload a Photo",
    "date_time_completed": "Date/Time completed",

    "number_of_licences": "Number of Licences",
    "monthly_bill": "Monthly Bill",
    "title": "Title",
    "renew_date": "Renew Date",
    "unit_price_gbp": "Unit Price (GBP)",
    "use_standard_price": "Use Standard Price",
    "cancel": "Cancel",
    "back": "Back",
    "back_to_n": "Back to {{n}}",
    "n_have_now_been_completed": "\"{{n}}\" has now been completed",
    "summary": "Summary",
    "reported_by": "Reported By",
    "list": "List",

    "activate": "Activate",
    "downgrade": "Downgrade",
    "upgrade": "Upgrade",
    "begin": "Begin",
    "add": "Add",

    "marketing_price" : "Marketing Price",
    "compliance_licences" : "Compliance Licences",
    "price_per_licence": "Price Per Licences",

    "compliance": {
        "options": "Compliance Options",
        "licence_price": "Auto Licence Price per User (GBP)",
        "licences_hit_limit": "You've hit your compliance licence limit.",
        "increase_licences": "Increase to add user",

        "cancellation_info_block": "The service will remain active until the next renewal date",
        "licences_description": "While Compliance is active you must have enough licences for all drivers in the fleet",

        "next_section": "Next Section",

        "title": "Compliance",
        "active": "Activate",
        "defects": "Defects",
        "outstanding": "Outstanding",

        "dashboard_link": "Daily Vehicle Check and Compliance",

        "dashboard_advert": "Want an easier way to do your vehicle compliance checks?",
        "dashboard_advert_button": "Find out more",

        "incident_information": "Incident Information",
        "datetime": "Date/Time of report",
        "override_warning": "Please note that the Monthly bill will be overwritten if the number of licenses is changed.",

        "report_status": {
            "Incomplete": "Incomplete",
            "Pending": "Pending",
            "Waiting": "Waiting",
            "Resolved": "Resolved",
            "Complete": "Complete",
        },

        "severity": {
            "None": "No Issues",
            "Fault": "Minor Issues",
            "Severe": "Severe Issues",
        },
        "severity_label": "Severity",

        "reporting_to": "Reporting to",
        "report_type": "Check Type",
        "view_image": "View Image",

        "answer": "Answer",
        "check_key_name": "Check",
        "details": "Details",

        "DailyReport": {
            "title": "Daily Vehicle Checks",
            "dashboard_summary": "A daily vehicle check sheet is a list of specific items that you must check each day before using your vehicle. The system will notify your fleet manager of any issues raised and keeps a log of all of your checks.",
            "complete": "Complete",
            "incomplete": "Incomplete",
            "no_issues": "All Good",
            "minor_issues": "Minor Issues",
            "severe_issues": "Severe Issues",
            "group_key_name": "Check Group",

            "completed": "You have completed the daily checks for {{registration}}",
            "minor_issues_long": "You have registered some minor issues please contact your fleet manager and get repaired in good time",
            "severe_issues_long": "You have registered some Severe issues please contact your fleet manager before driving",


            "inside": {
                "title": "Inside the Vehicle",
                "mirrors_glass": {
                    "title": "Mirrors and Glass",
                    "is_window_cracked": "Is the Windscreen Cracked / Scratched / discoloured?",
                    "cracked": "Yes Cracked",
                    "scratched": "Yes Scratched",
                    "discoloured": "Yes Discoloured",
                    "is_vision_obsured": "Does this obsure the driver's vision?",

                    "is_mirrors_acceptable": "Are all required mirrors fitted and working and free from damage?",

                    "not_fitted": "Not Fitted",

                    "is_camera_working": "If a camera system is used instead of a mirror are they not working or is the view incorrect?",
                },


                "wipers": {
                    "title": "Windscreen wipers and washers",
                    "is_working": "Are the windscreen wipers correctly fitted and working?",
                    "is_missing": "Are the windscreen wipers missing?",
                    "is_damaged": "Are the Windscreen wipers damaged or worn?",
                },

                "front_view": {
                    "title": "Front view",

                    "object_obstruction": "Do any objects get in the way of your front view?",
                    "wiper_obstruction": "Is there any obstruction within the swept area of the windscreen wipers?",
                },

                "dashboard": {
                    "title": "Dashboard warning lights and gauges",

                    "is_reading_working": "Are all instruments and gauges working correctly?",
                    "is_warning_working": "Are your warning lights working correctly?",
                },

                "steering": {
                    "title": "Steering",

                    "is_moving": "Does the steering wheel move properly?",
                    "is_powered": "Does the power assisted Steering work correctly?",
                    "has_play": "Does the steering have excessive play?",
                    "does_jam": "Does the steering jam?",
                },

                "horn": {
                    "title": "Horn",
                    "is_working": "Does the horn work?",
                    "is_accessible": "Is the horn accessible from the driver's seat?",
                },

                "breaks_air": {
                    "title": "Brakes and air build-up",
                    "working": "Does air build up correctly",
                    "warning": "Does the warning system work?",
                    "leaks": "Are there any air leaks?",
                    "footwell": "Is the footwell Clear?",
                    "service": "Does the service brake operate both the tractor and trailer brakes?",
                    "parking": "Does the parking brake for the tractor unit work?",
                    "loose": "Is the service brake loose, or does it have excessive side play or an incomplete anti-slip tread?",
                },

                "height_marker": {
                    "title": "Height marker",
                    "is_correct": "Is the correct height displayed on the vehicle height marker in the cab?",
                },

                "seatbelts": {
                    "title": "Seatbelts",
                    "is_damaged": "Do the seatbelts have any cuts, damage or fraying that may stop them from working?",
                    "is_secure": "Do the seatbelts stay secure when you plug them in?",
                    "does_retract": "Do the seatbelts retract against you when fitted, and fully retract when you take them off?",
                },



            },


            "outside": {
                "title": "Outside the vehicle",

                "lights": {
                    "title": "Lights and indicators",
                    "is_working": "Do all lights and indicators work correctly?",
                    "is_colour_correct": "Are all lenses are fitted, clean and the right colour?",
                    "brakes": "Do stop lamps come on when you apply the service brake and go out when you release it?",
                    "side": "Are side marker lights fitted and do they work?",
                },

                "fuel_oil": {
                    "title": "Fuel and oil leaks",
                    "cap": "Is the fuel filler cap fitted correctly?",
                    "leaks": "With engine turned on, are there any fuel or oil leaks underneath the vehicle?",
                },

                "battery": {
                    "title": "Battery security and conditions",
                    "is_secure": "Is the battery secure?",
                    "is_good": "Is the battery in good condition?",
                    "does_leak": "Does the battery leak?",
                },

                "diesel_exhaust": {
                    "title": "Diesel exhaust fluid (AdBlue)",
                    "has_adblue": "Is there enough AdBlue fluid in your diesel vehicle (if required)?",
                },

                "exhaust": {
                    "title": "Excessive engine exhaust smoke",
                    "excessive": "Does the exhaust emit an excessive amount of smoke?",
                },

                "body_security": {
                    "title": "Security of body and wings",
                    "fasteners_working": "Do all fastening devices work?",
                    "doors": "Are cab doors and trailer doors secure when closed?",
                    "panels": "Are body panels on tractor or trailer secure and not likely to fall off?",
                    "legs": "Are landing legs (if fitted) secure and not likely to fall off while driving?",
                    "guards": "Check side guards and rear under-run guards are fitted if required, and that they’re not insecure or damaged",
                },

                "spray": {
                    "title": "Spray Suppression Flaps",

                    "required": "Are spray suppression flaps are required?",
                    "secure": "are they secure?",
                    "damaged": "are they damaged?",
                    "clogged": "are they clogged with mud or debris?",
                },

                "wheels": {
                    "title": "Tires and wheel fixing",

                    "secure": "Are the tires and wheels secure?",
                    "tread": "Do the tires have a tread depth of at least 1mm?",
                    "inflated": "Are the tires inflated correctly?",
                    "cuts": "Are there any deep cuts in the tyre’s sidewall?",
                    "cord": "Is there cord visible anywhere on the tyre?",
                    "nuts": "Are all wheel nuts tight enough? - To do this you can check if wheel nut indicators (if fitted) have moved ",
                    "twin": "Are there any objects or debris trapped between the twin wheels?",
                },

                "brakes": {
                    "title": "Brake lines and trailer parking brake",

                    "couplings": "Are couplings free from debris and in the right place?",
                    "damage": "Is there any damage or wear to the brake lines?",
                    "trailer_brake": "Does the trailer parking brake operate correctly?",
                },

                "electrical": {
                    "title": "Electrical connections",

                    "insulated": "Is the visible wiring insulated?",
                    "risk": "Is any visible wiring likely to get caught or damaged?",
                    "coupling": "Are all electrical trailer couplings connected securely?",
                    "switches": "Do all electrical switches work correctly?",
                },

                "coupling": {
                    "title": "Coupling security",

                    "primary": "Is the trailer located correctly in the fifth wheel or coupling?",
                    "secondary": "Are the secondary locking devices in the correct position?",
                },

                "load": {
                    "title": "Security of load",
                    "secure": "Is your load secured against movement?",
                    "guidance": "Do you require any guidance in relation to the security of your load?",
                },

                "numberplate": {
                    "title": "Number plate",
                    "broken": "Is the number plate broken?",
                    "incorrect": "Is the number plate incorrect or spaced incorrectly?",
                    "dirty": "Is the number plate dirty?",
                    "faded": "Is the number plate faded?",
                    "covered": "Is the number plate covered over by anything?",
                },

                "reflectors": {
                    "title": "Reflectors (including side reflectors)",
                    "missing": "Are the reflectors missing?",
                    "broken": "Are the reflectors broken?",
                    "insecure": "Are the reflectors insecure?",
                    "fitted": "Are the reflectors fitted correctly?",
                    "colour": "Are the reflectors the wrong colour?",
                    "covered": "Are the reflectors obscured by dirt or other objects?",
                },

                "plates": {
                    "title": "Markings and warning plates (including conspicuity markings)",
                    "colour": "Are the markings and warning plates the right colour?",
                    "visible": "Are the markings and warning plates visible?",
                    "secured": "Are the markings and warning plates securely fastened?",
                    "obscured": "Are the markings and warning plates obscured by dirt or other objects?",
                    "hazard": "If the vehicle is carrying dangerous goods, do the hazard information panels show the correct information for the load?",
                    "hazard_visible": "If the vehicle is carrying dangerous goods, are the hazard information panels visible?",
                    "hazard_secured": "If the vehicle is carrying dangerous goods, are the hazard information panels securely fastened?",
                    "hazard_covered": "If the vehicle is carrying dangerous goods, are the hazard information panels obscured by dirt or other objects?",
                },
            },

            "before_use": {
                "title": "Before Use",

                "general": {
                    "title": "General",
                    "previous": "Have all previously reported faults been rectified?",
                },

                "leaks": {
                    "title": "Obvious Leaks",
                    "any": "Is there any sign of leakage?",
                },

                "hydraulics": {
                    "title": "Hydraulic Fluid Level",
                    "fluid_level": "Is the reservoir at the correct level?",
                },

                "carriage": {
                    "title": "Mast and Carriage",
                    "damage": "Is there any damage or foreign items to the mast and carriage?",
                    "lubrication": "Is there appropriate lubrication to the mast and carriage?",
                },

                "chains_bolts": {
                    "title": "Chains and Fixing Bolts",
                    "damage": "Is there any damaged links or fixing bolts?",
                    "stretching": "Is there any obvious stretching?",
                    "lubrication": "Is there appropriate lubrication?",
                },

                "forks": {
                    "title": "Forks",
                    "cracks": "Are there any signs of cracks on the forks, especially at the heels, or any other sign of damage or excess wear",
                    "damage": "Are there any other sign of damage or excess wear on the forks?",
                    "pins": "Are there any other sign of damage or excess wear on the securing pins?",
                },

                "backrest": {
                    "title": "Backrest/Extension",
                    "damage": "Are there any signs of damage or debris to the backrest and extension?",
                },

                "attachments": {
                    "title": "Attachments",
                    "secure": "Are the attachments secure at the fixings and locking points?",
                    "hydraulics": "Are hydraulics and other connections secure?",
                },

                "wheels": {
                    "title": "Tires / Wheels / Nuts",
                    "tyres": "Are there any obvious signs of damage to the tires, including cuts and tread wear?",
                    "pressure": "Are Pneumatic tires the correct pressure?",
                    "damage": "Are the wheels, including rims damaged?",
                    "nuts": "Are the wheel nuts loose?",
                },

                "seat": {
                    "title": "Seat and Seat Belt",
                    "secure": "Is the seat correctly fixed to the truck and not loose or damaged?",
                    "belt": "Is the seat belt or other restraint secure and functioning correctly?",
                },

                "steering": {
                    "title": "Steering",
                    "acceptable": "Does the steering feel normal with no undue play or unusual movement?",
                },

                "service_brakes": {
                    "title": "Service Brakes",
                    "acceptable": "Do the brakes feel normal and work efficiently?",
                },

                "parking_brakes": {
                    "title": "Parking Brakes",
                    "acceptable": "Does the parking brake work efficiently and release correctly?",
                },

                "controls": {
                    "title": "Operating Controls",
                    "acceptable": "Do all foot and hand controls operate correctly and are not obstructed in any way?",
                },

                "system": {
                    "title": "Operating System",
                    "acceptable": "If the truck is fitted with computerised systems, have they initialized correctly and are working?",
                },

                "warning_lights": {
                    "title": "Warning Lights",
                    "operating": "Do all warning lights operate correctly?",
                    "any_warning": "Are any warning lights illuminated?",
                },

                "dashboard": {
                    "title": "Gauges/Instruments",
                    "acceptable": "Do all gauges and other instruments work correctly?",
                },

                "lights": {
                    "title": "Lights/Beacon",
                    "acceptable": "Are all Lights and beacons operating correctly?",
                },

                "horn": {
                    "title": "Horn",
                    "acceptable": "Does the horn operate correctly?",
                },

                "alarms": {
                    "title": "Alarms",
                    "acceptable": "Does the alarm operate correctly?",
                },

                "warnings": {
                    "title": "Other Warning Devices",
                    "acceptable": "If other warning systems fitted, do they work correctly?",
                },

                "guards": {
                    "title": "Safety Guards and Covers",
                    "acceptable": "Are all safety guards and covers properly fastened and secured?",
                },

                "body": {
                    "title": "Bodywork",
                    "acceptable": "Is there any apparent damage to the bodywork, including racking and other equipment?",
                },
            },
        },


        "LoadingReport": {
            "title": "Loading Report",

            "location_name": "Name of Loading Location",
            "dashboard_summary": "Vehicles should be suitable for any loads carried, and it is especially important that the vehicle has adequate anchor points to make sure that loads can be carried securely.  Use this report to log your observations and evidence that all necessary precautions have been taken..",
            "date": "Date of Report",
            "cargo": "Cargo",
        },

        "JourneyReport": {
            "title": "Vehicle Security Report",
            "new": "Start New",
            "list": "Access Saved",
            "report_id": "Report ID",

            "start": "Start",
            "stop": "Check",
            "end": "End",
            "start_time": "Start Time",
            "end_time": "End Time",
            "dashboard_summary": "The Vehicle Security Report allows you to record, simply and efficiently, a geo-tagged time stamped log of the checks made, every time your vehicle is left unattended, or your load is interacted with to minimise the risk of having any Clandestine Immigrants onboard.",

            "name": "Journey Title",
            "date_time": "Date and Time",

            "trailer_number": "Trailer Number",
            "security_device_type": "Security Device Type",
            "security_device_type_other": "Please Specify",

            "security_device_number": "Security Device Serial Number",
            "security_device_photos": "Security Device Images",

            "device_type": {
                "Seal": "Seal",
                "Lock": "Lock",
                "Other": "Other",
            },

            "check_load_space": "Check load space is effectively secured against unauthorised access?",

            "check_cargo": "Check cargo is effectively secured against unauthorised access? (if applicable)",

            "check_devices": "Check all locks, seals and other security devices for signs of tampering, damage and unauthorised repair or replacement?",

            "check_cables": "Check TIR cables and straps and related eyelets for signs of tampering, damage or signs of unauthorised repair or replacement? (if applicable)",

            "check_shell": "Check outer shell or fabric cover for signs of unauthorised access? (if applicable)",

            "check_external_storage": "Check all external storage compartments for signs of unauthorised access?",

            "check_wind_deflectors": "Check wind deflectors for signs of unauthorised access?",

            "check_beneath": "Check beneath the vehicle for signs of unauthorised access?",

            "check_inside": "Check inside the vehicle for signs of unauthorised access?",

            "check_roof_inside": "Check the vehicle’s roof from inside the vehicle for signs of unauthorised access?",


            "check_person": "Check that no person has gained unauthorised access?",

            "check_documentaion": "Check all necessary documentation is correct?",

            "any_signs": "Any signs noted that a person has gained, or has attempted to gain, unauthorised access to the vehicle?",

            "endorsement": "Endorsement from a third party who carried out the above checks (if applicable)",

            "instructions_complete_journey": "To view your saved journey checks, simply select the relevant entry below. If you wish to share them with a third party, you can easily export your journey checks to PDF. Just click on the \"PDF report\" button, then select the \"share\" button on your browser and choose how you want to share it from your device.",


            "add_stop": "Add New Check",

            "add_final": "End Journey",
        },

        "CrimeReport": {
            "title": "Crime Report",
            "dashboard_summary": "Log for audit and legal purposes any criminal incidents that occur in the course of your duties. Keep a log of steps taken, authorities involved and relevant details.",

            "date": "Date of Incident",
            "type": "Incident Type",
            "types": {
                "Robbery": "Robbery",
                "Mugging": "Mugging",
                "Assault": "Assault",
                "VehicleTheft": "Vehicle Theft",
                "VehicleDamage": "Vehicle Damage",
                "FuelTheft": "Fuel Theft",
                "Hijacking": "Hijacking",
                "Terrorist": "Terrorist Incident",
                "Stowaways": "Stowaways",
            },
            "description": "Incident Description",

            "parties_involved": "Parties Involved",
            "witnesses": "Witnesses",

            "police_report": "Police Report",
            "filed_with_police": "Police Report Filed?",
            "police_branch_name": "Precinct",
            "police_reference": "Crime Report Number",
            "officer_name": "Reporting Officer",
            "police_contact_number": "Phone",
            "follow_up_actions": "Follow Up Action",

        },

        "BorderReport": {
            "title": "Border Report",
            "dashboard_summary": "Evidence that you have taken all necessary steps to ensure the integrity of your load and vehicle whilst crossing international borders with your vehicle.",

            "time": "Time of report",

            "general_checks": "General Vehicle Checks",
            "inside": "Inside of the Vehicle",
            "outside": "Outside of Vehicle",
            "is_damage": "Is there any damage to the roof or body panels/sheets of the vehicle?",

            "other_checks": "Other Checks",
            "external_compartments": "Are the external compartments clear and sealed?",
            "underside_accessible": "Is the underside of the vehicle and any other accessible hiding places clear?",
            "seals_intact": "Are all seals intact?",
            "locks_intact": "All padlocks and door locks fastened?",
            "tir_intact": "Is the TIR chord fitted and sealed?",
            "life_signs_checked": "Have the Co2, camera checks and Heartbeat monitor checks been carried out by an outside agency?",
        },

        "TrafficIncidentReport": {
            "title": "Accident Report",
            "dashboard_summary": "If you are unfortunately involved in an accident involving your vehicle, use this report to record all relevant information and evidence to support you for audit and legal purposes.",


            "date": "Date/Time of Accident",

            "description": "Accident Description",
            "description_instructions": "Please upload a drawing of the accident",

            "injuries_damage": "Injuries and Damage",
            "driver_injuries": "Did the driver suffer any injuries?",
            "passengers_injuried": "Did any passengers suffer any injuries?",
            "no_injuries": "No Injuries",
            "injured_passengers": "Names and addresses of passengers injured",
            "no_damange": "No Damage",
            "vehicle_damaged": "Did the vehicle suffer any Damage?",
            "vehicle_damage": "Description of damage to the vehicle",

            "parties_involved": "Third Party Driver Details",
        },

        "inactive": {
            "title": "Compliance Checks",

            "content": "Introducing M-PARK’s comprehensive compliance solution for fleet managers and drivers. With M-PARK, you can effortlessly manage all your compliance tasks in one place, ensuring your drivers, vehicles and loads stay safe, keep on top of regulations, and avoid costly penalties.\n\nM-PARK is an app for drivers to record geo-tagged daily vehicle checks, crime reports, loading reports, border crossing reports, accident reports and vehicle security reports, along with supporting photos and documentation, all on a smart device.\n\nOur state-of-the-art system features an intuitive interface and advanced reporting capabilities, making it easy to monitor your compliance performance and identify areas that require attention. M-PARK has everything you need to stay compliant and minimize your risk.\n\nBut that's not all. With M-PARK, you'll also benefit from:\n - Customizable workflows to match your specific compliance needs\n - Alerts and notifications to keep you informed of any issues and tasks\n - Secure cloud-based storage for all your compliance data, accessible from anywhere, anytime\n - Easy to share with any relevant third party\n\nDon't let compliance be a headache. Choose M-PARK and streamline your compliance management today. Visit [www.m-park.co.uk](https://www.m-park.co.uk) to learn more. M-PARK, your journey starts here.",

            "message_to_drivers": "If you’re interested in these features, talk to your fleet manager.",
            "message_to_clients": "If you’re interested in these features, you can enable on one of your fleets from the edit page",

            "button_text": "Activate compliance in settings",
        }
    },


    "marketing": {
        "title": "Marketing",
        "directory": "Directory",

        "page": "View Page",
        "active": "Active",
        "description": "Ability to create a public webpage to showcase your site",
        "cancellation_info_block": "The service will remain active until the next renewal date",
        "edit_mode": "Edit Mode",
        "options": "Marketing Options",
        "licence_price": "Licence Price",
        "switch_sides": "Switch Sides",

        "colours": {
            "change": "Change Colours",
            "primary": "Primary",
            "secondary": "Secondary",
        },

        "button": {
            "click_to_edit": "Click to Edit",
            "edit": "Edit Button",
            "title": "Title",
            "href": "Link",
            "target": "Target",
            "targets": {
                "_self": "Current Tab",
                "_blank": "New Tab",
            },
            "variant": "Style",
        },

        "blocks": {
            "Addons": "Services and Facilities",
            "CallToAction": "Call To Action",
            "Details": "Details",
            "Gallery": "Gallery",
            "MediaWithContent": "Image with content",
            "Pricing": "Pricing",
            "Services": "Security Facilities",
        },

        "advert" : {
            "title": "Site Marketing",
            "content": "With M-PARK, you can quickly and simply create your own customisable website, allowing you to market your site and services to build traffic in key time slots. This means you can boost and upsell additional services by highlighting key promotions, giving you the opportunity to maximise your revenue potential.\n\nBut that's not all. With M-PARK, you'll also gain access to our large network of customers who are actively involved in transporting goods across Europe and have daily parking needs. This means you can market your site(s) and ensure you get the business you are looking for from across Europe, increasing your visibility and potential customer base.\n\nAnd if you're TAPA accredited, you'll be able to maximise the benefits of your accreditation by marketing your site(s) to our network of TAPA customers, giving you the opportunity to attract more business from customers who value high-security standards.\n\nTo start using the benefits of this feature, Site Managers can navigate to **Settings > Marketing > Active > Yes**. If you have any queries, contact <Info@m-park.co.uk>",
        },
    },
    "approval": {
        "title": "Pre-Book Auto Approval Spaces",
        "description": "In the boxes below, you can define how many spaces are available for pre-booking on M-Park every day (7 days in advance). These pre-booked spaces may be approved automatically.",
        "status": "Auto-approval enabled?",
        "invalid_value": "Invalid Value for {{day}}",
        "invalid_message": "Message characters cannot be greater than 400",
        "days": {
            "monday": "Monday",
            "tuesday": "Tuesday",
            "wednesday": "Wednesday",
            "thursday": "Thursday",
            "friday": "Friday",
            "saturday": "Saturday",
            "sunday": "Sunday",
            "default": "Default",
            "spaces": "Spaces Available",
            // "message": "Days value will dynamically change base on bookings"
            "message": ""
        },
        "message": {
            "title": "Global pre book message to Drivers",
            "description": "Up to 400 characters available.",
        }
    },
    "instant": {
        "title": "Instant Booking",
        "status": "Instant Booking?",
    },
    "booking": {
        "methods": "Booking Methods",
        "auto": "Auto Pre-Book",
        "manual": "Manual Pre-Book",
        "instant": "Instant Book",
        "instant_booking": "Instant Booking",
        "auto_approval": "Pre Booked",
        "out_of_gauge": "Out of Gauge",
        "adr": "ADR",
        "qr_not_activated": "QR will active 15 minutes before the booking start time",
        "datetime": "Date/Time of book"
    },
    "instant_booking": {
        "select_vehicle": "Select a different vehicle",
        "search_registration": "Search for a registration",
        "search_site_name": "Search for a site name",
        "present_message": "Please present for payment.",
        "invalid_code": "Invalid QR code",
        "select_site": "Select site to continue",
        "confirm": "Confirm Details",
        "no_parking": "No Parking",
        "parking": "Parking",
        "amend": "Amend Booking",
        "error": "Highlighted items above are not covered by the fleet.",
        "warning": "Once this booking is confirmed it cannot be cancelled",
        "back_to_scanner": "Back to scanner",
        "view_booking": "View Booking",
        "booking_complete": "Changes Complete",
        "check_off": "Mark Add Ons as Complete When Used.",
        "save_changes": "Save Changes",
        "no_sites": "You don't have any site that support pay on arrival"
    },
    "find-site" : {
        "results_for": "Results for"
    },
    "media_selector": {
        "title": "Select Image",
        "save": "Save",
        "upload": "Upload",
    },
    "prebookable_described": "Prebookable (Y/N)",
    "instant_booking_described": "Instant Booking (Y/N)",
    "select_or_type_to_search": "Select or Type to Search",
    "booking_type": "Type",
    "booking_types": {
        "instant_booking": "Instant",
        "auto_approval_booking": "Auto",
        "manual_approval_booking": "Manual",
        "subscription": "Subscription",
    },
    'pre_book_instant_book': 'Pre-Book/Instant Book'
};
