export default {
    "Apr": "Duben",
    "Aug": "Srpen ",
    "Credit": "Kredit",
    "Dec": "Prosinec",
    "Feb": "\u00danor",
    "Jan": "Leden",
    "Jul": "\u010cervenec",
    "Jun": "\u010cerven",
    "Mar": "B\u0159ezen",
    "May": "Kv\u011bten",
    "Nov": "Listopad",
    "Oct": "\u0158\u00edjen",
    "Sep": "Z\u00e1\u0159\u00ed",
    "a_new_order_has_been_made": "Nov\u00e1 objedn\u00e1vka #{{orderId}} byla provedena na parkovi\u0161ti {{siteName}} na {{bookingTime}}",
    "account_number": "\u010c\u00edslo \u00fa\u010dtu",
    "account_payment": "Platba na \u00fa\u010det",
    "account_payment_method": "Platebn\u00ed mo\u017enosti \u00fa\u010dtu",
    "account_status": "Stav z\u00e1kaznick\u00e9ho \u00fa\u010dtu",
    "account_type": "Typ z\u00e1kaznick\u00e9ho \u00fa\u010dtu",
    "active": "Aktivn\u00ed",
    "add_addon": "P\u0159idat dopl\u0148kovou slu\u017ebu",
    "add_client_pricing": "P\u0159idat cen\u00edk pro klienty",
    "add_driver": "Nov\u00fd \u0159idi\u010d",
    "add_drivers": "P\u0159idat \u0159idi\u010de",
    "add_favourite": "P\u0159idat jako obl\u00edben\u00e9",
    "add_parking_price": "P\u0159idat cenu parkovn\u00e9ho",
    "add_parking_prices": "P\u0159idat ceny parkovn\u00e9ho",
    "add_payment_method": "P\u0159idat platebn\u00ed mo\u017enost",
    "add_promotion": "P\u0159idat mo\u017enosti",
    "add_region": "P\u0159idat oblast",
    "add_service": "P\u0159idat slu\u017eby",
    "add_single_payout": "P\u0159idat jednu platbu",
    "add_site": "P\u0159idat polohu",
    "add_site_pricing": "P\u0159idat cen\u00edk parkovi\u0161t\u011b",
    "add_to_order": "P\u0159idat k objedn\u00e1vce",
    "addon": "Dop\u0148kov\u00e1 slu\u017eba",
    "addon_purchase_using_fleet_account": "Dopl\u0148kov\u00e9 slu\u017eby pro \u0159idi\u010de hrazeny \u00fa\u010dtem flotily",
    "addon_specific": "Specifick\u00e9 pro slu\u017ebu a dopln\u011bk",
    "addon_spending_cap_instructions": "Pokud si p\u0159ejete omezit konkr\u00e9tn\u00ed slu\u017ebu\/dopln\u011bk, uve\u010fte je pros\u00edm n\u00ed\u017ee",
    "addons": "Dopl\u0148ky",
    "addons_services": "Dopl\u0148ky & Slu\u017eby",
    "address": "Adresa",
    "address_line_2": "Adresa",
    "admin_dashboard": "P\u0159ehled spr\u00e1vce",
    "all": "V\u0161echno",
    "all_clients_are_imported": "V\u0161ichni klienti byli \u00fasp\u011b\u0161n\u011b p\u0159id\u00e1ni",
    "all_drivers_are_imported": "V\u0161ichni \u0159idi\u010di byli \u00fasp\u011b\u0161n\u011b p\u0159id\u00e1ni",
    "all_fleets_are_imported": "V\u0161echny vozov\u00e9 parky byly \u00fasp\u011b\u0161n\u011b p\u0159id\u00e1ny",
    "all_payments_are_imported": "V\u0161echny platby byly \u00fasp\u011b\u0161n\u011b p\u0159id\u00e1ny ",
    "all_payouts_are_imported": "V\u0161echny v\u00fddaje byly \u00fasp\u011b\u0161n\u011b p\u0159id\u00e1ny ",
    "all_promotions_are_imported": "V\u0161echny preference byly \u00fasp\u011b\u0161n\u011b p\u0159id\u00e1ny",
    "all_sites_are_imported": "V\u0161echna parkovi\u0161t\u011b byla \u00fasp\u011b\u0161n\u011b p\u0159id\u00e1na",
    "all_suppliers_are_imported": "V\u0161ichni dodavatel\u00e9 byli \u00fasp\u011b\u0161n\u011b p\u0159id\u00e1ni",
    "all_users_are_imported": "V\u0161ichni u\u017eivatel\u00e9 byli \u00fasp\u011b\u0161n\u011b p\u0159id\u00e1ni",
    "all_vehicles_are_imported": "V\u0161echna vozidla byla \u00fasp\u011b\u0161n\u011b p\u0159id\u00e1na",
    "alternative_payment_method": "Jin\u00e1 platebn\u00ed metoda",
    "amount": "\u010c\u00e1stka",
    "app": "M-Park Aplikace",
    "approve": "Potvrdit ",
    "archived": "ARCHIVOV\u00c1NO:",
    "back_to_map_view": "Zp\u011bt na zobrazen\u00ed mapy",
    "balance": "Z\u016fstatek",
    "best": "Nejlep\u0161\u00ed",
    "billing_address": "Faktura\u010dn\u00ed adresa ",
    "billing_address_line_2": "Faktura\u010dn\u00ed adresa",
    "billing_address_same_as_registered_address": "Faktura\u010dn\u00ed adresa je stejn\u00e1 jako registra\u010dn\u00ed adresa?",
    "billing_county": "Kraj",
    "billing_currency": "Faktura\u010dn\u00ed m\u011bna",
    "billing_information": "Faktura\u010dn\u00ed \u00fadaje",
    "booking_cant_be_extend_to_before_initial_expire_date": "Rezervaci nelze prodlou\u017eit p\u0159ed po\u010d\u00e1te\u010dn\u00edm datem vypr\u0161en\u00ed platnosti a mus\u00ed b\u00fdt prodlou\u017eena alespo\u0148 o 1 hodinu",
    "booking_cant_end_end_before_it_stated": "Rezervace nem\u016f\u017ee skon\u010dit d\u0159\u00edve, ne\u017e za\u010dala",
    "booking_cant_start_on_the_past": "Rezervace nem\u016f\u017ee za\u010d\u00edt zp\u011btn\u011b a mus\u00ed b\u00fdt 45 minut a v\u00edce od aktu\u00e1ln\u00edho \u010dasu",
    "booking_checked_in": "P\u0159ihl\u00e1\u0161en",
    "booking_checked_out": "Odhl\u00e1\u0161en",
    "booking_duration": "Na jak dlouho?",
    "booking_duration_must_be_at_lest_one_hour": "Rezervace mus\u00ed trvat minim\u00e1ln\u011b 1 hodinu",
    "booking_extended": "Rezervace byla prodlou\u017eena",
    "booking_from": "Zadejte datum\/\u010das p\u0159\u00edjezdu",
    "booking_id": "\u010c\u00edslo rezervace",
    "booking_summary": "Souhrn",
    "booking_to": "Zadejte datum\/\u010das odjezdu",
    "call_to_action": "Prov\u00e9st",
    "call_to_action_link_text": "Prov\u00e9st",
    "cancel_extend": "Zru\u0161it prodlou\u017een\u00ed",
    "cancel_question": "Zru\u0161it?",
    "card": "Karta",
    "card_expiry": "Platnost karty",
    "card_holder_name": "Majitel platebn\u00ed karty",
    "card_number": "\u010c\u00edslo karty",
    "category": "Kategorie ",
    "ccv": "Bezpe\u010dnostn\u00ed k\u00f3d CCV",
    "change": "Zm\u011bnit ",
    "check_email": "Zkontrolujte pros\u00edm emailovou adresu",
    "check_in": "P\u0159ihl\u00e1sit",
    "check_off_what_customer_has_received": "Pros\u00edm za\u0161krtn\u011bne, co z\u00e1kazn\u00edk obdr\u017eel.",
    "check_out": "Odhl\u00e1sit",
    "checked_in_at": "P\u0159ihl\u00e1\u0161en v ",
    "checked_out_at": "Odhl\u00e1\u0161en v ",
    "checkout_now": "Zkontrolovat nyn\u00ed",
    "choose": "Vybrat",
    "choose_client": "Vybrat klienta",
    "choose_csv_file_to_upload": "Vyberte CSV soubor, kter\u00fd si p\u0159ejete nahr\u00e1t",
    "choose_driver": "Vybrat \u0159idi\u010de",
    "choose_facility": "Vybrat za\u0159\u00edzen\u00ed",
    "choose_fleet": "Vybrat vozov\u00fd park ",
    "choose_type": "Vyberte typ",
    "city": "M\u011bsto",
    "city_country": "M\u011bsto (St\u00e1t)",
    "client": "Klient",
    "client_deleted": "Klient byl smaz\u00e1n!",
    "client_name": "Jm\u00e9no klienta",
    "client_payment_terms": "Platebn\u00ed podm\u00ednky klienta",
    "client_payment_terms_days": "Splatnost klienta",
    "client_pricing": "Cen\u00edk pro klienty",
    "client_pricing_reset": "Resetovat cen\u00edk pro klienty",
    "client_pricing_saved": "Cen\u00edk pro klienty byl ulo\u017een",
    "client_supplier": "Klient \/ Dodavatel",
    "clients_imported": "Detaily ke klient\u016fm byly vlo\u017eeny",
    "close": "Zav\u0159\u00edt",
    "column": "Sloupec",
    "comma_separated_available_options": "Odd\u011blen\u00e9 \u010d\u00e1rkou, dostupn\u00e9 mo\u017enosti",
    "commission_amount": "\u010c\u00e1stka provize",
    "commission_percentage": "Procenta provize",
    "commission_rate_options": {
        "percentage": "Procenta",
        "flat_rate": "Pau\u0161\u00e1ln\u00ed sazba"
    },
    "commission_rate_type": "Typ sazby provize",
    "commission_rate_value": "Hodnota sazby provize",
    "commission_rates": "Sazby provize",
    "company": "Spole\u010dnost",
    "company_details": "Detaily spole\u010dnosti",
    "company_name": "Jm\u00e9no spole\u010dnosti",
    "complete_purchase": "Dokon\u010dete n\u00e1kup a zapla\u0165te {{amount}}",
    "confirm_booking": "Potvrdit rezervaci",
    "confirm_your_email": "Potvr\u010fte emailovou adresu",
    "conform_dialog": {
        "are_you_sure": "Jste ji jist\u00fd?",
        "order_confirm": {
            "message": "Potvrdit objedn\u00e1vku?",
            "cancel": "Zru\u0161it",
            "accept": "OK"
        },
        "order_reject": {
            "message": "Zru\u0161it objedn\u00e1vku?",
            "cancel": "Zru\u0161it",
            "accept": "OK"
        },
        "cancel": "Zru\u0161it",
        "delete": "Odstranit",
        "accept": "OK",
        "reset": "Resetovat ",
        "no": "Ne",
        "yes": "Ano",
        "cancel_order": "Zru\u0161it objedn\u00e1vku",
        "extend": "Prodlou\u017eit rezervaci",
        "new_parking_price_will_be_calculated_and_order_will_be_extended": "Bude vypo\u010d\u00edt\u00e1na nov\u00e1 cena parkovn\u00e9ho a rezervace bude prodlou\u017eena na vybran\u00e9 datum a \u010das",
        "clear_all_selected_vehicles": "Vymazat v\u0161echna vybran\u00e1 vozidla?"
    },
    "contact_name": "Jm\u00e9no kontaktn\u00ed osoby",
    "continue": "Pokra\u010dovat",
    "continue_sign_in": "Pro p\u0159ihl\u00e1\u0161en\u00ed pou\u017eijte nov\u00e9 heslo ",
    "cost": "N\u00e1klady",
    "country": "Zem\u011b",
    "county": "Kraj",
    "covered_costs": "Naklady pokryty",
    "create": "Vytvo\u0159it ",
    "create_a_booking": "Vytvo\u0159it rezervaci",
    "create_account": "Vytvo\u0159it \u00fa\u010det",
    "create_password_header": "Vytvo\u0159te nov\u00e9 heslo",
    "create_user": "Vytvo\u0159it u\u017eivatele",
    "credit": "Kredit ",
    "credit_limit": "Kreditn\u00ed limit",
    "credit_zero_definition": "0 = {{definition}}",
    "csv_file_should_contain_the_following_columns": "CSV soubor by m\u011bl obsahovat n\u00e1sleduj\u00edc\u00ed sloupce:",
    "currencies": "M\u011bny",
    "currency": "M\u011bna",
    "custom": "Zvyk",
    "daily": "Denn\u011b ",
    "dashboard": {
        "dashboard": "P\u0159ehled",
        "total_m_park_fee": "Poplatek za M-Park celkem",
        "parking_occurrences": "Vyu\u017eit\u00ed z d\u016fvodu parkov\u00e1n\u00ed",
        "non_parking_occurrences": "Vyu\u017eit\u00ed z jin\u00fdch d\u016fvod\u016f",
        "active_sites": "Dostupn\u00e1 parkovi\u0161t\u011b",
        "active_users": "Aktivn\u00ed u\u017eivatel\u00e9",
        "active_accounts": "Aktivn\u00ed \u00fa\u010dty",
        "total_site_revenue": "Celkov\u00fd p\u0159\u00edjem parkovi\u0161t\u011b",
        "total_revenue": "Celkov\u00fd p\u0159\u00edjem",
        "total_non_parking_revenue": "Celkov\u00fd p\u0159\u00edjem mimo parkov\u00e1n\u00ed",
        "average_order_value": "Pr\u016fm\u011brn\u00e1 cena objedn\u00e1vky",
        "platform_dashboard": "P\u0159ehled aplikace",
        "driver_dashboard": "P\u0159ehled pro \u0159idi\u010de",
        "company_dashboard": "Klientsk\u00fd p\u0159ehled",
        "fleet_manager_dashboard": "P\u0159ehled spr\u00e1vce flotily",
        "supplier_dashboard": "P\u0159ehled pro dodavatele",
        "site_manager_dashboard": "P\u0159ehled mana\u017eera parkovi\u0161t\u011b",
        "operator_dashboard": "P\u0159ehled oper\u00e1tora",
        "supplier_operator_dashboard": "P\u0159ehled dodavatele",
        "client_operator_dashboard": "P\u0159ehled klientsk\u00e9ho oper\u00e1tora",
        "platform_operator_dashboard": "P\u0159ehled oper\u00e1tora platformy",
        "platform_finance_dashboard": "P\u0159ehled financ\u00ed"
    },
    "date": "Datum",
    "date_first_issued": "Datum prvn\u00edho vyu\u017eit\u00ed",
    "date_first_used": "Datum prvn\u00edho vyu\u017eit\u00ed",
    "day": "Den",
    "delete": "Vymazat",
    "delete_current_client": "Smazat klienta?",
    "delete_payment_method": "Odstranit platebn\u00ed metodu?",
    "delete_selected_image": "Vymazat vybran\u00fd obr\u00e1zek?",
    "delete_selected_vat_record": "Vymazat vybran\u00fd z\u00e1znam DPH?",
    "description": "Popis",
    "did_not_receive_security_code": "Nep\u0159ijato, klikn\u011bte zde pro op\u011btovn\u00e9 odesl\u00e1n\u00ed",
    "disapprove": "Neschv\u00e1lit",
    "discount": "Sleva",
    "discount_type": "Druh slevy",
    "discount_value": "Hodnota slevy",
    "dismiss": "Zavrhnout",
    "dismiss_all": "Odm\u00edtnout v\u0161e",
    "dismiss_all_notification": "Zav\u0159\u00edt v\u0161echna ozn\u00e1men\u00ed?",
    "dismiss_this_notification": "Zav\u0159\u00edt toto ozn\u00e1men\u00ed?",
    "distance": "Vzd\u00e1lenost",
    "distance_units": "M\u011brn\u00e1 jednotka",
    "download_reports": "St\u00e1hnout p\u0159ehled",
    "download_sample_file": "St\u00e1hnout uk\u00e1zkov\u00fd soubor",
    "drawer_link_titles": {
        "dashboard": "P\u0159ehled",
        "users": "U\u017eivatel\u00e9",
        "vehicles": "Vozidla",
        "favourite_sites": "Obl\u00edben\u00e1 parkovi\u0161t\u011b",
        "settings": "Nastaven\u00ed",
        "need_help": "N\u00e1pov\u011bda?",
        "clients": "Klienti",
        "fleets": "Vozov\u00e9 parky",
        "suppliers": "Dodavatel\u00e9",
        "sites": "Parkovi\u0161t\u011b",
        "promotion": "Preference",
        "promotions": "Preference",
        "bookings": "Rezervace",
        "orders": "Objedn\u00e1vky",
        "reports": "Reporty",
        "find_site": "Naj\u00edt parkovi\u0161t\u011b",
        "scan_qr_code": "Naskenovat QR k\u00f3d",
        "my_history": "Moje historie"
    },
    "driver": "\u0158idi\u010d",
    "driver_checked_out_at": "\u0158idi\u010d byl odhl\u00e1\u0161en v {{checkedOutTime}}",
    "driver_have_been_checked_in": "\u0158idi\u010d byl p\u0159ihl\u00e1\u0161en",
    "driver_have_been_checked_out": "\u0158idi\u010d byl odhl\u00e1\u0161en",
    "driver_parking_period_expires_on": "Placen\u00e9 parkov\u00e1n\u00ed pro \u0159idi\u010de vypr\u0161\u00ed {{expireDate}}",
    "driver_settings": "Nastaven\u00ed pro \u0159idi\u010de",
    "drivers": "\u0158idi\u010d(i)",
    "drivers_imported": "Detaily k \u0159idi\u010d\u016fm byly vlo\u017eeny",
    "duration": "Doba trv\u00e1n\u00ed",
    "east": "V\u00fdchod",
    "east_short": "V",
    "edit": "Upravit",
    "edit_payment_method": "Upravit platebn\u00ed mo\u017enost",
    "email_address": "Emailov\u00e1 adresa",
    "email_notifications": "Emailov\u00e1 upozorn\u011bn\u00ed",
    "enter_card_holder_name": "Zadejte jm\u00e9no majitele platebn\u00ed karty",
    "enter_card_number": "Zadejte \u010d\u00edslo platebn\u00ed karty",
    "enter_email_address": "Zadejte email ",
    "enter_expire": "Zadejte datum expirace",
    "enter_message": "Vlo\u017eit zpr\u00e1vu",
    "enter_name": "Zdejte jm\u00e9no",
    "enter_order_id_manually": "Zadejte ru\u010dn\u011b \u010d\u00edslo objedn\u00e1vky",
    "enter_parking_site_name": "Zadejte n\u00e1zev parkovi\u0161t\u011b",
    "enter_parking_site_website": "Zadejte webovou str\u00e1nku parkovi\u0161t\u011b",
    "enter_phone_number": "Zadejte telefonn\u00ed \u010d\u00edslo",
    "enter_post_code": "Zadejte po\u0161tovn\u00ed sm\u011brovac\u00ed \u010d\u00edslo",
    "enter_postcode_town": "Zadejte po\u0161tovn\u00ed sm\u011brovac\u00ed \u010d\u00edslo \/ M\u011bsto",
    "enter_town_city": "Zadejte m\u011bsto",
    "enter_username": "Zadejte u\u017eivatelsk\u00e9 jm\u00e9no",
    "enter_your_security_code": "Zadejte V\u00e1\u0161 bezpe\u010dnostn\u00ed k\u00f3d ",
    "entity": "Struktura",
    "errors": {
        "error_401_message": "401 | NEOPR\u00c1VN\u011aN\u00dd PO\u017dADAVEK",
        "error_404_message": "404 | STR\u00c1NKA NENALEZENA",
        "error_0_message": "Bez internetov\u00e9ho p\u0159ipojen\u00ed",
        "page_error": "CHYBA STR\u00c1NKY!",
        "sorry_an_error_has_occurred_to_this_page": "Litujeme, tuto str\u00e1nku nelze na\u010d\u00edst",
        "you_are_not_authorized_to_access_the_page_you_requested": "Litujeme, nem\u00e1te opr\u00e1vn\u011bn\u00ed k p\u0159\u00edstupu na po\u017eadovanou str\u00e1nku",
        "page_not_available": "Omlouv\u00e1me se, str\u00e1nka, kterou hled\u00e1te, neexistuje, byla odstran\u011bna, n\u00e1zev byl zm\u011bn\u011bn nebo je do\u010dasn\u011b nedostupn\u00e1 "
    },
    "example": "P\u0159\u00edklad",
    "expire": "Platnost",
    "expires": "Platnost vypr\u0161\u00ed",
    "expiry_date": "Kone\u010dn\u00e9 datum",
    "export": "Exportovat ",
    "export_code": "Exportovat QR k\u00f3d",
    "export_payouts": "Exportn\u00ed v\u00fddaje",
    "export_payouts_file_format": "Form\u00e1t souboru exportn\u00edch v\u00fddaj\u016f",
    "extend_booking": "Prodlou\u017eit rezervaci",
    "facilities": "Za\u0159\u00edzen\u00ed",
    "filter_by_site_name": "Filtrovat podle n\u00e1zvu parkovi\u0161t\u011b",
    "filters_labels": {
        "show_advance_filters": "Zobrazit pokro\u010dit\u00e9 filtry",
        "hide_advance_filters": "Skr\u00fdt pokro\u010dil\u00e9 filtry",
        "filter_by_creation_date": "Filtrovat podle data vytvo\u0159en\u00ed \u00fa\u010dtu",
        "filter_by_country": "Filtrovat podle st\u00e1tu",
        "filter_by_region": "Filtrovat podle oblasti",
        "filter_by_account_status": "Filtrovat podle stavu \u00fa\u010dtu",
        "search_for_email_address": "Hledat emailovou adresu",
        "filter_by_company_name": "Filtrovat podle n\u00e1zvu spole\u010dnosti",
        "filter_by_site": "Filtrovat podle parkovi\u0161t\u011b",
        "filter_by_user": "Filtrovat podle u\u017eivatele",
        "filter_by_client": "Filtrovat podle klienta",
        "filter_by_suppliers": "Filtrovat podle dodavatele",
        "filter_by_fleet": "Filtrovat podle vozov\u00e9ho parku",
        "filter_by_product": "Filtrovat podle produktu",
        "start_date": "Datum p\u0159\u00edjezdu",
        "end_date": "Datum odjezdu ",
        "filter_by_primary_contact_name": "Filtrovat podle jm\u00e9na hlavn\u00edho kontaktu",
        "credit_status": "Stav kreditu",
        "between_these_dates": "V obdob\u00ed mezi daty",
        "company_name": "Jm\u00e9no spole\u010dnosti",
        "show_promotion": "Zobrazit mo\u017enosti?",
        "active_date": "Aktu\u00e1ln\u00ed datum",
        "expire_date": "Datum expirace",
        "order_date": "Datum objedn\u00e1vky",
        "due_date": "Datum splatnosti",
        "balance": "Balance",
        "figures": "\u010c\u00e1stky",
        "any": "\u017d\u00e1dn\u00fd",
        "no_min": "\u017d\u00e1dn\u00e9 minimum",
        "no_max": "\u017d\u00e1dn\u00e9 maximum",
        "show_filters": "Zobrazit filtry",
        "apply": "Pou\u017e\u00edt filtry"
    },
    "find_site": "Naj\u00edt parkovi\u0161t\u011b",
    "find_site_to_book": "Naj\u00edt parkovi\u0161t\u011b k rezervaci",
    "find_site_with_promotion_to_book": "Naj\u00edt parkovi\u0161t\u011b s preferencemi",
    "find_sites_with_promotions": "Naj\u00edt parkovi\u0161t\u011b s preferencemi",
    "find_user": "Naj\u00edt u\u017eivatele",
    "first_line_address": "Adresa",
    "first_line_billing_address": "Faktura\u010dn\u00ed adresa",
    "first_name": "K\u0159estn\u00ed jm\u00e9no",
    "fleet": "Vozov\u00fd park",
    "fleet_name": "N\u00e1zev vozov\u00e9ho parku",
    "fleet_name_company": "Klient | N\u00e1zev vozov\u00e9ho parku",
    "fleet_spending_caps": "V\u00fdchoz\u00ed v\u00fddajov\u00fd limit vozov\u00e9ho parku",
    "fleets": "Flotila(y)",
    "fleets_imported": "Detaily k vozov\u00fdm park\u016fm byly vlo\u017eeny",
    "forgot_password": "Zapomn\u011bli jste Va\u0161e heslo?",
    "form_validation": {
        "is_required": "{{attribute}} je po\u017eadov\u00e1no",
        "min": "{{attribute}} mus\u00ed b\u00fdt v\u011bt\u0161\u00ed nebo rovno {{value}}",
        "invalid_email_address": "Zadejte pros\u00edm platnou emailovou adresu",
        "password_invalid_min_length": "Heslo nesm\u00ed obsahovat m\u00e9n\u011b ne\u017e {{length}} znak\u016f",
        "code_invalid_min_length": "K\u00f3d nesm\u00ed obsahovat m\u00e9n\u011b ne\u017e {{length}} \u010d\u00edsel",
        "code_invalid_max_length": "K\u00f3d nesm\u00ed obsahovat v\u00edce ne\u017e {{length}} \u010d\u00edsel",
        "password_is_required": "Zadejte heslo",
        "code_is_required": "Zadejte k\u00f3d",
        "password_is_must_match": "Heslo se mus\u00ed shodovat",
        "password_confirm_required": "Potvr\u010fte zadan\u00e9 heslo",
        "email_address_is_required": "Zadejte emailovou adresu",
        "name_is_required": "Zadejte jm\u00e9no",
        "username_is_required": "Zadejte u\u017eivatelsk\u00e9 jm\u00e9no",
        "user_name_is_required": "Zadejte u\u017eivatelsk\u00e9 jm\u00e9no",
        "phone_number_is_required": "Zadejte telefonn\u00ed \u010d\u00edslo",
        "user_type_is_required": "Typ u\u017eivatele",
        "site_is_required": "Parkovi\u0161t\u011b",
        "message_is_required": "Pozn\u00e1mka",
        "product_is_required": "Produkt",
        "discount_is_required": "Sleva",
        "start_date_is_required": "Datum p\u0159\u00edjezdu",
        "expiry_date_is_required": "Datum odjezdu ",
        "expiry_date_min": "Datum vypr\u0161en\u00ed platnosti nem\u016f\u017ee b\u00fdt krat\u0161\u00ed ne\u017e aktu\u00e1ln\u00ed datum",
        "call_to_action_required": "Potvr\u010fte v\u00fdb\u011br",
        "link_to_action_required": "Odkaz na webov\u00e9 str\u00e1nky",
        "site_name_is_required": "N\u00e1zev parkovi\u0161t\u011b",
        "description_is_required": "Popis parkovi\u0161t\u011b",
        "total_spaces_available_is_required": "Po\u010det dostupn\u00fdch parkovac\u00edch m\u00edst ",
        "site_currency_is_required": "M\u011bna",
        "supplier_is_required": "Dodavetel",
        "longitude_is_required": "Zem\u011bpisn\u00e1 d\u00e9lka",
        "latitude_is_required": "Zem\u011bpisn\u00e1 \u0161\u00ed\u0159ka",
        "address_is_required": "Adresa",
        "address_line_2_is_required": "Adresa",
        "city_town_is_required": "M\u011bsto",
        "country_is_required": "St\u00e1t",
        "county_is_required": "Okrsek",
        "post_code_is_required": "Sm\u011brovac\u00ed \u010d\u00edslo",
        "parking_price_is_required": "Cena parkovn\u00e9ho ",
        "parking_price_period_value_is_required": "\u010casov\u00fd \u00fasek",
        "parking_price_per_period_type_is_required": "Cena za \u010dasov\u00fd \u00fasek ",
        "parking_price_up_to_value_is_required": "Zadejte maxim\u00e1ln\u00ed hodnotu za mno\u017estv\u00ed",
        "parking_price_up_to_period_is_required": "Zadejte maxim\u00e1ln\u00ed hodnotu za \u010dasov\u00fd \u00fasek",
        "category_is_required": "Kategorie ",
        "price_is_required": "Cena",
        "commission_rate_type_is_required": "Typ provize",
        "commission_value_is_required": "Provize",
        "vehicle_registration_number_is_required": "Registra\u010dn\u00ed zna\u010dka vozidla",
        "primary_contact_name_is_required": "Kontaktn\u00ed jm\u00e9no",
        "contact_name_is_required": "Kontaktn\u00ed jm\u00e9no",
        "account_status_is_required": "Stav \u00fa\u010dtu",
        "account_number_is_required": "\u010c\u00edslo \u00fa\u010dtu",
        "currency_is_required": "M\u011bna",
        "account_type_is_required": "Typ \u00fa\u010dtu",
        "vat_registration_number_required": "DI\u010c",
        "client_credit_limit_required": "Je vy\u017eadov\u00e1n kreditn\u00ed limit",
        "client_payment_terms_required": "Platebn\u00ed podm\u00ednky",
        "client_payment_terms_days_required": "Platebn\u00ed podm\u00ednky",
        "expire_date_is_required": "Datum expirace",
        "security_code_is_required": "Bezpe\u010dnostn\u00ed k\u00f3d",
        "card_number_is_required": "\u010c\u00edslo karty",
        "invalid_user_email": "Nebyl nalezen \u017e\u00e1dn\u00fd u\u017eivatel s t\u00edmto e-mailem",
        "account_number_required": "\u010c\u00edslo \u00fa\u010dtu",
        "order_id_is_required": "ID objedn\u00e1vky",
        "payment_date_is_required": "Datum platby",
        "amount_is_required": "\u010castka",
        "ccv_is_required": "CCV K\u00f3d",
        "discount_type_is_required": "Typ slevy",
        "company_is_required": "Klient",
        "parking_limit_reached": "T\u00edmto p\u0159ekro\u010d\u00edte V\u00e1\u0161 limit na parkov\u00e1n\u00ed ",
        "credit_limit_reached": "T\u00edmto p\u0159ekro\u010d\u00edte kreditn\u00ed limit Va\u0161eho \u00fa\u010dtu",
        "invalid_card_number": "\u010c\u00edslo karty je neplatn\u00e9",
        "invalid_expire": "Platnost karty vypr\u0161ela",
        "invalid_ccv": "CCV je neplatn\u00e9",
        "invalid_qr_code": "Naskenujte pros\u00edm platn\u00fd M-Park QR k\u00f3d ",
        "addons_required_without_parking": "Proto\u017ee neparkujete, mus\u00edte vybrat alespo\u0148 jeden dopln\u011bk ",
        "booking": {
            "invalid_instant_order_token": "Va\u0161e relace vyp\u0159\u0161ela, pros\u00edm naskenujte znovu QR k\u00f3d",
            "invalid_location": "Pro naskenov\u00e1n\u00ed QR k\u00f3du mus\u00edte b\u00fdt na m\u00edst\u011b"
        }
    },
    "formats": {
        "date": "Do MMM YY",
        "date_long": "dddd Do MMMM YYYY",
        "datetime": "Do MMM YY HH:mm",
        "short_datetime": "DD/MM/YY HH:mm",
        "short_datetime_reverse": "HH:mm - DD/MM/YYYY",
        "time": "HH:mm:ss",
        "full_date": "D MMMM, YYYY",
        "full_date_and_time": "D MMMM, YYYY HH:mm:ss",
        "short_date": "DD/MM/YYYY",
    },
    "free": "Zdarma",
    "from": "Od",
    "full_period": "Cel\u00e9 obdob\u00ed",
    "get_directions": "Navigovat",
    "global_commission": "Celkov\u00e1 provize",
    "go_to_booking": "P\u0159ejd\u011bte na rezervaci",
    "go_to_clients": "P\u0159ejd\u011bte na klienty",
    "go_to_dashboard": "Zp\u011bt do p\u0159ehledu",
    "go_to_fleet": "P\u0159ejd\u011bte na vozov\u00fd park",
    "go_to_fleets": "P\u0159ejd\u011bte na vozov\u00e9 parky",
    "go_to_promotions": "P\u0159ejd\u011bte na preference",
    "go_to_sites": "P\u0159ejd\u011bte na parkovi\u0161t\u011b",
    "go_to_suppliers": "P\u0159ejd\u011bte na dodavatele",
    "go_to_users": "P\u0159ejd\u011bte na u\u017eivatele",
    "go_to_vehicles": "P\u0159ejd\u011bte na Vozidla",
    "got_to_settings": "Zp\u011bt do nastaven\u00ed ",
    "gr_code_location_error": "Nepoda\u0159ilo se n\u00e1m zjistit Va\u0161i polohu. Povolte pros\u00edm ur\u010dov\u00e1n\u00ed polohy, abyste mohli skenovat QR k\u00f3d",
    "grand_total": "Celkov\u00fd sou\u010det",
    "help_message_receive": "D\u011bkujeme, \u017ee jste n\u00e1s kontaktovali, Va\u0161e zpr\u00e1va byla p\u0159ijata",
    "home": "Dom\u016f",
    "hour": "Hodina",
    "id": "Unik\u00e1tn\u00ed k\u00f3d",
    "images": "Fotky",
    "import": "Vlo\u017eit",
    "import_clients": "Vlo\u017eit klienty",
    "import_drivers": "Vlo\u017eit \u0159idi\u010de",
    "import_fleets": "Vlo\u017eit vozov\u00e9 parky",
    "import_payments": "Vlo\u017eit platby",
    "import_payouts": "Vlo\u017eit v\u00fddaje",
    "import_promotions": "Vlo\u017eit mo\u017enosti",
    "import_sites": "Vlo\u017eit parkovi\u0161t\u011b",
    "import_suppliers": "Vlo\u017eit dodavatele",
    "import_users": "Vlo\u017eit u\u017eivatele",
    "import_vehicles": "Vlo\u017eit vozidla",
    "inactive": "Neaktivn\u00ed",
    "incl_vat": "V\u010detn\u011b DPH",
    "instant_order": "Rezervovat nyn\u00ed",
    "journey_time": "Doba trv\u00e1n\u00ed cesty",
    "last_name": "P\u0159\u00edjmen\u00ed",
    "last_ten_orders": "Posledn\u00edch 10 objedn\u00e1vek",
    "last_updated": "Posledn\u00ed aktualizace",
    "latitude": "Zem\u011bpisn\u00e1 \u0161\u00ed\u0159ka",
    "leave_feedback": "Zanechat zp\u011btnou vazbu",
    "link": "Odkaz",
    "list_of_site_names_separated_by_comma": "Seznam n\u00e1zv\u016f parkovi\u0161\u0165 by m\u011bl b\u00fdt odd\u011blen \u010d\u00e1rkou",
    "list_of_vehicles_registration_numbers_separated_by_comma": "Seznam registra\u010dn\u00edch zna\u010dek vozidel by m\u011bl b\u00fdt odd\u011blen \u010d\u00e1rkou, pokud je u\u017eivatelem \u0159idi\u010d",
    "list_of_vehicles_registration_numbers_separated_by_comma_driver": "Seznam registra\u010dn\u00edch zna\u010dek vozidel by m\u011bl b\u00fdt odd\u011blen \u010d\u00e1rkou",
    "loading": "Na\u010d\u00edt\u00e1n\u00ed\u2026",
    "location": "Lokace",
    "login": "P\u0159ihl\u00e1\u0161en\u00ed",
    "logout": "Odhl\u00e1sit se",
    "longitude": "Zem\u011bpisn\u00e1 d\u00e9lka",
    "longitude_latitude": "Zem\u011bpisn\u00e1 d\u00e9lka\/ Zem\u011bpisn\u00e1 \u0161\u00ed\u0159ka",
    "manager": "Mana\u017eer",
    "max_journey_duration": "Maxim\u00e1ln\u00ed doba trv\u00e1n\u00ed cesty",
    "message": "Zpr\u00e1va",
    "messages": {
        "user_updated": "U\u017eivatel byl aktualizov\u00e1n",
        "user_created": "U\u017eivatel byl vytvo\u0159en"
    },
    "month": "M\u011bs\u00edc",
    "monthly": "M\u011bs\u00ed\u010dn\u011b",
    "more_information_about_the_site": "V\u00edce informac\u00ed o parkovi\u0161ti",
    "more_than_500_spaces": "V\u00edce ne\u017e 500 m\u00edst",
    "more_than_hours": "V\u00edce ne\u017e {{hours}} hodin",
    "more_than_two_hours": "V\u00edce ne\u017e 2 hodiny",
    "mpark_credit": "M-Park Kredit",
    "mpark_credit_account": "M-Park Kredit",
    "mpark_revenue": "P\u0159\u00edjmy M-Park",
    "n_days": "1 den",
    "n_days_plural": "{{count}} dn\u00ed",
    "n_hours": "1 hodina",
    "n_hours_plural": "{{count}} hodin",
    "name": "Jm\u00e9no",
    "name_on_the_card": "Jm\u00e9no na platebn\u00ed kart\u011b",
    "net_amount": "Z\u00e1kladn\u00ed cena",
    "new_client": "P\u0159idat nov\u00e9ho klienta",
    "new_fleet": "P\u0159idat nov\u00fd vozov\u00fd park",
    "new_promotion": "P\u0159idat mo\u017enosti",
    "new_site": "P\u0159idat nov\u00e9 parkovi\u0161t\u011b",
    "new_supplier": "P\u0159idat nov\u00e9ho dodavatele",
    "new_user": "Nov\u00fd u\u017eivatel",
    "new_vat_rate": "Nov\u00e1 sazba DPH",
    "new_vehicle": "P\u0159idat nov\u00e9 vozidlo",
    "no": "Ne",
    "no_data": "\u017d\u00e1dn\u00e1 data",
    "no_new_notification": "\u017d\u00e1dn\u00e9 nov\u00e9 ozn\u00e1men\u00ed",
    "no_orders_available": "Nejsou k dispozici \u017e\u00e1dn\u00e9 objedn\u00e1vky",
    "no_parking_prices_defined": "Na tomto parkovi\u0161ti nejsou definov\u00e1ny \u017e\u00e1dn\u00e9 ceny za parkov\u00e1n\u00ed",
    "no_payment_method_found": "Neplatn\u00e1 platebn\u00ed metoda",
    "no_report_data": "\u017d\u00e1dn\u00e1 data p\u0159ehledu",
    "no_sites_found_with_selected_filters": "Nebyla nalezena \u017e\u00e1dn\u00e1 parkovi\u0161t\u011b s vybran\u00fdmi filtry",
    "none": "\u017d\u00e1dn\u00fd",
    "north": "Sever",
    "north_east": "Severov\u00fdchod",
    "north_east_short": "SV",
    "north_short": "S",
    "north_west": "Severoz\u00e1pad",
    "north_west_short": "SZ",
    "not_payouts_found_for_today": "Dnes nebyly nalezeny \u017e\u00e1dn\u00e9 platby",
    "notes": "Pozn\u00e1mky",
    "notifications": "Ozn\u00e1men\u00ed",
    "notifications_types": {
        "BookingConfirmed": {
            "header": "Rezervace schv\u00e1lena",
            "body": "Va\u0161e rezervace #{{bookingId}} byla schv\u00e1lena na parkovi\u0161ti {{siteName}} na {{bookingTime}}"
        },
        "BookingRejected": {
            "header": "Rezervace zam\u00edtnuta",
            "body": "Va\u0161e rezervace #{{bookingId}} byla odm\u00edtnuta na parkovi\u0161ti {{siteName}}. Pros\u00edm upravte va\u0161i rezervaci nebo n\u00e1s kontaktujte"
        },
        "BookingCheckedIn": {
            "header": "P\u0159ihl\u00e1\u0161en",
            "body": "P\u0159ihl\u00e1sili jste se na adrese {{siteName}} ve sv\u00e9 rezervaci #{{bookingId}} v {{bookingTime}}"
        },
        "BookingCheckedOut": {
            "header": "Odhl\u00e1\u0161en",
            "body": "Odhl\u00e1sili jste se na adrese {{siteName}} ve sv\u00e9 rezervaci #{{bookingId}} v {{bookingTime}}"
        },
        "BookingRequested": {
            "header": "Nov\u00e1 rezervace",
            "body": "Nov\u00e1 rezervace #{{bookingId}} byla vytvo\u0159ena na {{siteName}} na {{bookingTime}}"
        }
    },
    "notify_new_orders": "Nov\u00e9 objedn\u00e1vky",
    "notify_orders_status": "Aktualizace stavu objedn\u00e1vky",
    "now": "Nyn\u00ed",
    "number_of_bookings": "Po\u010det rezervac\u00ed",
    "number_of_drivers": "Po\u010det \u0159idi\u010d\u016f",
    "number_of_vehicles": "Po\u010det vozidel",
    "of_preposition": "{{item1}} z {{item2}}",
    "okay": "OK",
    "on_account": "Na \u00fa\u010det",
    "on_card": "Na kart\u011b",
    "only_show_sales": "Zobrazit pouze prodeje?",
    "open_24_hours": "Otev\u0159eno 24 hodin",
    "options": "Mo\u017enosti",
    "order_approved": "Objedn\u00e1vka schv\u00e1lena",
    "order_canceled": "Objedn\u00e1vka zru\u0161ena!",
    "order_declined": "Objedn\u00e1vka odm\u00edtnuta",
    "order_has_been_updated": "Objedn\u00e1vka byla aktualizov\u00e1na",
    "order_id": "\u010c\u00edslo objedn\u00e1vky",
    "order_items": "Objednat polo\u017eky",
    "order_received": "Objedn\u00e1vka byla obdr\u017eena ",
    "order_status": {
        "pending": "\u010cek\u00e1 na schv\u00e1len\u00ed",
        "paid": "Uhrazeno",
        "approved": "Schv\u00e1leno",
        "completed": "Dokon\u010deno",
        "rejected": "Zam\u00edtnuto",
        "started": "Zah\u00e1jeno",
        "canceled": "Zru\u0161eno",
        "cancelled": "Zru\u0161eno"
    },
    "order_success_waiting_approval": "Po\u010dkejte, pros\u00edm, ne\u017e {{siteName}} schv\u00e1l\u00ed Va\u0161i objedn\u00e1vku. Nev\u00e1hejte opustit tuto str\u00e1nku a my V\u00e1s upozorn\u00edme, jakmile bude aktualizov\u00e1no.",
    "order_summary": "P\u0159ehled objedn\u00e1vky",
    "order_summary_and_checkout": "Shrnut\u00ed objedn\u00e1vky a pokladna",
    "order_updated": "Objedn\u00e1vka byla aktualizov\u00e1na",
    "orders": {
        "recent_orders": "Ned\u00e1vn\u00e9 objedn\u00e1vky",
        "view_all_orders": "Zobrazit v\u0161echny objedn\u00e1vky",
        "order_reference": "Reference objedn\u00e1vky",
        "last_ten_orders": "Posledn\u00edch 10 objedn\u00e1vek"
    },
    "over_cap": "P\u0159ekro\u010den\u00ed limitu",
    "paid": "Uhrazeno",
    "parking_available": "Mo\u017enost parkov\u00e1n\u00ed?",
    "parking_available_described": "Mo\u017enost parkov\u00e1n\u00ed (A\/N)",
    "parking_booking_question": "Pot\u0159ebujete parkov\u00e1n\u00ed?",
    "parking_hourly_price": "Parkov\u00e1n\u00ed za hodinovou sazbu",
    "parking_price": "Cena za parkov\u00e1n\u00ed",
    "parking_price_configuration_note": "Vezm\u011bte pros\u00edm na v\u011bdom\u00ed, \u017ee je pou\u017eita nejni\u017e\u0161\u00ed mo\u017en\u00e1 cena a \u010dasov\u00fd \u00fasek bude p\u0159i\u0159azen k nejbli\u017e\u0161\u00edmu mo\u017en\u00e9mu \u010dasov\u00e9mu obdob\u00ed",
    "parking_prices": "Ceny parkov\u00e1n\u00ed (bez DPH)",
    "parking_prices_vat": "Ceny parkovn\u00e9ho (v\u010detn\u011b DPH)",
    "parking_site_name": "N\u00e1zev parkovi\u0161t\u011b",
    "parking_site_website": "Webov\u00e1 str\u00e1nka parkovi\u0161t\u011b",
    "parking_specific": "Vztahuj\u00edc\u00ed se k\u00a0parkov\u00e1n\u00ed ",
    "parking_with_trailer": "Pot\u0159ebujete m\u00edsto pro n\u00e1v\u011bs?",
    "password": "Heslo",
    "password_is_reset": "Va\u0161e heslo bylo zm\u011bn\u011bno",
    "payment": "Platba",
    "payment_date": "Datum splatnosti",
    "payment_details": "Platebn\u00ed detaily",
    "payment_method": "Zp\u016fsob platby",
    "payment_methods": "Platebn\u00ed mo\u017enosti",
    "payment_terms_days": "Splatnost klienta",
    "payments_imported": "Detaily k platb\u00e1m byly vlo\u017eeny",
    "payout": "Platba",
    "payout_saved": "Platba byla ulo\u017eena!",
    "payouts_imported": "Dateily k v\u00fddaj\u016fm byly vlo\u017eeny",
    "percentage": "Procento",
    "period_per": "Obdob\u00ed (za)",
    "period_value": "\u010casov\u00e9 obdob\u00ed",
    "phone_number": "Telefonn\u00ed \u010d\u00edslo",
    "place_order": "Vytvo\u0159it objedn\u00e1vku",
    "please_enter_a_valid_code": "Zadejte pros\u00edm platn\u00fd k\u00f3d",
    "please_set_up_a_valid_payment_method": "Zadejte pros\u00edm platnou platebn\u00ed metodu",
    "please_set_up_a_valid_payment_method_for_your_fleet": "Obra\u0165te se pros\u00edm na sv\u00e9ho mana\u017eera pro p\u0159id\u00e1n\u00ed platn\u00e9 platebn\u00ed metody do Va\u0161eho vozov\u00e9ho parku",
    "please_wait": "Vy\u010dkejte pros\u00edm\u2026",
    "post_code": "Po\u0161tovn\u00ed sm\u011brovac\u00ed \u010d\u00edslo",
    "postcode_town": "Po\u0161tovn\u00ed sm\u011brovac\u00ed \u010d\u00edslo \/ M\u011bsto",
    "prebookable": "Mo\u017enost rezervace p\u0159edem?",
    "prebookable_facility": "Mo\u017enost rezervace p\u0159edem",
    "present_qr_code_for_payment": "Pro platbu pros\u00edm p\u0159edlo\u017ete QR k\u00f3d",
    "price": "Cena",
    "price_per_twelve_hours": "{{price}} {{currency}} za 12 hodin",
    "price_range": "Cenov\u00e9 rozp\u011bt\u00ed",
    "price_range_between": "za 12 hours - {{min}} a {{max}}",
    "primary_contact": "Hlavn\u00ed kontakt",
    "product": "Produkt",
    "product_category": "Kategorie produktu",
    "profile": "Profil",
    "promotion_cant_expire_before_it_is_started": "Platnost ak\u010dn\u00ed nab\u00eddky nem\u016f\u017ee vypr\u0161et p\u0159ed zah\u00e1jen\u00edm",
    "promotion_message": "Reklamn\u00ed sd\u011blen\u00ed",
    "promotions_available": "Dostupn\u00e9 preference",
    "promotions_imported": "Detaily k preferenc\u00edm byly vlo\u017eeny",
    "provide_email_address_to_sent_reset_request": "Zadejte pros\u00edm Va\u0161i emailovou adresu pro resetov\u00e1n\u00ed hesla",
    "quantity": "Mno\u017estv\u00ed",
    "rate_base_currency": "Sazba za z\u00e1kladn\u00ed m\u011bnu ({{baseCurrency}})",
    "rebook": "Zm\u011bnit objedn\u00e1vku",
    "receive_marketing_emails": "P\u0159ejete si zas\u00edlat marketingov\u00e1 sd\u011blen\u00ed?",
    "record_deleted": "Z\u00e1znam byl archivov\u00e1n",
    "reference": "Reference",
    "region": "Oblast",
    "region_country": "Region",
    "region_specific": "Detaily k oblasti",
    "region_spending_cap_instructions": "Jsou-li zad\u00e1ny krit\u00e9tia pro regiony, objedn\u00e1vky pro parkovi\u0161t\u011b mimo zadan\u00fd region nebudou zahrnuty",
    "registered_address": "Registrovan\u00e1 adresa",
    "registration": "Registrace",
    "reject": "Odm\u00edtnout",
    "remaining": "Zb\u00fdvaj\u00edc\u00ed",
    "remove_all_filters": "Odstranit v\u0161echny filtry",
    "remove_favourite": "Odstranit z obl\u00edben\u00fdch",
    "repeat_password": "Zopakujte heslo",
    "request_order": "Objednat",
    "reset": "Resetovat",
    "reset_link_sent": "Zaslali jsme V\u00e1m odkaz pro resetov\u00e1n\u00ed hesla",
    "reset_parking_pricing_for_this_site_client": "Resetovat ceny parkovn\u00e9ho u tohoto parkovi\u0161t\u011b a klienta",
    "reset_password": "Resetovat heslo",
    "reset_to_default_pricing": "Obnovit v\u00fdchoz\u00ed cen\u00edk",
    "role": "\u00daloha",
    "roles": {
        "platform_admin": "Spr\u00e1vce",
        "platform_finance": "Finance",
        "supplier_admin": "Spr\u00e1vce dodavatele",
        "site_manager": "Mana\u017eer parkovi\u0161t\u011b",
        "fleet_manager": "Mana\u017eer vozov\u00e9ho parku",
        "operator": "Oper\u00e1tor",
        "client_admin": "Spr\u00e1vce klienta",
        "driver": "\u0158idi\u010d",
        "client_operator": "Oper\u00e1tor klienta",
        "supplier_operator": "Oper\u00e1tor dodavatele",
        "platform_operator": "Oper\u00e1tor platformy"
    },
    "save_client_pricing": "Ulo\u017eit cen\u00edk pro klienty",
    "save_new_client": "P\u0159idat nov\u00e9ho klienta",
    "save_new_fleet": "P\u0159idat nov\u00fd vozov\u00fd park",
    "save_new_promotion": "P\u0159idat mo\u017enosti",
    "save_new_site": "P\u0159idat nov\u00e9 parkovi\u0161t\u011b",
    "save_new_supplier": "P\u0159idat nov\u00e9ho dodavatele",
    "save_new_user": "Nov\u00fd u\u017eivatel",
    "save_new_vehicle": "P\u0159idat nov\u00e9 vozidlo",
    "save_payment_method": "Ulo\u017eit platebn\u00ed mo\u017enost",
    "save_payout": "Ulo\u017eit platbu",
    "search": "Hledat ",
    "search_for_anything": "Hledat",
    "search_placeholder": "Hledat\u2026",
    "second_line_address": "Adresa",
    "security_facilities": "Bezpe\u010dnostn\u00ed za\u0159\u00edzen\u00ed",
    "security_rating": "Hodnocen\u00ed bezpe\u010dnosti",
    "security_rating_described": "Bezpe\u010dnostn\u00ed hodnocen\u00ed TAPA (1-5)",
    "select_vehicle": "Za\u010dn\u011bte v\u00fdb\u011brem sv\u00e9ho vozidla",
    "send_email": "Odeslat email",
    "service": "Slu\u017eba",
    "share_app_on_social_media": "Sd\u00edlejte aplikaci s p\u0159\u00e1teli na soci\u00e1ln\u00edch s\u00edt\u00edch",
    "share_location": "Sd\u00edlet polohu?",
    "show_all": "Zobrazit v\u0161e",
    "show_only": "Zobrazit pouze {{subject}}",
    "show_order_details": "Zobrazit podrobnosti objedn\u00e1vky",
    "show_qr_code": "Zobrazit QR k\u00f3d",
    "sign_up": "Registrace",
    "site": "Parkovi\u0161t\u011b",
    "site_created": "Str\u00e1nka vytvo\u0159ena",
    "site_currency": "M\u011bna v m\u00edst\u011b parkov\u00e1n\u00ed",
    "site_description": "Popis za\u0159\u00edzen\u00ed",
    "site_feedback": "Zp\u011btn\u00e1 vazba k parkovi\u0161ti",
    "site_feedback_sent": "Zp\u011btn\u00e1 vazba k parkovi\u0161ti odesl\u00e1na!",
    "site_fees": "Poplatky za parkovi\u0161t\u011b",
    "site_id": "Identifik\u00e1tor parkovi\u0161t\u011b",
    "site_name": "Jm\u00e9no parkovi\u0161t\u011b",
    "site_pricing": "Cen\u00edk parkovi\u0161t\u011b",
    "site_size": "Kapacita parkovi\u0161t\u011b",
    "site_specific": "Detaily k parkovi\u0161ti",
    "site_spending_cap_instructions": "Na uveden\u00fdch parkovi\u0161t\u00edch bude omezeno",
    "site_updated": "Str\u00e1nka aktualizov\u00e1na",
    "sites": "Parkovi\u0161t\u011b",
    "sites_found_with_current_filters": "{{sites}} parkovi\u0161\u0165 bylo nalezeno s pou\u017eit\u00edm aktu\u00e1ln\u00edho filtru ",
    "sites_imported": "Detaily k parkovi\u0161t\u00edm byly vlo\u017eeny",
    "south": "Jih",
    "south_east": "Jihov\u00fdchod",
    "south_east_short": "JV",
    "south_short": "J",
    "south_west": "Jihoz\u00e1pad",
    "south_west_short": "JZ",
    "spaces_available": "Celkov\u00fd po\u010det voln\u00fdch m\u00edst",
    "spaces_available_display": "{{spaces}} voln\u00fdch m\u00edst k dispozici",
    "spending_cap": "V\u00fddajov\u00fd strop",
    "spending_caps": "V\u00fddajov\u00fd limit",
    "spending_caps_addon_not_defined_warning": "*Dopl\u0148kov\u00e9 slu\u017eby, kter\u00e9 nejsou uvedeny ve v\u00fddajov\u00fdch stropech nebudou spole\u010dnost\u00ed hrazeny ",
    "start_date": "Po\u010d\u00e1te\u010dn\u00ed datum",
    "state_code": "K\u00f3d st\u00e1tu",
    "state_code_placeholder": "ISO-3166-2 i.e. GB-ENG",
    "status": "Stav",
    "steps": "({{current}} z {{steps}})",
    "stopped": "P\u0159eru\u0161eno",
    "submit": "Povrdit",
    "subtotal": "Mezisou\u010det",
    "success_dialog": {
        "success": "\u00dasp\u011b\u0161n\u011b zpracov\u00e1no!",
        "error": "Chyba!",
        "fleet_setting_updated": "Nastaven\u00ed vozov\u00e9ho parku bylo aktualizov\u00e1no",
        "driver_setting_updated": "Nastaven\u00ed bylo aktualizov\u00e1no",
        "site_suggestion_sent": "N\u00e1vrh na parkovi\u0161t\u011b odesl\u00e1n",
        "client_created": "Klient vytvo\u0159en!",
        "client_updated": "Klient aktualizov\u00e1n!",
        "fleet_created": "Vozov\u00fd park vytvo\u0159en!",
        "fleet_updated": "Vozov\u00fd park aktualizov\u00e1n!",
        "vehicle_created": "Vozidlo vytvo\u0159eno!",
        "vehicle_updated": "Vozidlo aktualizov\u00e1no!",
        "supplier_created": "Dodavatel vytvo\u0159en!",
        "supplier_updated": "Dodavatel aktualizov\u00e1n!",
        "promotion_created": "Preference vytvo\u0159eny!",
        "promotion_updated": "Preference aktualizov\u00e1ny!"
    },
    "suggest_a_parking_site": "Doporu\u010dit parkovac\u00ed m\u00edsto",
    "supplier": "Dodavatel ",
    "supplier_payment_terms": "Platebn\u00ed podm\u00ednky dodavatele",
    "supplier_payment_terms_days": "Splatnost dodavatele",
    "supplier_site": "Dodavatel | Parkovi\u0161t\u011b",
    "suppliers_imported": "Detaily k dodavatel\u016fm byly vlo\u017eeny",
    "switch_to_list_view": "Zobrazen\u00ed seznamu",
    "switch_to_map_view": "Zobrazen\u00ed mapy",
    "tapa_rating": "TAPA hodnocen\u00ed",
    "tapa_rating_display": "{{rating}} hodnocen\u00ed TAPA ",
    "terms_condition": "Obchodn\u00ed podm\u00ednky",
    "this_order_has_been_completed_on": "Tato objedn\u00e1vka byla dokon\u010dena v {{time}}",
    "this_order_has_been_rejected": "Tato objedn\u00e1vka byla zam\u00edtnuta",
    "this_order_is_not_approved_yet": "Tato objedn\u00e1vka zat\u00edm nebyla schv\u00e1lena",
    "to": "Do",
    "total": "Celkem",
    "total_addons": "Dop\u0148uj\u00edc\u00ed slu\u017eby celkem",
    "total_inc_vat": "Celkem (v\u010detn\u011b DPH)",
    "total_parking": "Parkovn\u00e9 celkem",
    "total_revenue": "Celkov\u00e9 p\u0159\u00edjmy",
    "total_spaces": "Celkov\u00fd po\u010det m\u00edst",
    "total_to_pay_on_card": "Celkem k platb\u011b kartou",
    "town_city": "M\u011bsto",
    "trailer": "N\u00e1v\u011bs",
    "transactions": "Transakce",
    "truck_park": "Parkovi\u0161t\u011b n\u00e1kladn\u00edch vozidel",
    "twelve_hours": "12 hodin",
    "type": "Typ",
    "type_of_user": "Typ u\u017eivatele",
    "uncertified": "Neov\u011b\u0159en\u00e9",
    "unlimited": "Neomezen\u011b",
    "unpaid": "Neuhrazeno",
    "up_to_500_spaces": "A\u017e 500 m\u00edst",
    "up_to_hours": "A\u017e do {{hours}} hodin",
    "up_to_one_hour": "A\u017e 1 hodina",
    "up_to_two_hours": "A\u017e 2 hodiny",
    "update": "Aktualizovat",
    "update_client": "Aktualizovat klienta",
    "update_exchange_rates": "Aktualizovat sm\u011bnn\u00e9 kurzy",
    "update_fleet": "Aktualizovat vozov\u00fd park",
    "update_fleet_settings": "Aktualizovat nastaven\u00ed vozov\u00e9ho parku",
    "update_promotion": "Aktualizovat preference",
    "update_settings": "Aktualizovat nastaven\u00ed",
    "update_site": "Aktualizovat parkovi\u0161t\u011b",
    "update_supplier": "Aktualizovat dodavatele",
    "update_user": "Aktualizovat u\u017eivatele",
    "update_vehicle": "Aktualizovat vozidlo",
    "upload": "Nahr\u00e1t",
    "upload_payments": "Nahr\u00e1t platby",
    "upto": "A\u017e do",
    "upto_period": "A\u017e do obdob\u00ed",
    "upto_value": "A\u017e do hodnoty",
    "use_fleet_defaults": "Pou\u017e\u00edt v\u00fdchoz\u00ed nastaven\u00ed vozov\u00e9ho parku",
    "used": "Vyu\u017eito?",
    "user": "U\u017eivatel",
    "username": "U\u017eivatelsk\u00e9 jm\u00e9no",
    "users_imported": "Detaily k u\u017eivatel\u016fm byly vlo\u017eeny",
    "valid_fleet_name_that_is_created_for_this_driver_supplier": "Platn\u00fd n\u00e1zev vozov\u00e9ho parku, kter\u00fd je propojen s klientsk\u00fdm \u00fa\u010dtem \u0159idi\u010de",
    "vat": "DPH",
    "vat_rate": "Sazba DPH",
    "vat_rates": "Sazby DPH",
    "vat_registration_number": "DI\u010c",
    "vehicle": "Vozidlo",
    "vehicle_registration_nr": "Registra\u010dn\u00ed zna\u010dka vozidla",
    "vehicle_registration_number": "Registra\u010dn\u00ed zna\u010dka vozidla",
    "vehicle_type": "Typ vozidla",
    "vehicle_types": {
        "Van": "Dod\u00e1vka",
        "Truck": "Solo",
        "TractorUnit": "N\u00e1v\u011bs"
    },
    "vehicles": "Vozidla",
    "vehicles_imported": "Detaily k vozidl\u016fm byly vlo\u017eeny",
    "view_all_bookings": "Zobrazit v\u0161echny rezervace",
    "view_all_sites": "Zobrazit v\u0161echna parkovi\u0161t\u011b",
    "view_all_users": "Zobrazit v\u0161echny u\u017eivatele",
    "view_details": "Zobrazit detaily",
    "view_order": "Zobrazit objedn\u00e1vku",
    "waiting_approval_title": "\u010cek\u00e1n\u00ed na schv\u00e1len\u00ed\u2026.",
    "we_have_sent_a_security_code": "Bezpe\u010dnostn\u00ed k\u00f3d byl odesl\u00e1n na {{email}}",
    "weekly": "T\u00fddn\u011b",
    "west": "Z\u00e1pad",
    "west_short": "Z",
    "year": "Rok",
    "yearly": "Ro\u010dn\u011b",
    "yes": "Ano",
    "you_have_been_checked_in": "Byli jste p\u0159ihl\u00e1\u0161eni",
    "you_have_been_checked_out": "Byli jste odhl\u00e1\u0161eni",
    "you_have_checked_in": "P\u0159ihl\u00e1sili jste se na adrese {{siteName}} ke sv\u00e9 objedn\u00e1vce #{{orderId}} v {{bookingTime}}",
    "you_have_checked_out": "Odhl\u00e1sili jste se na adrese {{siteName}} ve sv\u00e9 objedn\u00e1vce #{{orderId}} v {{bookingTime}}",
    "you_have_not_selected_any_site_as_favourite": "Nevybrali jste \u017e\u00e1dnou lokalitu jako obl\u00edbenou",
    "your_account_is_current_on_stop": "V\u00e1\u0161 \u00fa\u010det je aktu\u00e1ln\u011b zablokov\u00e1n. Pros\u00edm kontaktujte Va\u0161eho mana\u017eera.",
    "your_booking_has_been_extended": "Rezervace byla prodlou\u017eena do {{expireDate}}",
    "your_booking_is_ready": "Va\u0161e rezervace je p\u0159ipravena",
    "your_order_has_been_approved": "Va\u0161e objedn\u00e1vka #{{orderId}} byla schv\u00e1lena parkovi\u0161t\u011bm {{siteName}} na {{bookingTime}}",
    "your_order_has_been_declined": "Va\u0161e objedn\u00e1vka #{{orderId}} byla zam\u00edtnuta parkovi\u0161t\u011bm {{siteName}}. Pros\u00edm upravte Va\u0161i rezervaci nebo n\u00e1s kontaktujte",
    "your_order_is_successful_show_qr_code": "Va\u0161e rezervace byla schv\u00e1lena, pros\u00edm p\u0159edlo\u017ete QR k\u00f3d na adrese {{siteName}} ke zpracov\u00e1n\u00ed",
    "your_parking_period_expires_on": "Va\u0161e placen\u00e9 parkov\u00e1n\u00ed vypr\u0161\u00ed {{expireDate}}"
}
