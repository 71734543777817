import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAuth} from "@/services/Auth";
import React, {useEffect, useState} from "react";
import LoadingPage from "@/components/LoadingPage";
import {Button, Card, Col, Row, Table} from "react-bootstrap";
import ConfirmDialog from "../../../components/Modals/ConformDialog";
import SuccessDialog from "../../../components/Modals/SuccessDialog";
import OrderItems from "@/components/Tables/OrderItems";
import Fleets from "@/components/Tables/Fleets";
import Users from "@/components/Tables/Users";
import Vehicles from "@/components/Tables/Vehicles";
import moment from "moment-timezone";
import { Helmet } from "react-helmet";
import AccountItems from "../../../components/Tables/AccountItems";

function ClientSingle(props) {

    const {t, i18n} = useTranslation();

    let auth = useAuth();

    const getRequest = auth.getRequest;
    let {id: clientId} = useParams();


    const [client, setClient] = useState(null);
    const [loading, setLoading] = useState(true);
    const [canUpdateClient, setCanUpdateClient] = useState(false);
    const [canDeleteClient, setCanDeleteClient] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [clientDeleteSuccess, setClientDeleteSuccess] = useState(false);
    const id = clientId || props.companyId;

    const deleteClient = () => {

        setConfirmDelete(false);
        setLoading(true);
        auth.deleteRequest(`/clients/${id}`)
            .then(response => {
                setLoading(false);
                setClient(null);
                setClientDeleteSuccess(true);
            })
            .catch(error => {
                setLoading(false);
            })
    }

    const handleClose = () => {
        setConfirmDelete(false);
        if (clientDeleteSuccess) {
            props.history.push(`/clients`)
            setClientDeleteSuccess(false);
        }
    }

    useEffect(() => {
        if(client === null)
        {
            let request = getRequest('/clients/' + id);


            request
                .then(response => {
                    setClient(response.data.client);
                    setCanUpdateClient(response.data.can_update);
                    setCanDeleteClient(response.data.can_delete);
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                })

            return () => request?.abort && request.abort();

        }
    }, [client]);

    return (
        <Card className="mx-2 my-2 p-2">
            <Helmet>
                <title>{client?.deleted_at && t('archived') || ''} {client?.name || ''} / {t('drawer_link_titles.clients')} - {t('app')}</title>
            </Helmet>


            <SuccessDialog
                show={clientDeleteSuccess}
                message="record_deleted"
                handleClose={_ => setClientDeleteSuccess(false)}
            />

            <ConfirmDialog
                message='delete_current_client'
                cancel='conform_dialog.cancel'
                accept='conform_dialog.delete'
                acceptAction={deleteClient}
                show={confirmDelete}
                handleClose={handleClose}
            />

            <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                <h3 className="text-primary">{client?.deleted_at && t('archived') || ''} {client?.name}</h3>
                <div>
                    {canDeleteClient && <Button variant="danger" className="mx-1" onClick={() => setConfirmDelete(true)}>{t('delete')}</Button>}
                    {
                        (client && auth.user.is_platform_admin && client.account_type === 'Card') &&
                        <Link to={`/clients/${id}/payment-methods`} className="btn btn-warning me-2 my-1 my-md-0">{t('payment_methods')}</Link>
                    }
                    {
                        (client && auth.user.is_client_admin && client.account_type === 'Card') &&
                        <Link to={`/settings/payment-methods/`} className="btn btn-warning me-2 my-1 my-md-0">{t('payment_methods')}</Link>
                    }
                    {
                        canUpdateClient &&
                        <Link to={`/clients/edit/${id}`} className="btn btn-primary">{t('edit')}</Link>
                    }
                </div>
            </Card.Subtitle>
            <Row className="mt-5">
                <Col md={8}>
                    <Row>
                        <Col>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h5>{t('drawer_link_titles.fleets')}</h5>
                                {
                                    (auth?.roles?.create_fleet) && !client?.deleted_at &&
                                    <Link to={`/fleets/create/${id}`} className="btn btn-secondary">{t('new_fleet')}</Link>
                                }
                            </div>

                            <Fleets url={`/clients/${id}/fleets`} perPage={10} exclude={['client']} />
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h5 className="mb-0">{t('orders.last_ten_orders')}</h5>
                                <Link to={`/orders/client/${id}`} className="btn btn-primary">{t('orders.view_all_orders')}</Link>
                            </div>

                            <OrderItems url={`/clients/${id}/order_items`} perPage={10} paginate={false}/>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h5 className="mb-0">{t('drawer_link_titles.users')}</h5>
                                <div>
                                    {
                                        (!auth.user.is_client_operator && !client?.deleted_at && !auth.user.is_platform_operator) &&
                                        <Link to={`/users/create/client/${id}`} className="btn btn-secondary me-2">{t('new_user')}</Link>
                                    }
                                    <Link to={`/clients/${id}/users`} className="btn btn-primary">{t('view_all_users')}</Link>
                                </div>
                            </div>

                            <Users url={`/clients/${id}/users`} exclude={['client', 'account_number', 'site', 'supplier']} />
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">{t('drawer_link_titles.vehicles')}</h5>
                            </div>

                            <Vehicles url={`/clients/${id}/vehicles`} exclude={['client']} />
                        </Col>
                    </Row>

                    <Row className="mt-5">
                      <Col>
                        <div className="d-flex justify-content-between align-items-center">
                          <h5 className="mb-0">{t('billing_information')}</h5>
                        </div>

                        <AccountItems url={`/companies/${id}/account-items`} />
                      </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">{t('site_pricing')}</h5>
                                {
                                    auth.user.is_platform_admin && !client?.deleted_at &&
                                    <Link to={{ pathname: `/clients/${id}/pricing`, state: { client }}} className="btn btn-secondary me-2">{t('add_site_pricing')}</Link>
                                }
                            </div>
                            <Table striped bordered hover responsive className="mt-4">
                                <thead>
                                <tr>
                                    <th>{t('site_name')}</th>
                                    <th>{t('email_address')}</th>
                                    <th>{t('address')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    client?.client_pricing?.map && client.client_pricing.filter(_ => !!_.site).map((pricing, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {
                                                        auth.user.is_platform_admin ?
                                                            <Link to={{ pathname: `/clients/${id}/sites/${pricing.site.id}/pricing`, state: { client } }}>{pricing.site.name}</Link>
                                                            :
                                                            <span>{pricing.site.name}</span>
                                                    }
                                                </td>
                                                <td>{pricing.site.email}</td>
                                                <td>{pricing.site.address}</td>
                                            </tr>
                                        )
                                    }) || (
                                    <tr>
                                        <td colSpan={3}>{t('loading')}</td>
                                    </tr>
                                    )
                                }
                                {
                                    client?.client_pricing?.length === 0 &&
                                    <tr>
                                        <td colSpan={3}>{t('no_data')}</td>
                                    </tr>
                                }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>

                <Col md={4}>
                    {client && (
                    <ol className="list-group">
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className=" text-black-50">{t('address')}</div>
                                {client.address},&nbsp;{client.address_line_2}
                            </div>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className=" text-black-50">{t('email_address')}</div>
                                {client.email}
                            </div>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className=" text-black-50">{t('phone_number')}</div>
                                {client.phone_number}
                            </div>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className=" text-black-50">{t('account_number')}</div>
                                {client.account_number}
                            </div>
                        </li>

                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className=" text-black-50">{t('billing_currency')}</div>
                                {client.currency}
                            </div>
                        </li>

                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className=" text-black-50">{t('compliance_licences')}</div>
                                {client.compliance_licences}
                            </div>
                        </li>

                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className=" text-black-50">{t('price_per_licence')}</div>
                                {client.compliance_price_per_licence && client.compliance_price_per_licence?.toFixed(2) || ''}
                            </div>
                        </li>

                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className=" text-black-50">{t('compliance.title') + ' ' + t('last_updated')}</div>
                                {client.compliance_last_updated &&  moment(client.compliance_last_updated).format(t('formats.date')) || ''}
                            </div>
                        </li>

                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className=" text-black-50">{t('account_status')}</div>
                                {t(client.account_status_defined)}
                            </div>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className=" text-black-50">{t('account_type')}</div>
                                {t(client.account_type)}
                            </div>
                        </li>
                        {
                            (client.account_type === 'Credit' && client.credit != null) &&
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('mpark_credit')}</div>
                                    {client.credit?.balance?.toFixed(2)}&nbsp;{client.currency} / {client.credit.limit == 0 ? t('unlimited') : client.credit.limit?.toFixed(2) +' '+ client.currency}
                                </div>
                            </li>
                        }
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className=" text-black-50">{t('client_payment_terms')}</div>
                                {client.client_payment_terms_days}&nbsp;{t('days')}&nbsp; {client.client_payment_terms}
                            </div>
                        </li>
                        {
                            client.account_type !== 'Credit' &&
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('alternative_payment_method')}</div>
                                    <div className="d-flex flex-column mt-2">
                                        {
                                            client.payment_methods.map((paymentMethod, index) => {
                                                return <span className="" key={index}>{paymentMethod.card_number}</span>
                                            })
                                        }
                                    </div>
                                </div>
                            </li>
                        }
                    </ol>
                    ) || <LoadingPage/>}
                </Col>
            </Row>
        </Card>
    )
}

export default ClientSingle;
