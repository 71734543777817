export default {
    "Apr": "Aprilie",
    "Aug": "August",
    "Dec": "decembrie",
    "Feb": "februarie",
    "Jan": "ianuarie",
    "Jul": "iulie",
    "Jun": "iunie",
    "Mar": "Martie",
    "May": "Mai",
    "Nov": "noiembrie",
    "Oct": "octombrie",
    "Sep": "Septembrie",
    "account_number": "Numar de cont",
    "account_payment": "Plata contului",
    "account_status": "starea contului",
    "account_type": "Tip de cont",
    "active": "Activ",
    "add_addon": "Ad\u0103uga\u021bi Addon",
    "add_drivers": "Ad\u0103uga\u021bi soferi",
    "add_parking_price": "Ad\u0103uga\u021bi pre\u021bul de parcare",
    "add_parking_prices": "Add Parking Prices",
    "add_payment_method": "Ad\u0103uga\u021bi o metod\u0103 de plat\u0103",
    "add_promotion": "Adaug\u0103 promo\u021bie",
    "add_region": "Ad\u0103uga\u021bi o regiune",
    "add_service": "Ad\u0103uga\u021bi serviciu",
    "add_site": "Ad\u0103uga\u021bi site",
    "addon": "Add-on",
    "addon_purchase_using_fleet_account": "Suplimentul pentru achizi\u021bii de \u0219oferi utiliz\u00e2nd contul de flot\u0103",
    "addon_specific": "Specific pentru servicii și suplimente",
    "addons": "Suplimente",
    "address": "Adresa",
    "admin_dashboard": "Tabloul de bord administrativ",
    "all": "Toate",
    "alternative_payment_method": "Metod\u0103 alternativ\u0103 de plat\u0103",
    "amount": "Cantitate",
    "approve": "Aproba",
    "billing_address": "Adresa de facturare",
    "billing_address_same_as_registered_address": "Adresa de facturare aceea\u0219i cu adresa \u00eenregistrat\u0103?",
    "billing_currency": "Moneda de facturare",
    "call_to_action": "Apel la ac\u021biune",
    "card": "Card",
    "card_expiry": "Expirarea cardului",
    "card_holder_name": "Nume De\u021bin\u0103tor Card",
    "card_number": "Num\u0103r de card",
    "category": "Categorie",
    "ccv": "CCV",
    "check_email": "V\u0103 rug\u0103m s\u0103 v\u0103 verifica\u021bi e-mailul",
    "check_in": "Verifica",
    "check_off_what_customer_has_received": "V\u0103 rug\u0103m s\u0103 verifica\u021bi ceea ce a primit clientul.",
    "check_out": "Verific\u0103",
    "checked_in_at": "S-a \u00eenregistrat la",
    "checked_out_at": "Verificat la",
    "choose": "Alege",
    "choose_driver": "Alege\u021bi Soferul",
    "choose_facility": "Alege\u021bi Facilitatea",
    "choose_fleet": "Alege\u021bi Flot\u0103",
    "city": "Ora\u0219",
    "city_country": "Oras-tara",
    "client": "Client",
    "client_payment_terms": "Condi\u021bii de plat\u0103 pentru clien\u021bi",
    "client_payment_terms_days": "Zilele de plat\u0103 ale clientului",
    "commission_amount": "Suma comisionului",
    "commission_percentage": "Procentul comisionului",
    "commission_rates": "Tarife comisioane",
    "company_details": "detaliile companiei",
    "company_name": "Numele companiei",
    "confirm_your_email": "Confirma\u021bi adresa dumneavoastr\u0103 de email",
    "conform_dialog": {
        "are_you_sure": "Esti sigur?",
        "order_confirm": {
            "message": "Aproba\u021bi comanda?",
            "cancel": "Anulare",
            "accept": "Bine"
        },
        "order_reject": {
            "message": "Respinge\u021bi comanda?",
            "cancel": "Anulare",
            "accept": "Bine"
        },
        "cancel": "Anulare",
        "delete": "\u0218terge",
        "accept": "Bine"
    },
    "contact_name": "nume de contact",
    "continue": "Continua",
    "continue_sign_in": "V\u0103 rug\u0103m s\u0103 continua\u021bi s\u0103 v\u0103 conecta\u021bi cu noua parol\u0103",
    "cost": "Cost",
    "country": "\u021aar\u0103",
    "county": "jud",
    "covered_costs": "Costuri acoperite",
    "create": "Crea",
    "create_account": "Creeaz\u0103 cont",
    "create_password_header": "Crea\u021bi o nou\u0103 parol\u0103 mai jos",
    "create_user": "Creaza utilizator",
    "credit": "Credit",
    "credit_limit": "Limita de credit",
    "daily": "Zilnic",
    "dashboard": {
        "dashboard": "Bord",
        "total_m_park_fee": "Taxa total\u0103 M-Park",
        "parking_occurrences": "Evenimente de parcare",
        "non_parking_occurrences": "Evenimente non-parcare",
        "active_sites": "Site-uri active",
        "active_users": "Utilizatori activi",
        "total_site_revenue": "Venitul total al site-ului",
        "total_revenue": "Veniturile totale",
        "total_non_parking_revenue": "Venituri totale non-parcare",
        "average_order_value": "Valoarea medie a comenzii",
        "platform_dashboard": "Tabloul de bord al platformei",
        "driver_dashboard": "Tabloul de bord pentru \u0219ofer",
        "company_dashboard": "Tabloul de bord client",
        "fleet_manager_dashboard": "Tabloul de bord Fleet Manager",
        "supplier_dashboard": "Tabloul de bord al furnizorului",
        "site_manager_dashboard": "Tabloul de bord Site Manager",
        "operator_dashboard": "Tabloul de bord al operatorului"
    },
    "date": "Data",
    "date_first_used": "Data primei utiliz\u0103ri",
    "day": "Zi",
    "delete_payment_method": "\u0218terge\u021bi metoda de plat\u0103?",
    "description": "Descriere",
    "disapprove": "Dezaproba",
    "discount": "Reducere",
    "distance_units": "Unit\u0103\u021bi de distan\u021b\u0103",
    "download_reports": "Desc\u0103rca\u021bi raportul",
    "drawer_link_titles": {
        "dashboard": "tablou de bord ",
        "users": "Utilizatori",
        "vehicles": "Vehicule",
        "favourite_sites": "Site-uri preferate",
        "settings": "Setari",
        "need_help": "Aveti nevoie de ajutor?",
        "clients": "Clienti",
        "fleets": "Flote",
        "suppliers": "Furnizori",
        "sites": "Site-uri",
        "promotion": "Promotie",
        "promotions": "Promotii",
        "orders": "Comenzi",
        "reports": "Rapoarte",
        "find_site": "Gaseste un site",
        "scan_qr_code": "Scaneaza codul QR"
    },
    "driver": "Sofer",
    "driver_checked_out_at": "\u0218oferul a f\u0103cut check-out la {{checkedOutTime}}",
    "driver_have_been_checked_in": "\u0218oferul a fost \u00eenregistrat",
    "driver_have_been_checked_out": "\u0218oferul a fost verificat",
    "driver_parking_period_expires_on": "Perioada de parcare pl\u0103tit\u0103 a \u0219oferului expir\u0103 {{expireDate}",
    "driver_settings": "Set\u0103ri sofer",
    "drivers": "\u0218ofer(i)",
    "edit": "Edita\u021bi ",
    "edit_payment_method": "Edita\u021bi metoda de plat\u0103",
    "email_address": "Adresa de email",
    "email_notifications": "notific\u0103ri prin email",
    "enter_card_holder_name": "Introduce\u021bi numele titularului cardului",
    "enter_card_number": "Introduce\u021bi num\u0103rul cardului",
    "enter_email_address": "Introduce\u021bi adresa de email",
    "enter_expire": "Introduce\u021bi data de expirare",
    "enter_message": "Introduce\u021bi mesajul",
    "enter_name": "Introdu numele",
    "enter_order_id_manually": "Introduce\u021bi manual ID-ul comenzii",
    "enter_parking_site_name": "Introduce\u021bi un nume de parcare",
    "enter_parking_site_website": "Accesa\u021bi site-ului de parcare",
    "enter_phone_number": "Introduceti numarul de telefon",
    "enter_username": "Introduceti numele de utilizator",
    "enter_your_security_code": "Introduce\u021bi codul dvs. de securitate",
    "entity": "Entitate",
    "errors": {
        "error_401_message": "Cerere neautorizata"
    },
    "expire": "Expira",
    "expires": "Expir\u0103",
    "expiry_date": "Data de expirare",
    "export": "Export",
    "export_payouts": "Pl\u0103\u021bi de export",
    "facilities": "Facilit\u0103\u0163i",
    "filters_labels": {
        "show_advance_filters": "Afi\u0219a\u021bi filtrele avansate",
        "hide_advance_filters": "Ascunde filtrele avansate",
        "filter_by_creation_date": "Filtra\u021bi dup\u0103 data cre\u0103rii contului",
        "filter_by_country": "Filtra\u021bi dup\u0103 Numele \u021b\u0103rii",
        "filter_by_region": "Filtra\u021bi dup\u0103 regiune",
        "filter_by_account_status": "Filtra\u021bi dup\u0103 starea contului",
        "search_for_email_address": "C\u0103uta\u021bi adresa de e-mail",
        "filter_by_company_name": "Filtra\u021bi dup\u0103 numele companiei",
        "filter_by_site": "Filtra\u021bi dup\u0103 site",
        "filter_by_user": "Filtra\u021bi dup\u0103 utilizator",
        "filter_by_client": "Filtra\u021bi dup\u0103 client",
        "filter_by_suppliers": "Filtra\u021bi dup\u0103 furnizor",
        "filter_by_fleet": "Filtra\u021bi dup\u0103 flot\u0103",
        "start_date": "Data de \u00eenceput",
        "end_date": "Data de \u00eencheiere",
        "filter_by_primary_contact_name": "Filtra\u021bi dup\u0103 numele contactului principal",
        "credit_status": "Starea creditului",
        "between_these_dates": "\u00centre Aceste Date",
        "show_promotion": "Afi\u0219eaz\u0103 promo\u021bia?"
    },
    "find_user": "G\u0103si\u021bi utilizator",
    "fleet": "Flota",
    "fleet_name": "Numele flotei",
    "fleets": "Flote",
    "forgot_password": "Ti-ai uitat parola?",
    "form_validation": {
        "invalid_email_address": "Va rugam sa utilizati o adresa de e-mail valida",
        "password_invalid_min_length": "Parola nu trebuie sa aiba mai putin de {{length}} caractere",
        "code_invalid_min_length": "Codul nu trebuie sa fie mai mic de {{length}} numere",
        "code_invalid_max_length": "Codul nu trebuie sa contina mai mult {{length}} numere",
        "password_is_required": "Parola este necesara",
        "code_is_required": "Codul este necesar",
        "password_is_must_match": "Parola trebuie sa se potriveasca",
        "password_confirm_required": "Este necesara confirmarea parolei",
        "email_address_is_required": "Adresa de e-mail este necesara",
        "name_is_required": "Numele este necesar",
        "username_is_required": "Numele de utilizator este necesar",
        "user_name_is_required": "Numele de utilizator este necesar",
        "phone_number_is_required": "Numarul de telefon este necesar",
        "user_type_is_required": "Tipul de utilizator este necesar",
        "site_is_required": "Site-ul este necesar",
        "message_is_required": "Mesajul este obligatoriu",
        "product_is_required": "Produsul este necesar",
        "discount_is_required": "Reducerea este necesara",
        "start_date_is_required": "Data de incepere este obligatorie",
        "expiry_date_is_required": "Data de expirare este obligatorie",
        "expiry_date_min": "Data de expirare nu poate fi mai mică decât data curentă",
        "call_to_action_required": "Este necesar un apel la ac\u021biune",
        "link_to_action_required": "Link-ul este necesar",
        "site_name_is_required": "Numele site-ul este necesar",
        "description_is_required": "Este necesara descrierea",
        "total_spaces_available_is_required": "Este necesar un total de locuri disponibile",
        "site_currency_is_required": "Moneda site-ului este necesara",
        "longitude_is_required": "Longitudinea este necesar\u0103",
        "latitude_is_required": "Este necesar\u0103 latitudinea",
        "address_is_required": "Adresa este obligatorie",
        "city_town_is_required": "Ora\u0219ul\/ora\u0219ul este obligatoriu",
        "country_is_required": "\u021aara este obligatorie",
        "county_is_required": "\u021aara este obligatorie",
        "post_code_is_required": "Codul po\u0219tal este necesar",
        "parking_price_is_required": "Pre\u021bul de parcare este obligatoriu",
        "parking_price_period_value_is_required": "Este necesar\u0103 valoarea perioadei",
        "parking_price_per_period_type_is_required": "Este necesar\u0103 valoarea pe perioad\u0103",
        "parking_price_up_to_value_is_required": "P\u00e2n\u0103 la valoarea este necesar\u0103",
        "parking_price_up_to_period_is_required": "Este necesar\u0103 o perioad\u0103 de p\u00e2n\u0103 la",
        "category_is_required": "Categoria este obligatorie",
        "price_is_required": "Pre\u021bul este obligatoriu",
        "commission_rate_type_is_required": "Este necesar tipul de rat\u0103 de comision",
        "commission_value_is_required": "Rata comisionului este necesar\u0103",
        "vehicle_registration_number_is_required": "Num\u0103rul de \u00eenmatriculare al vehiculului este necesar"
    },
    "from": "Din",
    "full_period": "Perioada complet\u0103",
    "get_directions": "A primi direc\u021bii",
    "global_commission": "Comisii globale",
    "go_to_dashboard": "Accesa\u021bi Tabloul de bord",
    "got_to_settings": "Mergi la Setari",
    "grand_total": "Total general",
    "help_message_receive": "V\u0103 mul\u021bumim c\u0103 ne-a\u021bi contactat, mesajul dvs. a fost primit",
    "home": "Acas\u0103",
    "hour": "Ora",
    "id": "ID",
    "images": "Imagini",
    "import_clients": "Importa\u021bi clien\u021bi",
    "import_fleets": "Import flote",
    "import_payouts": "Pl\u0103\u021bi de import",
    "import_promotions": "Promo\u021bii de import",
    "import_sites": "Importa\u021bi site-uri",
    "import_suppliers": "Furnizori de import",
    "import_users": "Importa\u021bi utilizatori",
    "import_vehicles": "Import vehicule",
    "inactive": "Inactiv",
    "last_ten_orders": "Ultimele 10 comenzi",
    "latitude": "Latitudine",
    "link": "Link",
    "login": "Autentificare",
    "logout": "Delogare",
    "longitude": "Longitudine",
    "manager": "Administrator",
    "max_journey_duration": "Durata maxim\u0103 a c\u0103l\u0103toriei",
    "message": "Mesaj",
    "month": "Lun\u0103",
    "monthly": "Lunar",
    "more_than_500_spaces": "Peste 5000 de locuri",
    "more_than_two_hours": "Mai mult de 2 ore",
    "name": "Nume",
    "name_on_the_card": "Numele de pe card",
    "net_amount": "Cantitate net\u0103",
    "new_client": "Ad\u0103uga\u021bi un client nou",
    "new_fleet": "Ad\u0103uga\u021bi o flot\u0103 nou\u0103",
    "new_promotion": "Adaug\u0103 promo\u021bie",
    "new_site": "Ad\u0103uga\u021bi site nou",
    "new_supplier": "Ad\u0103uga\u021bi un furnizor nou",
    "new_user": "Utilizator nou",
    "new_vehicle": "Ad\u0103uga\u021bi un vehicul nou",
    "save_new_client": "Ad\u0103uga\u021bi un client nou",
    "save_new_fleet": "Ad\u0103uga\u021bi o flot\u0103 nou\u0103",
    "save_new_promotion": "Adaug\u0103 promo\u021bie",
    "save_new_site": "Ad\u0103uga\u021bi site nou",
    "save_new_supplier": "Ad\u0103uga\u021bi un furnizor nou",
    "save_new_user": "Utilizator nou",
    "save_new_vehicle": "Ad\u0103uga\u021bi un vehicul nou",
    "no": "Nu",
    "no_payment_method_found": "Nu a fost g\u0103sit\u0103 o metod\u0103 de plat\u0103",
    "notify_new_orders": "Comenzi noi",
    "notify_orders_status": "Actualiz\u0103ri privind starea comenzii",
    "number_of_bookings": "Num\u0103r de rezerv\u0103ri",
    "number_of_drivers": "Num\u0103rul de \u0219oferi",
    "number_of_vehicles": "Num\u0103rul de vehicule",
    "okay": "OK",
    "only_show_sales": "Afi\u0219a\u021bi doar v\u00e2nz\u0103rile?",
    "open_24_hours": "Deschis 24 de ore",
    "order_id": "Comanda ID",
    "order_status": {
        "pending": "In asteptare",
        "paid": "Pl\u0103tit",
        "completed": "Efectuat",
        "rejected": "Respins"
    },
    "order_success_waiting_approval": "",
    "orders": {
        "recent_orders": "comenzi recente",
        "view_all_orders": "Vezi toate comenzile",
        "order_reference": "Comand\u0103",
        "last_ten_orders": "Ultimele 10 comenzi"
    },
    "over_cap": "Peste Cap",
    "paid": "pl\u0103tit",
    "parking_available": "Parcare disponibil\u0103?",
    "parking_price": "Pret parcare",
    "parking_price_configuration_note": "V\u0103 rug\u0103m s\u0103 re\u021bine\u021bi: se folose\u0219te cel mai mic pre\u021b p\u00e2n\u0103 la care se afl\u0103 \u00een interval, iar Perioada perceput\u0103 este rotunjit\u0103 la cea mai apropiat\u0103 \u201ePe perioad\u0103\u201d",
    "parking_site_name": "Numele locului de parcare",
    "parking_site_website": "Site-ul de parcare",
    "password": "Parola",
    "password_is_reset": "Parola dvs. este resetat\u0103",
    "payment": "Plat\u0103",
    "payment_details": "Detaliile platii",
    "payment_methods": "Metode de plata",
    "payment_terms_days": "Zilele de plat\u0103 ale clientului",
    "period_per": "Perioada (per)",
    "period_value": "Valoarea perioadei",
    "phone_number": "Numar de telefon",
    "please_set_up_a_valid_payment_method": "V\u0103 rug\u0103m s\u0103 ad\u0103uga\u021bi o metod\u0103 de plat\u0103 valid\u0103 pentru a face o rezervare",
    "please_wait": "Te rog asteapta...",
    "post_code": "Cod po\u0219tal",
    "prebookable": "Se poate rezerva \u00een avans?",
    "present_qr_code_for_payment": "V\u0103 rug\u0103m s\u0103 prezenta\u021bi codul QR p\u00e2n\u0103 la plata",
    "price": "Pre\u021b",
    "primary_contact": "Contact primar",
    "product": "Produs",
    "profile": "Profil",
    "promotion_message": "Mesaj de promovare",
    "provide_email_address_to_sent_reset_request": "V\u0103 rug\u0103m s\u0103 furniza\u021bi adresa de e-mail mai jos pentru a solicita codul de resetare",
    "quantity": "Cantitate",
    "receive_marketing_emails": "Primi\u021bi e-mailuri de marketing",
    "reference": "Referin\u0163\u0103",
    "region": "Regiune",
    "region_country": "Regiune (\u021bara)",
    "region_specific": "Specific regiune",
    "registered_address": "Adresa inregistrata",
    "registration": "\u00cenregistrare",
    "remaining": "R\u0103mas",
    "repeat_password": "Repeta parola",
    "request_order": "Cerere de comanda",
    "reset_link_sent": "Linkul de resetare a parolei este trimis",
    "reset_password": "Reseteaza parola",
    "role": "Rol",
    "roles": {
        "platform_admin": "Admin",
        "platform_finance": "Finan\u0163a",
        "supplier_admin": "Administrator furnizor",
        "site_manager": "Manager de site",
        "fleet_manager": "Manager de flot\u0103",
        "operator": "Operator",
        "client_admin": "Admin client",
        "driver": "Sofer"
    },
    "search": "C\u0103utare",
    "search_for_anything": "Caut\u0103 orice",
    "security_facilities": "Facilit\u0103\u021bi de securitate",
    "security_rating": "Evaluare de securitate",
    "send_email": "Trimite email",
    "service": "Serviciu",
    "share_location": "Distribuie locatia?",
    "show_qr_code": "Afi\u0219a\u021bi codul QRC",
    "sign_up": "Inscrie-te",
    "site": "Site",
    "site_created": "Site creat",
    "site_currency": "Moneda site-ului",
    "site_description": "Descrierea siteului",
    "site_name": "Numele site-ului",
    "site_size": "Dimensiunea site-ului",
    "site_specific": "Site specific",
    "site_updated": "Site actualizat",
    "spaces_available": "Total spa\u021bii disponibile",
    "spaces_available_display": "{{spaces}} spa\u021bii disponibile",
    "spending_caps": "Plafoane de cheltuieli",
    "start_date": "Data de \u00eenceput",
    "status": "stare",
    "stopped": "Oprit",
    "submit": "Trimite",
    "subtotal": "Subtotal",
    "success_dialog": {
        "success": "Succes!",
        "fleet_setting_updated": "Set\u0103rile flotei au fost actualizate",
        "driver_setting_updated": "Set\u0103rile au fost actualizate",
        "site_suggestion_sent": "Sugestie de site a fost trimis\u0103"
    },
    "suggest_a_parking_site": "Sugera\u021bi un loc de parcare",
    "supplier": "Furnizor",
    "supplier_payment_terms": "Condi\u021bii de plat\u0103 a furnizorului",
    "supplier_payment_terms_days": "Termenii de plat\u0103 a furnizorului zile",
    "switch_to_list_view": "Comuta\u021bi la vizualizarea list\u0103",
    "switch_to_map_view": "Comuta\u021bi la vizualizarea Hart\u0103",
    "tapa_rating_display": "{{evaluare}} Evaluare TAPA",
    "to": "La",
    "total": "Total",
    "total_addons": "Total Addons",
    "total_parking": "Parcare total\u0103",
    "total_spaces": "Spa\u021bii totale",
    "total_to_pay_on_card": "Total de pl\u0103tit cu cardul",
    "town_city": "Ora\u0219\/Ora\u0219",
    "transactions": "Tranzac\u021bii",
    "truck_park": "Parcul de camioane",
    "type": "Tip",
    "type_of_user": "Tip de utilizator",
    "unlimited": "Nelimitat",
    "unpaid": "Nepl\u0103tit",
    "up_to_500_spaces": "P\u00e2n\u0103 la 500 de locuri",
    "up_to_one_hour": "P\u00e2n\u0103 la 1 or\u0103",
    "up_to_two_hours": "P\u00e2n\u0103 la 2 ore",
    "update": "Actualiza\u021bi",
    "update_client": "Actualiza\u021bi clientul",
    "update_exchange_rates": "Actualiza\u021bi cursurile de schimb",
    "update_fleet": "Actualiza\u021bi flota",
    "update_fleet_settings": "Actualiza\u021bi set\u0103rile flotei",
    "update_settings": "Actualiza\u021bi set\u0103rile",
    "update_site": "Actualiza\u021bi site-ul",
    "update_supplier": "Actualiza\u021bi furnizorul",
    "update_user": "Actualiza\u021bi utilizatorul",
    "update_vehicle": "Actualiza\u021bi vehiculul",
    "upload_payments": "\u00cenc\u0103rca\u021bi pl\u0103\u021bi",
    "upto_period": "P\u00e2n\u0103 la Perioada",
    "upto_value": "P\u00e2n\u0103 la valoare",
    "use_fleet_defaults": "Utiliza\u021bi set\u0103rile implicite ale flotei",
    "used": "Folosit?",
    "user": "Utilizator",
    "username": "Nume de utilizator",
    "vat": "TVA",
    "vat_rate": "cota TVA",
    "vat_registration_number": "Num\u0103r de \u00eenregistrare \u00een scopuri de TVA",
    "vehicle": "Vehicul",
    "vehicle_registration_number": "Num\u0103r de \u00eenmatricluare al vehiculului",
    "view_all_bookings": "Vezi toate rezerv\u0103rile",
    "view_all_sites": "Vizualiza\u021bi toate site-urile",
    "view_all_users": "Vede\u021bi to\u021bi utilizatorii",
    "view_details": "Vezi detalii",
    "waiting_approval_title": "Astept aprobarea site-ului...",
    "we_have_sent_a_security_code": "Am trimis un cod de securitate c\u0103tre {{email}}",
    "weekly": "S\u0103pt\u0103m\u00e2nal",
    "year": "An",
    "yearly": "Anual",
    "yes": "da",
    "you_have_been_checked_in": "Ai fost \u00eenregistrat",
    "you_have_been_checked_out": "Ai fost verificat",
    "you_have_not_selected_any_site_as_favourite": "Nu a\u021bi selectat niciun site ca favorit",
    "your_parking_period_expires_on": "Perioada dvs. de parcare pl\u0103tit\u0103 expir\u0103 {{expireDate}}"
}
