import LoadingPage from "@/components/LoadingPage";
import {Badge, Button, Card, Col, Row, Spinner, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import FacilitiesList from "./components/FacilitiesList";
import OpenHours from "./components/OpenHours";
import JourneyDetails from "../sites/components/JourneyDetails";
import {useAuth} from "@/services/Auth";
import {useParams, Link, useHistory, useLocation} from "react-router-dom";
import ToggleFavourite from "../sites/ToggleFavourite";
import { Helmet } from "react-helmet";
import api from "@/services/Api";

function SiteDetails(props) {

    const { id } = useParams();
    const { t } = useTranslation();

    const route = useLocation();
    const history = useHistory();

    const auth = useAuth();
    const baseUrl = api.defaults.baseURL;

    const from  = route.state && route.state.from || null

    const [site, setSite] = useState(route.state && route.state.site || false);

    useEffect(() => {
        if (!site && id)
            getSiteDetails();
    }, []);

    const getSiteDetails = () => {
        auth.getRequest('/sites/' + id, { include: 'all' })
          .then(response => {
              setSite(response.data.data);
          })
    }

    const canBook = () => {
        return auth.user.is_driver || auth.user.is_client_admin || auth.user.is_fleet_manager
    }

    useEffect(() => {
        history.replace(route.pathname, {
            ...route.state,
            site,
        });
    }, [site]);
    return (
      (!site) ? (
        <LoadingPage/>
      ) : (
        <Card className="mx-2 my-2 p-2 site-card site-find-card position-relative">
            <Helmet>
                <title>{site.name} / {t('drawer_link_titles.find_site')} - {t('app')}</title>
            </Helmet>

            <Card.Body className="relative pt-0">

                <Row className="mb-2">
                    <Col cols="12" className="d-flex flex-column flex-md-row align-items-md-center">
                        {
                          ( from && from === '/find-a-site') &&
                          <Button variant="primary" className="my-1" onClick={() => props.history.goBack()}>{t('back_to_map_view')}</Button>
                        }
                        <h2 className="my-md-0 my-3 me-auto text-primary">{site.name}</h2>
                        {(site.latitude && site.longitude && canBook()) && (
                          <a className="btn btn-success mx-1 my-md-0 my-2" href={`https://google.com/maps/dir/?api=1&destination=${site.latitude},${site.longitude}`} target="_blank">
                              {t('get_directions')}
                          </a>
                        )}


                        {((site.prebookable || props?.location?.state?.instant_order_token) && !auth.user.is_platform_admin && canBook()) && (
                          <Link className="btn btn-primary mx-1 my-md-0 my-2" to={{
                              pathname: '/bookings/create',
                              state   : {
                                  site,
                                  booking: {
                                      site_id            : site.id,
                                      instant_order_token: props.location && props.location.state && props.location.state.instant_order_token || null
                                  }
                              }
                          }}>
                              {props.location && props.location.state && props.location.state.instant_order_token ? t('instant_order') : t('request_order')}
                          </Link>
                        ) || ''}
                        {
                          canBook() &&
                          <ToggleFavourite site={site} updateResponse={getSiteDetails}/>
                        }
                    </Col>
                </Row>

                <Row className="flex-column-reverse flex-sm-row ">
                    <Col sm={8} className="col-12">
                        {site.latest_promotion &&
                          <div>
                              <h5>
                                  <Badge className="my-1 text-wrap text-start" bg="success">{site.latest_promotion.message}</Badge>
                              </h5>
                          </div>
                        }

                        <JourneyDetails coordinates={site} value={site.journey} onChange={journey => setSite({ ...site, journey })} className="my-2 " />

                        <OpenHours facilities={site.facilities}/>

                        {site.security_rating && (
                          <div className="d-flex align-items-center mb-2 mt-2">
                              <Badge bg="primary" className="mx-2 facility-name-site-card-badge">&nbsp;</Badge>
                              <span className="mx-2 text-nowrap facility-name-site-card">{t('tapa_rating_display', {rating: site.security_rating})}</span>
                          </div>
                        )}

                        {site.total_spaces && (
                          <div className="d-flex align-items-center mb-2">
                              <Badge bg="primary" className="mx-2 facility-name-site-card-badge">&nbsp;</Badge>
                              <span className="mx-2 text-nowrap facility-name-site-card">{t('spaces_available_display', {spaces: site.total_spaces})}</span>
                          </div>
                        )}

                        {site.full_address && (
                          <div className="d-flex align-items-center mb-2">
                              <img className="address-icon" src={`/images/address-icon.svg`}/>
                              <span className="mx-1 facility-name-site-card">{site.full_address}</span>
                          </div>
                        )}
                    </Col>
                    <Col sm={4} className="col-12 text-sm-end text-center">

                        {
                            site.profile_image?.url ?
                              <Card.Img style={{width: '200px'}} src={site.profile_image.url}/>
                              :
                              <i className="bi bi-truck" style={{fontSize: "10rem"}}></i>
                        }
                    </Col>
                </Row>
                <Row className="my-2">
                    <Col className="col-12">
                        <p>{site.description} </p>
                    </Col>
                </Row>
                <Row className="my-2">
                    <h4>{t('parking_prices_vat')}</h4>
                    <Col className="col-12">
                        <Table striped responsive bordered hover size="sm" className="mt-1 w-auto">
                            {
                                ( site.parking_prices && site.parking_prices.length !== 0)  ?
                                  <tbody>
                                  {
                                      site.parking_prices.sort((a, b) => a.up_to_hours - b.up_to_hours)
                                        .map((parkingPrices, index) => (
                                          <tr key={index}>
                                              <td align="right">
                                                  {
                                                    parkingPrices.price_with_vat && (
                                                      <>
                                                          ({parkingPrices.price_with_vat.toFixed(2) + ' ' + site.currency}
                                                          <span
                                                            className="text-black-50 ms-auto small"> {t('incl_vat')}</span>)
                                                      </>

                                                    )
                                                    ||
                                                    <span className='font-weight-bold'>{t('free')}</span>}
                                              </td>
                                              <td>
                                                  {parkingPrices.price_with_vat && '/ ' + t(parkingPrices.per_period_type == 'Hour' ?  'n_hours' : 'n_days', { count: parkingPrices.period_value })}
                                              </td>
                                              <td>
                                                  {index + 1 < site.parking_prices.length && t('upto') + ' ' + t(parkingPrices.up_to_period == 'Hour' ?  'n_hours' : 'n_days', { count: parkingPrices.up_to_value })}

                                              </td>
                                          </tr>
                                        ))
                                  }
                                  </tbody> :
                                  <tbody>
                                  <tr>
                                      <td colSpan={5} className="text-center text-danger">{t('no_parking_prices_defined')}</td>
                                  </tr>
                                  </tbody>
                            }
                        </Table>
                    </Col>
                </Row>

                {site.attachments && site.attachments.length > 0 && (
                  <div className="d-flex">
                      {site.attachments
                        .filter(image => image.hash !== site?.profile_image?.hash)
                        .map(image => (
                          <Card style={{width: '10rem'}} className="me-1 my-2" key={image.hash}>
                              <Card.Img src={image.url}/>
                          </Card>
                        ))}
                  </div>
                )}

                <Row className="my-2">
                    <FacilitiesList facilities={[].concat(
                      site.services
                        .filter(_ => _.category)
                        .map(_ => _.category)
                    )
                      .concat(
                        site.facilities
                      )} prebookable={site.prebookable} type="FACILITIES"/>
                </Row>
                <Row className="my-2">
                    <FacilitiesList facilities={site.security_facilities} type="SECURITY"/>
                </Row>

                <h5>{t('booking.methods')}</h5>
                <div className="d-inline-flex text-white gap-2 align-items-center">
                    {site.instant_booking && (
                      <div className="bg-primary px-3 py-2 rounded">
                          <h6 className="mb-0 small">{t('dashboard.driver.pay_on_arrival')}</h6>
                      </div>
                    )}
                    {site.prebookable && (
                      <div className="bg-success px-3 py-2 rounded">
                          <h6 className="mb-0 small">
                              {site.instant_booking ? t('dashboard.driver.prebook') : t('dashboard.driver.prebook_only')}
                          </h6>
                      </div>
                    )}
                </div>
            </Card.Body>
        </Card>
      )
    )
}

export default SiteDetails;