import {useTranslation} from "react-i18next";
import React, {useEffect, useState, useRef} from "react";
import {useAuth} from "@/services/Auth";
import LoadingPage from "@/components/LoadingPage";
import {Badge, Card, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import QRCode from "qrcode";
import useMoment from "../../../hooks/useMoment";

function MyHistory() {
    const {t} = useTranslation();
    let auth = useAuth();

    const requests = useRef({});

    const {momentLocal, moment} = useMoment();

    const [bookings, setBookings] = useState(false);

    const getUserOrders = () => {
        requests.current = auth.getRequest('/bookings')

        requests.current
            .then(async response => {
                if (response.data.message === 'OK') {
                    const bookings = await initQRCode(response.data);
                    setBookings(bookings);
                }
            })
            .catch(error => {
            })
    }

    const initQRCode = async ({bookings, restricted_categories}) => {
        for (const booking of bookings) {
            try {
                if (!booking.token) {
                    continue;
                }
                let isAdr = false;
                let isOut = false;
                const qrData = {
                    booking: {
                        id: booking.id,
                        token: booking.token,
                    }
                };
                const data = await QRCode.toDataURL(JSON.stringify(qrData))
                booking?.order_items?.forEach && booking?.order_items?.forEach(orderItem => {
                    if (orderItem.category_id == restricted_categories?.adr) {
                        isAdr = true;
                    }
                    if (
                        orderItem.category_id == restricted_categories?.long_bay ||
                        orderItem.category_id == restricted_categories?.wide_bay
                    ) {
                        isOut = true;
                    }
                });
                let qrBgColor = '';
                if (booking.instant_booking) {
                    qrBgColor = '#00458c';
                }

                if (booking.prebooked) {
                    qrBgColor = '#116145';
                }


                if (isOut) {
                    qrBgColor = '#79ddff';
                }

                if (isAdr) {
                    qrBgColor = '#921313';
                }
                const isInstant = !isAdr && !isOut && booking.instant_booking;
                const isOutNotAdr = !isAdr && isOut;
                booking.src = data;
                booking.qrBgColor = qrBgColor;
                booking.isAdr = isAdr;
                booking.isInstant = isInstant;
                booking.isOut = isOutNotAdr;
            } catch (e) {
                console.log(e);
            }
        }
        return bookings;
    }

    useEffect(() => {
        getUserOrders()

        return () => requests?.current?.abort && requests.current.abort();
    }, []);

    return (
        !(bookings instanceof Array) ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{t('drawer_link_titles.my_history')} - {t('app')}</title>
                </Helmet>

                <div className="site-find-card position-relative">
                    <div className="mx-sm-2 mx-0">
                        <h3 className="text-primary">{t('drawer_link_titles.my_history')}</h3>
                    </div>

                    <Row>
                        {
                            bookings.map(booking => {
                                return (
                                    <Col sm={6} lg={4} className="col-12 my-2 driver-dashboard" key={booking.id}>
                                        <Card style={{width: '100%', height: '100%'}}
                                              className="mx-sm-2 mx-0 my-1 card-shadow order-card">
                                            <Card.Body>
                                                <Card.Subtitle>{booking.site.name} {booking.site.id}</Card.Subtitle>
                                                {!!booking.token && (
                                                    moment(moment.now()).subtract('15', 'minutes').isSameOrAfter(moment(booking.from_datetime ?? booking.created_at)) && (
                                                        <div className="my-3 pb-2 pt-4" style={{
                                                            width: '100%',
                                                            backgroundColor: booking.qrBgColor,
                                                        }}>
                                                            <Card.Subtitle
                                                                className="d-flex flex-direction-column justify-content-center">

                                                                <div
                                                                    className="qr-code-contend d-flex text-center flex-column">
                                                                    <img src={booking.src} className="rounded"/>
                                                                </div>
                                                            </Card.Subtitle>
                                                            <div className="d-flex gap-3 my-3 mx-2 overflow-hidden">
                                                                {!!booking.isInstant && (
                                                                    Array.from(Array(20).keys()).map((key) => (
                                                                        <p key={key}
                                                                           className="text-white m-0 facility-name-site-card"
                                                                           style={{minWidth: '84px'}}>
                                                                            {t('booking.instant_booking')}
                                                                        </p>
                                                                    ))
                                                                )}
                                                                {!!booking.prebooked && (
                                                                    Array.from(Array(20).keys()).map((key) => (
                                                                        <div key={key}
                                                                             className="d-inline-flex gap-1 align-items-center">
                                                                            <img className="service-icon"
                                                                                 src={`/images/facilities/prebook-transparent.svg`}/>
                                                                            <p className="text-white m-0 facility-name-site-card"
                                                                               style={{minWidth: '62px'}}>
                                                                                {t('booking.auto_approval')}
                                                                            </p>
                                                                        </div>
                                                                    ))
                                                                )}
                                                                {!!booking.isOut && (
                                                                    Array.from(Array(20).keys()).map((key) => (
                                                                        <div key={key}
                                                                             className="d-inline-flex gap-1 align-items-center">
                                                                            <img className="service-icon"
                                                                                 src={`/images/facilities/11-transparent.svg`}/>
                                                                            <p className="text-black m-0 facility-name-site-card"
                                                                               style={{minWidth: '72px'}}>
                                                                                {t('booking.out_of_gauge')}
                                                                            </p>
                                                                        </div>
                                                                    ))
                                                                )}
                                                                {!!booking.isAdr && (
                                                                    Array.from(Array(20).keys()).map((key) => (
                                                                        <div key={key}
                                                                             className="d-inline-flex gap-1 align-items-center">
                                                                            <img className="service-icon"
                                                                                 src={`/images/facilities/3-transparent.svg`}/>
                                                                            <p className="text-white m-0 facility-name-site-card">{t('booking.adr')}</p>
                                                                        </div>
                                                                    ))
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) || (
                                                        <h6 className="mt-3">
                                                            <Badge pill
                                                                   bg='warning'
                                                                   text='dark'>
                                                                {t(`booking.qr_not_activated`)}
                                                            </Badge>
                                                        </h6>
                                                    )
                                                )}
                                                <Row className="mx-0 mt-2">
                                                    <Col className="col-6 mt-2 p-0">
                                                        <div className="me-auto">
                                                            <div
                                                                className="text-black font-weight-bold order-heading">{t('vehicle')}</div>
                                                            <h5 className="font-weight-normal">{booking.vehicle?.registration_number}</h5>
                                                        </div>
                                                    </Col>
                                                    <Col className="col-6 mt-2 p-0">
                                                        <div className="me-auto">
                                                            <div
                                                                className="text-black font-weight-bold order-heading">{t('price')}</div>
                                                            <h5 className="font-weight-normal">{booking.order_items_sum_total_amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</h5>
                                                        </div>
                                                    </Col>

                                                    {booking.from_datetime && <Col className="col-6 mt-2 p-0">
                                                        <div className="me-auto">
                                                            <div
                                                                className="text-black font-weight-bold order-heading">{t('from')}</div>
                                                            <h5 className="font-weight-normal">{booking.from_datetime && momentLocal(booking.from_datetime).format(t('formats.datetime'))}</h5>
                                                        </div>
                                                    </Col>}
                                                    {booking.to_datetime && <Col className="col-6 mt-2 p-0">
                                                        <div className="me-auto">
                                                            <div
                                                                className="text-black font-weight-bold order-heading">{t('to')}</div>
                                                            <h5 className="font-weight-normal">{booking.to_datetime && momentLocal(booking.to_datetime).format(t('formats.datetime'))}</h5>
                                                        </div>
                                                    </Col>}
                                                    {!booking.from_datetime && <Col className="col-6 mt-2 p-0">
                                                        <div className="me-auto">
                                                            <div
                                                                className="text-black font-weight-bold order-heading">{t('booking.datetime')}</div>
                                                            <h5 className="font-weight-normal">{booking.created_at && momentLocal(booking.created_at).format(t('formats.datetime'))}</h5>
                                                        </div>
                                                    </Col>}
                                                    <Col className="col-12 mt-2 p-0">
                                                        <div className="me-auto">
                                                            <div
                                                                className="text-black font-weight-bold order-heading">{t('address')}</div>
                                                            <h5 className="font-weight-normal">{booking.site.address}</h5>
                                                        </div>
                                                    </Col>
                                                    {
                                                        (booking.vehicle_checked_in_at) &&
                                                        <Col className="col-12 mt-2 p-0">
                                                            <div className="me-auto d-flex  justify-content-between">
                                                                <div
                                                                    className="text-black font-weight-bold order-heading">{t('checked_in_at')}</div>
                                                                <h5 className="font-weight-normal">

                                                                    <Badge bg="success">
                                                                        {booking.vehicle_checked_in_at && momentLocal(booking.vehicle_checked_in_at).format(t('formats.datetime'))}
                                                                    </Badge>
                                                                </h5>
                                                            </div>
                                                        </Col>
                                                    }
                                                    {
                                                        booking.vehicle_checked_out_at &&
                                                        <Col className="col-12 mt-2 p-0">
                                                            <div className="me-auto d-flex justify-content-between">
                                                                <div
                                                                    className="text-black font-weight-bold order-heading">{t('checked_out_at')}</div>
                                                                <h5 className="font-weight-normal">
                                                                    <Badge bg="warning" text="dark">
                                                                        {booking.vehicle_checked_out_at && momentLocal(booking.vehicle_checked_out_at).format(t('formats.datetime'))}
                                                                    </Badge>
                                                                </h5>
                                                            </div>
                                                        </Col>
                                                    }
                                                </Row>
                                            </Card.Body>
                                            <Card.Footer className="light">
                                                <div className="d-grid gap-2">
                                                    <Link to={`/history/site-feedback/${booking.id}`}
                                                          className="btn btn-primary">{t('leave_feedback')}</Link>
                                                </div>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                        {
                            bookings.length === 0 &&
                            <Row>
                                <Col className="text-center">
                                    <p className="text-danger">
                                        {t('no_orders_available')}
                                    </p>
                                </Col>
                            </Row>
                        }
                    </Row>
                </div>
            </Card>
        )
    )
}

export default MyHistory;
