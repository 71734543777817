import * as moment               from "moment";
import * as momentTimezone               from "moment-timezone";
import 'moment/locale/cs'
import 'moment/locale/hu'
import 'moment/locale/fr'
import 'moment/locale/lt'
import 'moment/locale/pl'
import 'moment/locale/ro'
import 'moment/locale/sk'
import {useTranslation} from "react-i18next";

export default function useMoment() {

  const {i18n} = useTranslation();
  const momentLocal = (date) =>  {
    let dateLocal =  moment(date).locale(i18n.language) ;

    return momentTimezone.utc(dateLocal);
  };

  return {
    momentLocal,
    moment
  }

}