function parseCoordinate(point)
{
	var coordinate = {
		lat: null,
		lng: null,
	};

	if(typeof point.lat !== 'undefined' && point.lat !== null)
	{
		coordinate.lat = parseFloat(point.lat);
	}
	else
	if(typeof point.latitude !== 'undefined' && point.latitude !== null)
	{
		coordinate.lat = parseFloat(point.latitude);
	}

	if(typeof point.lng !== 'undefined' && point.lng !== null)
	{
		coordinate.lng = parseFloat(point.lng);
	}
	else
	if(typeof point.longitude !== 'undefined' && point.longitude !== null)
	{
		coordinate.lng = parseFloat(point.longitude);
	}

	if(coordinate.lat !== null && coordinate.lng !== null && !isNaN(coordinate.lat) && !isNaN(coordinate.lng))
	{
		return coordinate;
	}

	return null;
}


function hashCoordinate(point) {
	point = parseCoordinate(point);

	if(point)
	{
		return parseFloat(point.lat).toFixed(3) + "," + parseFloat(point.lng).toFixed(3);
	}
}

function hashJourney(start, dest) {
	start = hashCoordinate(start);
	dest = hashCoordinate(dest);

	if(start && dest)
	{
		return start + '-' + dest;
	}
}

// Converts from degrees to radians.
function toRadians(degrees) {
	return parseFloat(degrees) * Math.PI / 180;
}

// Converts from radians to degrees.
function toDegrees(radians) {
	return parseFloat(radians) * 180 / Math.PI;
}


function bearing(start, dest) {
	start = parseCoordinate(start);
	dest = parseCoordinate(dest);

	var startLat = toRadians(start.lat),
	startLng = toRadians(start.lng),
	destLat = toRadians(dest.lat),
	destLng = toRadians(dest.lng);

	var y = Math.sin(destLng - startLng) * Math.cos(destLat),
	x = Math.cos(startLat) * Math.sin(destLat) -
				Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng),
	brng = Math.atan2(y, x),
	brng = toDegrees(brng);
	return (brng + 360) % 360;
}

if (!Number.prototype.toRadians)
{
  Number.prototype.toRadians = function () {
    return this / 180.0 * Math.PI;
  };
}

function distance(origin, destination)
{
  origin = parseCoordinate(origin);
  destination = parseCoordinate(destination);

  if(origin && destination)
  {

	  var a1 = toRadians(origin.lat),
	      a2 = toRadians(destination.lat),
	      dy = toRadians(destination.lng - origin.lng),
	      earths_radius = 6371e3; // gives d in metres

	  return Math.acos( Math.cos(a1 - a2) + Math.cos(a1) * Math.cos(a2) * (Math.cos(dy) - 1)) * earths_radius;

  }

  return 6371e3;
};

export {
	parseCoordinate,
	hashCoordinate,
	hashJourney,
	bearing,
	distance
};
