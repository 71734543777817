import Vehicles       from "../../views/pages/vehicles/Vehicles";
import VehicleSingle  from "../../views/pages/vehicles/VehicleSingle";
import VehicleEdit    from "../../views/pages/vehicles/VehicleEdit";
import VehicleCreate  from "../../views/pages/vehicles/VehicleCreate";
import VehicleBadges   from "../../components/VehicleBadges";
import ImportVehicles from "../../views/pages/vehicles/ImportVehicles";

const vehiclesRoutes = [

    {
        path         : "/vehicles",
        component    : Vehicles,
        exact        : true,
        gate         : 'view_vehicles',
        title        : 'Vehicles',
        translate_key: 'vehicles',
        // icon         : 'bi-truck',
        icon         : <VehicleBadges />,
        main         : true,
    },

    {
        path     : "/vehicles/edit/:id",
        component: VehicleEdit,
        exact    : true,
        gate     : 'view_vehicles',
        main     : false,
    },
    {
        path     : "/vehicles/create",
        component: VehicleCreate,
        exact    : true,
        gate     : 'view_vehicles',
        main     : false,
    },
    {
        path     : "/vehicles/import",
        component: ImportVehicles,
        exact    : true,
        gate     : 'view_vehicles',
        main     : false,
    },
    {
        path     : "/vehicles/fleet/create/:fleet_id",
        component: VehicleCreate,
        exact    : true,
        gate     : 'view_vehicles',
        main     : false,
    },
    {
        path     : "/vehicles/:id",
        component: VehicleSingle,
        exact    : true,
        gate     : 'view_vehicles',
        main     : false,
    },
]

export default vehiclesRoutes;
