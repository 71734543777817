import {Card} from "react-bootstrap";
import {useAuth} from "@/services/Auth";
import AdminDashboard from "./components/AdminDashboard";
import SiteManagerDashboard from "./components/SiteManagerDashboard";
import FleetManagerDashboard from "./components/FleetManagerDashboard";
import DriverDashboard from "./components/DriverDashboard";
import { Helmet } from "react-helmet";
import {useTranslation}               from "react-i18next";

function Dashboard() {
    const {t} = useTranslation();
    let auth = useAuth();


    var dashboard;

    if(auth.user.is_platform_admin || auth.user.is_platform_operator || auth.user.is_platform_finance)
    {
        dashboard = <AdminDashboard/>;
    }
    else
    if(auth.user.is_supplier_admin || auth.user.is_site_manager || auth.user.is_operator || auth.user.is_supplier_operator)
    {
        dashboard = <SiteManagerDashboard />;
    }
    else
    if(auth.user.is_fleet_manager || auth.user.is_client_admin || auth.user.is_client_operator)
    {
        dashboard = <FleetManagerDashboard />;
    }
    else
    if(auth.user.is_driver)
    {
        return (
          <>
              <div className="mx-2">
                  <Helmet>
                      <title>{t('drawer_link_titles.dashboard')} - {t('app')}</title>
                  </Helmet>
                  <DriverDashboard />
              </div>
          </>
        )
    }


    return (
        <Card className="mx-2 my-2 p-2">
            <Helmet>
                <title>{t('drawer_link_titles.dashboard')} - {t('app')}</title>
            </Helmet>

            {dashboard}
        </Card>

    );
}


export default Dashboard;
