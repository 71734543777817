import React, {useEffect, useState}        from "react";
import {Link} from "react-router-dom";
import {useTranslation}                    from "react-i18next";
import {useAuth}                           from "@/services/Auth";
import LoadingPage                         from "@/components/LoadingPage";
import {Button, Card, Spinner, Row, Col}   from "react-bootstrap";
import Select                              from "react-select";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup                            from "yup";
import SuccessDialog                       from "../../../../components/Modals/SuccessDialog";
import SuggestAParkingSite                 from "../../../../components/Forms/SuggestAParkingSite";
import NumberFormat                        from "react-number-format";
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
}                                          from "react-share";
import {NotificationChannels} from "../../../../services";


function DriverSettings() {


    const {t, i18n} = useTranslation();

    let auth = useAuth();

    const getRequest = auth.getRequest;
    const putRequest = auth.putRequest;

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [updatingSettings, setUpdatingSettings] = useState(false);

    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [notificationChannelList, setNotificationChannelList] = useState(null);
    const [serverErrors, setServerErrors] = useState([]);

    const facebookShareUrl = "Facebook.com/M.Park";
    const linkedinShareUrl = "Linkedin.com/M.Park";
    const twitterShareUrl = "Twitter.com/M.Park";

    const handleSubmit = (payload) => {
        console.log(payload);
        setUpdatingSettings(true);
        putRequest(`/users/settings/${user.id}`, payload)
            .then(response => {
                setUpdatingSettings(false);
                if (response.data.message === 'OK') {
                    setSuccessMessage('success_dialog.driver_setting_updated')
                    setShowSuccessDialog(true);
                }
            })
            .catch(error => {
                console.log(error);
                if (error.response.status === 422) {
                    console.log(error.response.data.errors);
                    let serverErrors = [];
                    for (const key in error.response.data.errors) {
                        serverErrors.push(
                            error.response.data.errors[key][0]
                        )
                    }
                    setServerErrors(serverErrors)
                }
                setUpdatingSettings(false);
            })

    }

    useEffect(() => {
        if(notificationChannelList === null)
        {
            NotificationChannels.get().then(_ => setNotificationChannelList(_));
        }

        getRequest('/users/' + auth.user.id)
            .then(response => {
                setUser(response.data.user)
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })

    }, []);


    const handleSuggestSuccess = (successMessage) => {
        setSuccessMessage(successMessage)
        setShowSuccessDialog(true);
    }

    const logOut = () => {
        auth.signOut().then(response => {
            window.location.reload();
        });
    };


    return (
        loading ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-md-3 p-1 fleet-setting-card">
                <SuccessDialog
                    message={successMessage}
                    handleClose={() => setShowSuccessDialog(false)}
                    show={showSuccessDialog}
                />
                <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                    <h3>{t('driver_settings')}</h3>

                    <Link to={`/settings/payment-methods/`} className="btn btn-warning me-2 my-1 my-md-0">{t('payment_methods')}</Link>
                </Card.Subtitle>
                <div className="card-body px-2">
                    <Formik
                        initialValues={{
                            distance_units                  : user.distance_units,
                            receive_marketing_emails        : user.receive_marketing_emails,
                            share_location                  : user.share_location,
                            preferred_notification_channel  : user.preferred_notification_channel,
                        }}
                        onSubmit={handleSubmit}
                    >
                        {({values, errors, touched}) => (
                            <Form>
                                <div className="form-group mt-2">
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-md-4">
                                            <label htmlFor="distance_units" className="my-2">{t('distance_units')}</label>
                                            <Field
                                                type="text"
                                                as="select"
                                                name="distance_units"
                                                className={`form-control`}
                                            >
                                                <option value="km">{t('Kilometer')}</option>
                                                <option value="mi">{t('Mile')}</option>
                                            </Field>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-4">
                                            <label htmlFor="receive_marketing_emails" className="my-2">{t('receive_marketing_emails')}</label>
                                            <Field
                                                type="number"
                                                as="select"
                                                name="receive_marketing_emails"
                                                className={`form-control`}
                                            >
                                                <option value={1}>{t('yes')}</option>
                                                <option value={0}>{t('no')}</option>
                                            </Field>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-4">
                                            <label htmlFor="preferred_notification_channel" className="my-2">{t('preferred_notification_channel')}</label>

                                          <Field
                                            type="number"
                                            as="select"
                                            name="preferred_notification_channel"
                                            className={`form-control`}
                                          >
                                            {
                                              (!notificationChannelList ? [] : Object.keys(notificationChannelList)).map(
                                                key => <option value={key}>{ notificationChannelList[key] }</option>
                                              )
                                            }
                                          </Field>

                                        </div>
                                        <div className="col-12 col-sm-6 col-md-4">
                                            <label htmlFor="share_location" className="my-2">{t('share_location')}</label>
                                            <Field
                                                type="number"
                                                as="select"
                                                name="share_location"
                                                className={`form-control`}
                                            >
                                                <option value={1}>{t('yes')}</option>
                                                <option value={0}>{t('no')}</option>
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                {
                                    (serverErrors.length !== 0) &&
                                    <div className="form-group mt-4">
                                        {
                                            serverErrors.map((error, index) => <p className="text-danger font-weight-bold" key={index}>{error}</p>)
                                        }
                                    </div>
                                }
                                <div className="form-group mt-4">
                                    <div className="d-grid gap-2">
                                        {!updatingSettings ?
                                         <Button type="submit" variant="primary">{t('update_settings')}</Button>
                                                           :
                                         <Button variant="primary" disabled>
                                             <Spinner
                                                 as="span"
                                                 animation="border"
                                                 size="sm"
                                                 role="status"
                                                 aria-hidden="true"
                                             />
                                             <span className="mx-2">{t('please_wait')}</span>
                                         </Button>
                                        }
                                    </div>
                                </div>

                            </Form>
                        )}
                    </Formik>
                    <div className="social-sharing-space mt-4">
                        <h6>{t('share_app_on_social_media')}</h6>
                        <Row>
                            <Col className="col-md-6 col-6 py-2">
                                <FacebookShareButton url={facebookShareUrl}>
                                    <FacebookIcon size={32} round/>
                                </FacebookShareButton>
                                <small>
                                    <span className="ms-1">{facebookShareUrl}</span>
                                </small>
                            </Col>
                            <Col className="col-md-6 col-6 py-2">
                                <TwitterShareButton url={twitterShareUrl}>
                                    <TwitterIcon size={32} round/>
                                </TwitterShareButton>
                                <small>
                                    <span className="ms-1">{twitterShareUrl}</span>
                                </small>
                            </Col>
                            <Col className="col-md-6 col-6 py-2">
                                <LinkedinShareButton url={linkedinShareUrl}>
                                    <LinkedinIcon size={32} round/>
                                </LinkedinShareButton>
                                <small>
                                    <span className="ms-1">{linkedinShareUrl}</span>
                                </small>
                            </Col>
                        </Row>
                    </div>
                    <SuggestAParkingSite handleSuggestSuccess={handleSuggestSuccess}/>
                </div>
                <Card.Footer className="px-2">
                    <div className="d-grid gap-2">
                        <Button type="button" variant="danger" onClick={logOut}>{t('logout')}</Button>
                    </div>
                </Card.Footer>
            </Card>
        )
    )
}

export default DriverSettings;
