import {Card, Col, Form, Row}       from "react-bootstrap";
import {useAuth}                    from "@/services/Auth";
import React, {useEffect, useState, useRef} from "react";
import LoadingPage                  from "@/components/LoadingPage";
import { useForm } from 'react-hook-form';

import Select                    from 'react-select';
import AsyncSelect from "react-select/async";
import {useTranslation}          from "react-i18next";
import {Link}                    from "react-router-dom";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import paginationFactory         from "react-bootstrap-table2-paginator";
import filterFactory             from "react-bootstrap-table2-filter";
import BootstrapTable            from "react-bootstrap-table-next";
import DatePicker                from "react-datepicker";
import useMoment from "@/hooks/useMoment";
import Paginate from "../../../../components/Paginate";

function AdminDashboard() {


    const requestStore = useRef({});
    const { current: requests } = requestStore;

    const {t, i18n} = useTranslation();
    let auth = useAuth();
    const getRequest = auth.getRequest;
    const postRequest = auth.postRequest;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const {momentLocal, moment} = useMoment()

    const [loading, setLoading] = useState(true);
    const [loadStats, setLoadStats] = useState(true);
    const [initialLoad, setInitialLoad] = useState(true);


    const [parkingOccurrences, setParkingOccurrences] = useState(0);
    const [nonParkingOccurrences, setNonParkingOccurrences] = useState(0);
    const [activeSites, setActiveSites] = useState(0);
    const [activeUsers, setActiveUsers] = useState(0);
    const [activeAccounts, setActiveAccounts] = useState(0);
    const [siteRevenue, setSiteRevenue] = useState(0);
    const [totalMPakFee, setTotalMParkFee] = useState(0);

    const [countries, setCountries] = useState([]);
    const [services, setServices] = useState([]);
    const [order_items, setOrderItems] = useState(false);
    const [currentPayload, setCurrentPayload] = useState({});
    const [page, setPage] = useState(0);
    const [totalRecords, setTotal] = useState(false);

    const perPage = 10;

    const getDashFilterData = () => {
        setLoading(true);
        auth.getRequest('/dashboard/admin', {
            data: 'filter',
            source: 'dashboard'
        })
            .then(response => {
                if (response.data.message === 'OK') {
                    setCountries(response.data.countries);
                    setServices(response.data.services);
                    setLoading(false);
                    setInitialLoad(false);
                }
            })
            .catch(error => {
                setLoading(false);
            })
    }

    const getDasStatsData = (values, page) => {
        requests.stats && requests?.stats?.abort && requests.stats.abort();

        const payload = {
            from_date: values.from_date,
            to_date: values.to_date,
            site_id: values.site_id,
            country_id: values.country_id,
            service_id: values.service_id,
        };

        setCurrentPayload(payload);

        payload.data = 'stats';
        payload.source = 'dashboard';

        payload.take = perPage;
        payload.skip = page * perPage;

        setLoadStats(true);
        requests.stats = auth.getRequest('/dashboard/admin', payload);

        setOrderItems(false)

        requests.stats
            .then(response => {
                if (initialLoad) {
                    setInitialLoad(false);
                    getDashFilterData();
                }
                if (response.data.message === 'OK') {
                    setParkingOccurrences(response.data.parking_occurrences);
                    setNonParkingOccurrences(response.data.non_parking_occurrences);
                    setActiveSites(response.data.active_sites);
                    setActiveUsers(response.data.active_users);
                    setSiteRevenue(response.data.total_site_revenue);
                    setTotalMParkFee(response.data.total_m_park_fee);
                    setActiveAccounts(response.data.active_accounts);
                    setOrderItems(response.data.order_items);
                    setTotal(response.data.total);
                    setLoadStats(false);
                }
            })
    }


    const siteServices = () => {
        let serviceList = services;
        if (site !== '' && site != null && typeof site === 'object') {
            serviceList = serviceList.filter(service => service.site_id == site.value);
        }
        return serviceList;
    }

    const linkCell = (cell, row) => {
        return <Link to={`orders/${row.order_id}`}>{cell}</Link>
    }

    const companyFleetName = (fleet) => {
        return fleet.name;
    }


    const FleetCell = (cell, row) => {
        if (cell && cell.client) {
            return <Link to={`fleets/${cell.id}`}>{cell.client.name} | {cell.name}</Link>
        } else if(typeof cell ==='object' && 'id' in cell) {
            return <Link to={`fleets/${cell.id}`}>{cell.name}</Link>
        }
        return  <span>{cell}</span>
    }
    const statusFormat = (cell) => {
        return t(`order_status.${cell}`)
    }

    const {SearchBar} = Search;

    const columns = [
        {
            dataField: 'id',
            text     : t('order_item_id'),
        },
        {
            dataField: 'order.order_number',
            text     : t('order_id'),
            formatter: linkCell,
        },
        {
            dataField: 'order.booking.booking_number',
            text     : t('booking_id'),
        },
        {
            dataField: 'order.vehicle.fleet',
            text     : t('fleet_name_company'),
            formatter: FleetCell,
        },
        {
            dataField: 'order.vehicle',
            text     : t('vehicle_registration_number'),
            formatter: (cell, row) => {
                return cell && <Link to={`vehicles/${cell.id}`}>{cell.registration_number}</Link> || ''
            }
        },
        {
            dataField: 'order.account_number',
            text     : t('account_number')
        },
        {
            dataField: 'order.site',
            text     : t('site'),
            formatter: (cell, row) => {
                return <Link to={`sites/${cell.id}`}>{cell.name}</Link>
            }
        },
        {
            dataField: 'order.status',
            text     : t('status'),
            formatter: (cell, row) => {
                if( row.order?.booking?.status === 'completed' ){
                    return t(`order_status.${row.order?.booking?.status}`)
                }
                return  t(`order_status.${cell}`)
            },
        },
        {
            dataField: 'name',
            text     : t('service')
        },
        {
            dataField: 'order.created_at',
            text     : t('date'),
            formatter: (cell, row) => {
                return momentLocal(cell).format('DD/MM/YYYY HH:mm')
            },
        },
        {
            dataField: 'total_amount',
            text     : t('amount'),
            align    : 'right',
            formatter: (cell, row) => {
                return <span>{ cell?.toFixed && cell.toFixed(2) || cell } { row.order.site  ? row.order.site.currency : ''}</span>
            },
        },
        {
            dataField: 'order.booking.from_datetime',
            text     : t('from'),
            formatter: (cell, row) => {
                return momentLocal(cell).tz(timezone).format(t('formats.short_datetime'))
            },
        },
        {
            dataField: 'order.booking.to_datetime',
            text     : t('to'),
            formatter: (cell, row) => {
                return momentLocal(cell).tz(timezone).format(t('formats.short_datetime'))
            },
        },
    ];


    const paginationOptions = {
        paginationSize: 4,
    };

    const formatRecord = (record) => {
        const  object = {...record};

        object.value = record.id;
        object.label = record.name;

        return object;
    };


    const {
              handleSubmit,
              register,
              watch,
              setValue,
              getValues
          } = useForm({
        defaultValues: {
            from_date: moment().startOf('month').format('YYYY-MM-DD'),
        },
    });

    const getSites = (search) => {
        requests.sites && requests?.sites?.abort && requests.sites.abort();

        return new Promise((resolve, reject) => {
            requests.sites = auth.getRequest('sites', { search, managing: true });

            requests.sites.then(response => {
                    resolve(response.data.sites.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    };

    useEffect(() => {
        return () => {
            for(var key in requests)
            {
                requests[key] && requests[key].abort && requests[key].abort();
            }
        }
    }, []);

    useEffect(() => {
        register('country_id');
        register('selected_country');

        register('site_id');
        register('selected_site');

        register('service_id');
        register('selected_service');
    }, []);

    const service = watch('selected_service');
    const site = watch('selected_site');
    const country = watch('selected_country');

    const from_date = watch('from_date');
    const to_date   = watch('to_date');

    useEffect(() => {

        let values = getValues();
        let canLoadStats = false;

        if( values?.from_date !== currentPayload?.from_date ) canLoadStats = true;
        if( values?.to_date !== currentPayload?.to_date && values?.to_date !== '' ) canLoadStats = true;
        if( values?.site_id !== currentPayload?.site_id ) canLoadStats = true;
        if( values?.country_id !== currentPayload?.country_id ) canLoadStats = true;
        if( values?.service_id !== currentPayload?.service_id ) canLoadStats = true;

        if( canLoadStats || initialLoad ){
            setPage(0)
            getDasStatsData(values, 0);
        }
    }, [country, service, site, from_date, to_date]);

    if(initialLoad)
    {
        return <LoadingPage/>;
    }

    const changePage =(event) => {
        setPage(event.selected)

        let values = getValues();
        getDasStatsData(values, event.selected);
    }


    return (
            <div className="site-find-card position-relative">
                {
                    auth.user.is_platform_admin &&
                    <h3 className="text-primary">{t('dashboard.platform_dashboard')}</h3>
                }
                {
                    auth.user.is_platform_operator &&
                    <h3 className="text-primary">{t('dashboard.platform_operator_dashboard')}</h3>
                }
                {
                    auth.user.is_platform_finance &&
                    <h3 className="text-primary">{t('dashboard.platform_finance_dashboard')}</h3>
                }
                <Form >
                <Row>
                    <Col sm={6} md={4} lg={3} className="col-12 mt-2">

                        <Select
                            className="react-select"
                            isClearable={true}
                            value={country}
                            onChange={(e) => {setValue('selected_country', e); setValue('country_id', e && e.value) }}
                            placeholder={t('region_country')}
                            options={countries.map(formatRecord)}/>
                    </Col>
                    <Col sm={6} md={4} lg={3} className="col-12 mt-2">

                            <AsyncSelect
                            className="react-select"
                            isClearable={true}
                            value={site}
                            onChange={(e) => {
                                setValue('site_id', e && e.value);
                                setValue('selected_site', e);
                            }}
                            placeholder={t('site')}
                                defaultOptions={true}
                                loadOptions={getSites}
                                />
                    </Col>

                    <Col sm={6} className="col-12 mt-2">
                        <Row className="my-md-0 my-2">
                            <Col className="col-12 d-flex align-items-center">
                                <Form.Control
                                    placeholder={t('from')}
                                    {...register('from_date')}
                                    type="date"
                                    max={to_date ? momentLocal(to_date).format('YYYY-MM-DD') : null}

                                />
                                <span className="mx-2">{t('to')}</span>
                                <Form.Control
                                    placeholder={t('to')}
                                    {...register('to_date')}
                                    type="date"
                                    min={from_date ? momentLocal(from_date).format('YYYY-MM-DD') : null}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={6} md={4} lg={3} className="col-12 mt-2">
                        <Select
                            className="react-select"
                            isClearable={true}
                            value={service}
                            onChange={(e) => {
                                setValue('service_id', e && e.value);
                                setValue('selected_service', e);
                            }}
                            placeholder={t('service')}
                            options={siteServices().map(formatRecord)}/>
                    </Col>
                </Row>
                </Form>
                <div className="position-relative">

                <Row className="mt-2">
                    <Col sm={6} md={4} lg={3} className="col-12 mt-2">
                        <Card bg="primary" className="mb-2 dash-card" text="light">
                            <Card.Body>
                                <Card.Title className="">
                                    <h3>{totalMPakFee}</h3>
                                </Card.Title>
                                <Card.Text className="">
                                    {t('dashboard.total_m_park_fee')}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} md={4} lg={3} className="col-12 mt-2">
                        <Card bg="primary" className="mb-2 dash-card" text="light">
                            <Card.Body>
                                <Card.Title className="">
                                    <h3>{parkingOccurrences}</h3>
                                </Card.Title>
                                <Card.Text className="">
                                    {t('dashboard.parking_occurrences')}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} md={4} lg={3} className="col-12 mt-2">
                        <Card bg="primary" className="mb-2 dash-card" text="light">
                            <Card.Body>
                                <Card.Title className="">
                                    <h3>{nonParkingOccurrences}</h3>
                                </Card.Title>
                                <Card.Text className="">
                                    {t('dashboard.non_parking_occurrences')}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} md={4} lg={3} className="col-12 mt-2">
                        <Card bg="primary" className="mb-2 dash-card" text="light">
                            <Card.Body>
                                <Card.Title className="">
                                    <h3>{activeSites}</h3>
                                </Card.Title>
                                <Card.Text className="">
                                    {t('dashboard.active_sites')}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} md={4} lg={3} className="col-12 mt-2">
                        <Card bg="primary" className="mb-2 dash-card" text="light">
                            <Card.Body>
                                <Card.Title className="">
                                    <h3>{activeUsers}</h3>
                                </Card.Title>
                                <Card.Text className="">
                                    {t('dashboard.active_users')}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} md={4} lg={3} className="col-12 mt-2">
                        <Card bg="primary" className="mb-2 dash-card" text="light">
                            <Card.Body>
                                <Card.Title className="">
                                    <h3> {activeAccounts}</h3>
                                </Card.Title>
                                <Card.Text className="">
                                    {t('dashboard.active_accounts')}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} md={4} lg={3} className="col-12 mt-2">
                        <Card bg="primary" className="mb-2 dash-card" text="light">
                            <Card.Body>
                                <Card.Title className="">
                                    <h3> {siteRevenue}</h3>
                                </Card.Title>
                                <Card.Text className="">
                                    {t('dashboard.total_site_revenue')}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <div className="mt-2">
                    <BootstrapTable
                        keyField="id"
                        striped
                        responsive
                        hover
                        filter={filterFactory()}
                        columns={columns || []}
                        data={order_items || []}
                        noDataIndication={order_items instanceof Array || !loadStats ? t('no_data') : t('loading')}
                    />
                    {
                        order_items && totalRecords &&
                        <Paginate {...{ changePage, totalRecords, page, perPage }} />
                        || ''
                    }
                </div>
                </div>
            </div>
    );
}

export default AdminDashboard;
