import {useHistory, useLocation} from "react-router-dom";
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useMoment from "@/hooks/useMoment";

function Parking({site, booking, onSubmit, errors: globalErrors, driver, vehicle, cancelBooking}) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const {t} = useTranslation();

    const route = useLocation();
    const history = useHistory();

    const {momentLocal, moment} = useMoment()

    const [parking, setParking] = useState(null);

    const schema = yup.object().shape({
        duration: yup
            .number()
            .typeError(t('form_validation.is_required', {attribute: t('duration')}))
            .required(t('form_validation.is_required', {attribute: t('duration')})),
    }).required();

    const {
        handleSubmit,
        register,
        formState: {errors},
        watch,
    } = useForm({
        defaultValues: (_ => {
            const booking = {..._};
            booking.duration = typeof booking.duration != 'undefined' ? booking.duration + '' : null;

            return booking;
        })(booking || {duration: null}),
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        history.replace(route.pathname, {
            ...route.state,
        });

    }, []);


    useEffect(() => {
        register('duration');
    }, []);

    const duration = watch('duration');

    useEffect(() => {
        if (duration == null) {
            setParking(null);
        } else {
            let value = '0';
            if (duration > 0) {
                value = '1';
            }
            setParking(value);
        }
    }, [duration]);


    const availableIntervals = {
        hours: [1, 2, 3],
        days: [1],
    };

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="booking booking-parking container-fluid">
            <Helmet>
                <title>{t('create')} / {t('drawer_link_titles.bookings')} - {t('app')}</title>
            </Helmet>

            <h6 className="text-primary">
                {t('booking.instant_booking')}
            </h6>

            <p className="extra-small mb-0">{site.name}</p>
            <p className="extra-small mb-0">
                {momentLocal(moment.now()).format(t('formats.short_datetime_reverse'))}
            </p>
            <p className="extra-small mb-0">{driver.name}</p>
            <p className="border-2 border-bottom extra-small mb-0 pb-2">{vehicle.registration_number}</p>

            <Row>
                <Col xl={8} xxl={6}>

                    <Row>
                        <Col xs={12}>
                            <Form.Label className="h4 mb-3 mt-4">
                                {t('booking_duration')}
                            </Form.Label>

                            <Row className="gx-2">
                                {availableIntervals.hours.map((count, index) => (
                                    <label key={index + 'hours'}
                                           className="col-6 col-lg-4 col-xl-3">
                                        <Form.Check.Input
                                            type="radio"
                                            {...register('duration')}
                                            value={count}
                                            className="d-none"
                                        />


                                        <span
                                            className="btn btn-outline-primary w-100 mb-2">{t('n_hours', {count})}</span>
                                    </label>
                                ))}

                                {availableIntervals.days.map((count, index) => (
                                    <label key={index + 'days'}
                                           className="col-6 col-lg-4 col-xl-3">
                                        <Form.Check.Input
                                            type="radio"
                                            {...register('duration')}
                                            value={count * 24}
                                            className="d-none"
                                        />


                                        <span
                                            className="btn btn-outline-primary w-100 mb-2">{t('n_days', {count})}</span>
                                    </label>
                                ))}

                                <label key="custom" className="col-12 col-lg-4 col-xl-3">
                                    <Form.Check.Input
                                        type="radio"
                                        {...register('duration')}
                                        value={0}
                                        className="d-none"
                                    />

                                    <span
                                        className="btn btn-outline-primary w-100 mb-2">{t('instant_booking.no_parking')}</span>
                                </label>
                            </Row>
                        </Col>
                    </Row>

                    <div className={globalErrors.credit_limit?.length ? 'd-block invalid-feedback' : 'invalid-feedback'}>
                        {globalErrors?.credit_limit && globalErrors?.credit_limit[0] && t(globalErrors?.credit_limit[0])}
                    </div>


                    {!!(parking === '0' || duration > 0) && (
                        <>
                            <div className={Object.values(globalErrors).length > 0 ? 'd-block invalid-feedback' : 'invalid-feedback'}>
                                {Object.values(globalErrors)?.map((error) => (
                                    <>
                                        {t(error)}
                                    </>
                                ))}
                            </div>

                            <div className="mt-4">
                                <Button type="submit" variant="success"  className="w-100">
                                    {t('continue')}
                                </Button>
                            </div>
                            <div className="mt-4">
                                <Button className="w-100" variant="danger" onClick={cancelBooking}>
                                    {t('cancel')}
                                </Button>
                            </div>
                        </>
                    ) || ''}


                    <Form.Control.Feedback type="invalid" className={!!globalErrors.instant_order_token && 'd-block'}>
                        {globalErrors?.instant_order_token && globalErrors?.instant_order_token[0] && t(globalErrors?.instant_order_token[0])}
                    </Form.Control.Feedback>
                </Col>
            </Row>
        </Form>
    );
}

export default Parking;


