import {Button, Dropdown, Navbar}      from "react-bootstrap";
import {Link, useHistory, useLocation} from "react-router-dom";

import {useAuth}         from '../../services/Auth'
import * as React        from "react";
import {useTranslation}  from "react-i18next";
import LogoutIcon        from "../../components/icons/LogoutIcon";
import GlobeIcon         from "../../components/icons/GlobeIcon";
import NotificationsIcon from "../../components/icons/NotificationsIcon";
import MenuIcon          from "../../components/icons/MenuIcon";
import CloseIcon         from "../../components/icons/CloseIcon";
import BackIcon          from "../../components/icons/BackIcon";
import HeaderLogo        from "../../components/HeaderLogo";
import { useLayout }     from "@/layouts/Layout";

function NavBar() {
    const {t, i18n} = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const auth = useAuth();

    const layout = useLayout();

    const logOut = () => {
        auth.signOut().then(response => {
            window.location.reload();
        });
    };

    const languageSwitcherToggle = React.forwardRef(({children, onClick}, ref) => (
        <div>
            <Button
                className="btn-icon d-flex align-items-center  p-1"
                ref={ref}
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }} variant="primary">
                <GlobeIcon/>
            </Button>
        </div>

    ));

    const userProfileOptions = React.forwardRef(({children, onClick}, ref) => (
        <Button
            className="btn-icon p-1"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }} variant="primary">
            <i
                className="bi bi-person-circle text-white"
            />
        </Button>
    ));

    const changeLanguage = (lang) => {
        auth.setLanguage(lang);
    };

    const goBack = () => {
        history.goBack()
    };

    return (
        <Navbar bg="primary" variant="dark" fixed="top" id="main-nav">
            <div className="d-flex px-1 px-sm-3 align-items-center" style={{width: '100%'}}>
                <div className="subnav d-flex elevated d-print-none">
                    {(location.pathname !== '/') &&
                     <Button className="btn-icon elevated  p-1 btn-sm me-1 me-sm-4" variant="primary" onClick={() => goBack()}>
                         <BackIcon/>
                     </Button>
                    || ''}

                    {(auth?.user) &&
                     <Button className="btn-icon btn-sm d-none d-sm-block elevated" variant="light" onClick={() => logOut()}>
                         <div className="d-flex align-items-center">
                             <span className="me-2">{t('logout')}</span>
                             <LogoutIcon size={16}/>
                         </div>
                     </Button>
                    ||
                     <Button className="btn-icon btn-sm d-none d-sm-block elevated" variant="light" onClick={() => logOut()}>
                         <div className="d-flex align-items-center">
                             <span className="me-2">{t('login')}</span>
                             <LogoutIcon size={16}/>
                         </div>
                     </Button>

                    }
                    {(auth?.user?.is_driver && auth?.user?.company?.name) &&
                        <h5 className="text-white align-content-center mb-0 mt-1 mx-2 px-1 d-none d-lg-flex">{auth.user.company.name}</h5>
                    || ''}
                </div>

                <div className="d-flex justify-content-center align-items-center app-logo-container mx-sm-3">
                    <Link to="/">
                        <HeaderLogo/>
                    </Link>
                </div>

                <div className="subnav d-flex justify-content-end align-items-center ms-auto ms-sm-0 d-print-none">


                    {layout.extraNav}

                    <div className="actions d-flex justify-content-between align-items-center elevated">
                        <Dropdown align="end" className="me-sm-2">
                            <Dropdown.Toggle as={languageSwitcherToggle}>
                                <i className="bi bi-globe text-white"/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item className={i18n.language === 'en' ? 'active' : ""} onClick={() => changeLanguage('en')}>English</Dropdown.Item>
                                <Dropdown.Item className={i18n.language === 'fr' ? 'active' : ""} onClick={() => changeLanguage('fr')}>français</Dropdown.Item>
                                <Dropdown.Item className={i18n.language === 'pl' ? 'active' : ""} onClick={() => changeLanguage('pl')}>Polski</Dropdown.Item>
                                <Dropdown.Item className={i18n.language === 'cs' ? 'active' : ""} onClick={() => changeLanguage('cs')}>čeština</Dropdown.Item>
                                <Dropdown.Item className={i18n.language === 'ro' ? 'active' : ""} onClick={() => changeLanguage('ro')}>Română</Dropdown.Item>
                                <Dropdown.Item className={i18n.language === 'lt' ? 'active' : ""} onClick={() => changeLanguage('lt')}>lietuvių</Dropdown.Item>
                                <Dropdown.Item className={i18n.language === 'hu' ? 'active' : ""} onClick={() => changeLanguage('hu')}>Magyar</Dropdown.Item>
                                <Dropdown.Item className={i18n.language === 'sk' ? 'active' : ""} onClick={() => changeLanguage('sk')}>slovenský</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        {auth?.user &&
                        <Link to="/notifications" className="btn btn-primary p-1 d-none d-sm-block btn-icon position-relative" variant="primary">
                            {
                                auth.notifications?.length > 0 &&
                                <span className="badge badge-danger rounded-pill bg-danger">{auth.notifications?.length}</span>
                            }
                            <NotificationsIcon/>
                        </Link>
                        || ''}

                        {auth?.user &&
                        <Button className="btn-icon btn-toggle p-1 ms-sm-2 elevated position-relative" variant="primary" onClick={() => layout.setToggled(!layout.toggled)}>
                            {
                                auth.notifications?.length > 0 && !layout.toggled &&
                                <span className="badge badge-danger rounded-pill bg-danger d-sm-none">{auth.notifications?.length}</span>
                            }
                            {
                                layout.toggled ?
                                <span className="bi bi-x-lg" />
                                        :
                                <span className="bi bi-list" />
                            }
                        </Button>
                        || ''}
                    </div>
                </div>
            </div>
        </Navbar>
    )
}

export default NavBar;
