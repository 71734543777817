import {useHistory, useLocation, Redirect} from "react-router-dom";
import React, {useState, useEffect} from "react";
import SuccessBooking from "./create/SuccessBooking";
import Parking from "./create/Parking";
import Addons from "./create/Addons";
import Quote from "./create/Quote";
import {useLayout} from "../../../layouts/Layout";
import moment from "moment-timezone";
import {useAuth} from "../../../services";
import LoadingPage from "../../../components/LoadingPage";


function Create(props) {
    let layout = useLayout();

    const route = useLocation();
    const auth = useAuth();
    const history = useHistory();

    const [site, _] = useState(route.state && route.state.site);
    const [driver, __] = useState(route.state && route.state.driver);
    const [vehicle, ___] = useState(route.state && route.state.vehicle);
    const [booking, setBooking] = useState({
        site_id: site && site.id,
        vehicle_id: vehicle && vehicle.id,
        user_id: driver && driver.id,
        instant_booking: true,
        ...( site.parking_available ? {} : {parking_booking: false, duration: 0})
    });
    const [tempBooking, setTempBooking] = useState({});

    const bookingKey = route.state && route.state.bookingKey || null;
    const defaultStage = site.parking_available ? 'parking' : 'addons';
    const stage =  route.state.stage ?? defaultStage;

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const setStage = (stage) => {
        history.push(route.pathname, {
            ...route.state,
            bookingKey,
            stage,
        });
    };

    const cancelBooking = () => {
        history.push('/');
    }

    const setBookingErrors = (errors) => {
        updateBooking({addons: []})
        setStage(defaultStage);
        setErrors(errors);
    };

    const amendBooking = () => {
        setStage(defaultStage);
    };


    const updateBooking = (attributes, stage) => {
        if (stage) {
            attributes.from_datetime = moment().format();

            if(!site.parking_available) {
                attributes.duration = 0;
            }

            if (stage === "addons") {
                attributes.parking_booking = attributes.duration > 0;
            }
        }
        const updatedBooking = {...booking, ...attributes};
        if (!updatedBooking.key) {
            updatedBooking.key = Math.random();
        }
        setBooking(updatedBooking);

        if (bookingKey != updatedBooking.key) {
            history.replace(route.pathname, {
                ...route.state,
                bookingKey: updatedBooking.key,
            });
        }
        if(stage)
        {
            setStage(stage);
        }
        setErrors({})
    };

    useEffect(() => {
        layout.scrollTop();
    }, [stage]);

    // useEffect(() => {
    //     if (booking?.id && ['approved', 'started', 'completed'].includes(booking.status)) {
    //         history.push(`/bookings/${booking.id}`, {booking});
    //     }
    // });

    const makeBooking = () => {
        setLoading(true);
        auth.postRequest('bookings', booking)
            .then(response => {
                if (response.data.message === 'OK')
                {
                    const data = response.data.booking;
                    setBooking(response.data.booking);
                    history.push(`/bookings/${data.id}/edit`, {
                        from: props.location,
                        token: data.token
                    })
                }
            })
            .catch(error => {
                if(error.response.status === 422)
                {
                    setBookingErrors(error.response.data.errors);
                }
            })
            .finally(error => {
                setLoading(false);
            })
    }

    if(loading)
    {
        return <LoadingPage />;
    }

    if (stage === 'parking') {
        return (
            <Parking {...{
                booking,
                errors,
                site,
                driver,
                vehicle,
                onSubmit: (values) => updateBooking(values, 'addons'),
                cancelBooking,
            }} />
        );

    }


    if (stage === 'addons') {
        return (
            <Addons {...{
                booking,
                site,
                errors,
                setBookingErrors,
                vehicle,
                driver,
                onSubmit: (values) => updateBooking(values, 'quote'),
                cancelBooking,
            }} />
        );

    }

    if (stage === 'quote') {
        return (
            <Quote {...{
                booking,
                site,
                errors,
                setBookingErrors,
                vehicle,
                driver,
                onSubmit: () => makeBooking(),
                cancelBooking,
                amendBooking,
            }} />
        );

    }

    if (stage === 'success') {
        return <></>
    //     return <SuccessBooking {...{
    //         tempBooking,
    //         updateBooking: (booking) => updateBooking(booking),
    //     }}/>;
    }
}

export default Create;
